/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface KitchenOrderItem { 
    kitchenStatus?: KitchenOrderItem.KitchenStatusEnum;
    name?: string;
    orderItemId?: number;
    prepareDuration?: number;
    quantity?: number;
    variance?: string;
}
export namespace KitchenOrderItem {
    export type KitchenStatusEnum = 'CANCELLED' | 'CONFIRMED' | 'NEW' | 'PREPARING' | 'READY' | 'SERVED';
    export const KitchenStatusEnum = {
        Cancelled: 'CANCELLED' as KitchenStatusEnum,
        Confirmed: 'CONFIRMED' as KitchenStatusEnum,
        New: 'NEW' as KitchenStatusEnum,
        Preparing: 'PREPARING' as KitchenStatusEnum,
        Ready: 'READY' as KitchenStatusEnum,
        Served: 'SERVED' as KitchenStatusEnum
    };
}


