import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {UserControllerService} from './gen';
import {map, Observable, of} from 'rxjs';
import {LocalstorageService} from './localstorage.service';
import {UserShiftService} from "./user-shift.service";

@Injectable({
  providedIn: 'root',
})
export class UserShiftGuard implements CanActivate {
  constructor(
    private router: Router,
    private customAuthService: AuthService,
    private userService: UserControllerService,
    private ls: LocalstorageService,
    private userShiftService: UserShiftService
  ) {}

  canActivate(): Observable<boolean> {
    if (!this.ls.getMerchant()) {
      this.router.navigate(['/login']);
      return of(false);
    }
    const token = this.customAuthService.getUser()?.token; // Assuming the token is stored in localStorage
    if (token) {

      this.userShiftService.checkShiftValid();

      return this.userService
        .getUserShiftCountPost({
          merchantId: this.ls.getMerchant().id,
        })
        .pipe(
          map((shiftCount) => {
            if (shiftCount === 0) {
              this.router.navigate(['/shift-opening']);
              return false;
            }
            return true;
          })
        );
    }

    return of(true);
  }
}
