/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Merchant } from './merchant';


export interface TableMerchant { 
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    hasUnconfirmed?: boolean;
    id?: number;
    merchant?: Merchant;
    name?: string;
    orderType?: TableMerchant.OrderTypeEnum;
    seq?: number;
    totalOrder?: number;
    totalSale?: number;
    updateTime?: string;
    updatedByName?: string;
}
export namespace TableMerchant {
    export type OrderTypeEnum = 'DINE_IN' | 'TAKE_AWAY';
    export const OrderTypeEnum = {
        DineIn: 'DINE_IN' as OrderTypeEnum,
        TakeAway: 'TAKE_AWAY' as OrderTypeEnum
    };
}


