import {Injectable} from '@angular/core';
import {UserModel} from '../@models/user.model';
import {Router} from '@angular/router';
import {UserControllerService, UserShiftRes} from "./gen";
import {LocalstorageService} from "./localstorage.service";

@Injectable({
  providedIn: 'root',
})
export class UserShiftService {
  private readonly _key = 'dposPosUserShift';
  private readonly _key2 = 'dposPosUserShiftUpdateDate';

  constructor(private router: Router, private userService: UserControllerService,  private ls: LocalstorageService) {}

  saveUserShift(shift: UserShiftRes) {
    localStorage.setItem(this._key, JSON.stringify(shift));

    // Get the current date
    var currentDate = new Date();
    var dateString = currentDate.toISOString();
    localStorage.setItem(this._key2, dateString);
  }

  getUserShift(): UserShiftRes {
    let userShiftJson = localStorage.getItem(this._key);
    let userShiftModel = JSON.parse(userShiftJson) as UserShiftRes;
    return userShiftModel;
  }

  clearUserShift() {
    let userShiftJson = localStorage.removeItem(this._key);
  }

  checkShiftValid(){

    let userShiftDate = localStorage.getItem(this._key2);

    if (userShiftDate) {
      const currentTime = new Date();
      const creationTime = new Date(userShiftDate);

      if (!isNaN(creationTime.getTime())) { // Check if the date is valid
        const timeDifferenceInHours = (currentTime.getTime() - creationTime.getTime()) / (1000 * 60 * 60);

        console.log(timeDifferenceInHours);

        if (timeDifferenceInHours > 6) {
          console.log("User shift model is older than 6 hours");

          this.userService.getUserShiftSummaryPost({ merchantId: this.ls.getMerchant().id })
            .subscribe(
              (userShift) => {
                if (userShift) {
                  this.saveUserShift(userShift);
                }
              },
              (error) => {
                console.error("Error fetching user shift summary:", error);
              }
            );
        }
      } else {
        console.error("Invalid date format retrieved from localStorage");
        // Optionally handle the invalid date scenario, maybe fallback to default behavior or clear localStorage
      }
    } else {
      this.userService.getUserShiftSummaryPost({ merchantId: this.ls.getMerchant().id })
        .subscribe(
          (userShift) => {
            if (userShift) {
              this.saveUserShift(userShift);
            }
          },
          (error) => {
            console.error("Error fetching user shift summary:", error);
          }
        );

    }
  }
}
