import {Component, OnInit} from '@angular/core';
import {Merchant, PaymentControllerService, PaymentReq, UserControllerService} from "../../@services/gen";
import {AuthService} from '../../@services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {ActivatedRoute, Router} from '@angular/router';
import {printz} from '../../@services/utils';
import {PaymentService} from '../../@services/payment.service';
import {NotificationService} from '../../@services/notification.service';
import {UserModel} from '../../@models/user.model';
import {LocalstorageService} from '../../@services/localstorage.service';
import {Payment} from "../../@services/gen/model/payment";

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
})
export class PaymentDetailComponent implements OnInit {
  paymentId: number;
  payment: PaymentReq;
  user: UserModel;
  merchant: Merchant = {};

  constructor(
    private paymentService: PaymentControllerService,
    private customPaymentService: PaymentService,
    private customAuthService: AuthService,
    private sb: MatSnackBar,
    private ns: NotificationService,
    private ds: TdDialogService,
    private userService: UserControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private ls: LocalstorageService
  ) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.merchant = this.ls.getMerchant();

    let qp = this.route.snapshot.queryParams;
    this.paymentId = +qp['paymentId'];
    this.load();
  }

  load() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.paymentService.getPaymentGet(this.paymentId).subscribe((res) => {
      dialogRef.close();
      this.payment = res;

    });
  }

  voidPayment(payment: Payment) {
    this.ds
      .openConfirm({
        title: 'Void Payment',
        message: 'Are you sure to void this payment? This action cannot be undone',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          let dialogRef = this.ns.showNotification({
            message: 'Voiding payment...',
            manualClose: true,
            icon: 'fa fa-sync',
          });

          this.paymentService
            .voidPaymentPost({
              payment: payment,
            })
            .subscribe(
              (res) => {
                dialogRef.close();
                this.ns.showNotification({message: 'Payment has been voided'});
                this.router.navigate(['/payment-list']);
              },
              (error) => {
                dialogRef.close();
              }
            );
        }
      });
  }

  reprintReceipt() {
    for (let mvp of this.merchant.merchantVsPrinters) {
      let dialogRef = this.ns.showNotification({
        message: 'Reprint receipt',
        manualClose: true,
        icon: 'fa fa-sync',
      });

      console.log(`mvp`, mvp);
      if (mvp.printReceipt) {
        this.customPaymentService.getPaymentReceipt(
          this.paymentId,
          mvp.printer.paperSizeType == 'BIG' ? 45 : 32,
          (res) => {
            dialogRef.close();

            printz(
              {
                printerName: mvp.printer.printerName,
                printerType: mvp.printer.printerType,
                vendorId: mvp.printer.printerVendorId,
                ipAddress: mvp.ipAddress,
                macAddress: mvp.macAddress,
              },
              [res]
            );
          }
        );
      } else {
        dialogRef.close();
      }
    }
  }
}
