import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  Merchant,
  OrderControllerService,
  PaymentControllerService,
  PaymentMethodControllerService,
} from '../../@services/gen';
import {kickCashDrawerz, printz, secondScreen} from '../../@services/utils';
import {AuthService} from '../../@services/auth.service';
import {PaymentService} from '../../@services/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserModel} from '../../@models/user.model';
import {LocalstorageService} from '../../@services/localstorage.service';
import {NotificationService} from '../../@services/notification.service';
import {Payment} from "../../@services/gen/model/payment";

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss'],
})
export class PaymentResultComponent implements OnInit {
  @ViewChild('referenceNoRef') referenceNoRef: ElementRef;
  @ViewChild('paidAmountRef') paidAmountRef: ElementRef;

  paidAmount: number;
  totalAmount: number = 0;
  table: string | undefined;
  payment: Payment;
  user: UserModel;
  merchant: Merchant = {};

  constructor(
    private orderService: OrderControllerService,
    private paymentService: PaymentControllerService,
    private paymentMethodService: PaymentMethodControllerService,
    private customAuthService: AuthService,
    private customPaymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private ls: LocalstorageService,
    private ns: NotificationService
  ) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.merchant = this.ls.getMerchant();

    this.route.queryParams.subscribe((qp) => {
      let paymentIdParam = qp['paymentId'];
      if (paymentIdParam) {
        this.paymentService.getPaymentGet(+paymentIdParam).subscribe((payment) => {
          this.payment = payment;

          console.log(this.payment);

          if (this.isCash() && payment.changeAmount >= 0) {
            kickCashDrawerz();
          }

          secondScreen({
            merchantName: this.merchant.companyName,
            merchantLogoUrl: this.merchant['merchantLogoSmall'],
            orderItems: payment.paymentItems.flatMap((p) => p.order.orderItems),
            payment: payment,
            discountAmount: payment.discountAmount,
            discountItemAmount: payment.discountItemAmount,
            netAmount: payment.netAmount,
            quantity: payment.paymentItems.flatMap((p) => p.order.orderItems).reduce((a, b) => a + b.quantity, 0),
            roundingAmount: payment.roundingAmount,
            subtotal: payment.totalAmount,
            tax1Amount: payment.tax1Amount,
            tax2Amount: payment.tax2Amount,
            totalAmount: payment.totalAmount,
          });
        });
      }
    });
  }

  reprintReceipt() {
    for (let mvp of this.merchant.merchantVsPrinters) {
      let dialogRef = this.ns.showNotification({
        message: 'Reprint receipt',
        manualClose: true,
        icon: 'fa fa-sync',
      });

      if (mvp.printReceipt) {
        this.customPaymentService.getPaymentReceipt(
          this.payment.id,
          mvp.printer.paperSizeType == 'BIG' ? 45 : 32,
          (res) => {
            dialogRef.close();
            console.log(`mvp receipt`, mvp);
            printz(
              {
                printerName: mvp.printer.printerName,
                printerType: mvp.printer.printerType,
                vendorId: mvp.printer.printerVendorId,
                ipAddress: mvp.ipAddress,
                macAddress: mvp.macAddress,
              },
              [res]
            );
          }
        );
      } else {
        dialogRef.close();
      }
    }
  }

  kickCashDrawer() {
    kickCashDrawerz();
  }

  isCash() {
    return this.payment.paymentMethod.name.toLowerCase() === 'cash';
  }
}
