<div class="calculator">
  <div *ngIf="title">
    <span class="fs-18px" [innerHTML]="title"></span>
  </div>
  <div>
    <input
      class="rounded p-3 border-dark fw-bold fs-3 text-black text-right"
      type="text" readonly [(ngModel)]="input">
  </div>
  <div class="buttons">
    <div class="row2">
      <button matRipple (click)="appendToInput('1')">1</button>
      <button matRipple (click)="appendToInput('2')">2</button>
      <button matRipple (click)="appendToInput('3')">3</button>
    </div>
    <div class="row2">
      <button matRipple (click)="appendToInput('4')">4</button>
      <button matRipple (click)="appendToInput('5')">5</button>
      <button matRipple (click)="appendToInput('6')">6</button>
    </div>
    <div class="row2">
      <button matRipple (click)="appendToInput('7')">7</button>
      <button matRipple (click)="appendToInput('8')">8</button>
      <button matRipple (click)="appendToInput('9')">9</button>
    </div>
    <div class="row2">
      <button matRipple (click)="appendToInput('0')">0</button>
      <button matRipple class="backspace" (click)="backspace()">⌫</button> <!-- Add the backspace button -->
    </div>

  </div>

  <div class="d-flex flex-row justify-content-between mt-3">
    <button class="btn btn-lg bg-white text-primary me-3" (click)="close()">Cancel</button>
    <button class="btn btn-lg bg-primary text-white" (click)="update()">OK</button>
  </div>

</div>
