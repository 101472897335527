import {Component, OnInit} from '@angular/core';
import {TableMerchant, TableMerchantControllerService} from '../../@services/gen';
import {LocalstorageService} from '../../@services/localstorage.service';
import {MatDialogRef} from '@angular/material/dialog';
import {UserShiftService} from "../../@services/user-shift.service";

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent implements OnInit {
  tables: TableMerchant[] = [];
  selectedTable: TableMerchant = null;

  constructor(
    private ts: TableMerchantControllerService,
    private ls: LocalstorageService,
    private dialogRef: MatDialogRef<TableListComponent>,
    private userShiftService: UserShiftService
  ) {}

  ngOnInit(): void {
    this.ts
      .getPagedTableMerchantPost({
        merchantId: this.ls.getMerchant().id,
        search: '',
        pageRequest: {
          page: 0,
          size: 999,
        },
      })
      .subscribe((res) => {
        this.tables = res.content;
      });
  }

  selectTable(tableId: number) {
    this.selectedTable = {id: tableId};
    this.userShiftService.checkShiftValid();
  }

  submit() {
    this.dialogRef.close(this.selectedTable);
  }
}
