<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="content__filter mb-3">

          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <span class="fs-3 mb-0 fw-bold me-3">Payment #{{payment?.paymentNo}}</span>
              <div class="fs-4 fw-semibold badge text-white"
                   [class.bg-danger]="payment?.paymentStatus === 'VOIDED'"
                   [class.bg-success]="payment?.paymentStatus === 'PAID'">{{payment?.paymentStatus | titlecase}}
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <button class="btn btn-lg btn-default fw-semibold mb-0 d-block py-3 text-black"
                      [routerLink]="['/payment-list']">
                <i class="fa fa-arrow-left"></i>
                Back to list
              </button>
              <button class="btn btn-lg btn-default fw-semibold mb-0 d-block py-3  ms-4"
                      (click)="reprintReceipt(); $event.stopPropagation()">
                <i class="fa fa-print"></i>
                Reprint Receipt
              </button>
              <button class="btn btn-lg fw-semibold mb-0 d-block py-3 text-white bg-danger ms-4"
                      *ngIf="payment?.paymentStatus === 'PAID'"
                      (click)="voidPayment(payment); $event.stopPropagation()">
                <i class="fa fa-trash"></i>
                Void Payment
              </button>
            </div>
          </div>
        </div>

        <div class="content__body">
          <table class="table table-bordered bg-white table">
            <ng-container *ngIf="payment?.paymentStatus === 'VOIDED'">
              <tr>
                <th>Void By</th>
                <td>{{payment?.voidUser}}</td>
              </tr>
              <tr>
                <th>Void Date</th>
                <td>{{payment?.voidDate | date: 'dd/MM/yy HH:mm'}}</td>
              </tr>
            </ng-container>
            <tr>
              <th class="w-25">Paid Date</th>
              <td>{{payment?.creationTime | date: 'dd/MM/yy HH:mm'}}</td>
            </tr>
            <tr>
              <th>Receipt No</th>
              <td>
                <a
                  class="p-0" [routerLink]="['/payment-detail']"
                  [queryParams]="{paymentId: payment?.id}">{{payment?.paymentNo}}</a>
              </td>
            </tr>
            <tr>
              <th>Items</th>
              <td>
                <div class="p-0 d-flex flex-column">
<!--                  <span>#{{item?.orderItem?.order?.orderNo}}</span>-->
<!--                  <span>{{item?.orderItem?.order?.membership?.name}} ({{item?.orderItem?.order?.membership?.phoneNo}})</span>-->
                  <div class="d-flex flex-row justify-content-between mt-1">
                    <span class="fw-semibold w-250px">Item</span>
                    <span class="fw-semibold w-100px text-right">Qty</span>
                    <span class="fw-semibold w-100px text-right">Subtotal</span>
                    <span class="fw-semibold w-100px text-right">Disc</span>
                    <span class="fw-semibold w-100px text-right">Tax</span>
                    <span class="fw-semibold w-100px text-right">Rounding</span>
                    <span class="fw-semibold w-100px text-right">Total</span>
                  </div>
                  <div *ngFor="let oi of payment?.paymentOrderItems; let last=last"
                       class="d-flex flex-row justify-content-between">
                    <span class="w-250px">
                      <span>{{oi?.orderItem.name}}</span>
                      <br>
                      <ng-container *ngFor="let v of oi.varianceModel; let last=last">
                      <ng-container *ngFor="let opt of v.options;">
                        - {{opt.name}} +{{opt.price | number: '1.2'}}<span *ngIf="!last"><br /></span>
                      </ng-container>
                      </ng-container>
                    </span>
                    <span class="w-100px text-right">x{{oi?.quantity }}</span>
                    <span class="w-100px text-right">{{oi?.subtotal | number: '1.2'}}</span>
                    <span class="w-100px text-right">{{oi?.discount | number: '1.2'}}</span>
                    <span class="w-100px text-right">{{oi?.tax | number: '1.2'}}</span>
                    <span class="w-100px text-right">{{oi?.rounding | number: '1.2'}}</span>
                    <span class="w-100px text-right">{{oi?.total | number: '1.2'}}</span>

                    <hr *ngIf="!last">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Method</th>
              <div class="d-flex flex-row">
                <img src="{{payment?.paymentMethod?.logoUrl}}"
                     class="h-35px"
                     *ngIf="payment?.paymentMethod?.logoUrl">
                <span class="col-auto text-right">{{payment?.paymentMethod?.name}}</span>
              </div>
            </tr>
            <tr *ngIf="payment?.paymentMethod.name !== 'Cash'">
              <th>eWallet Ref</th>
              <td>{{payment?.ewalletReferenceNo}}</td>
            </tr>
            <tr>
              <th>Table</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.tableMerchant?.name}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Subtotal</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.totalAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>SST</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.tax1Amount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Serv Charge</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.tax2Amount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr *ngIf="payment?.discountAmount > 0">
              <th> Discount</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                  -{{payment?.discountAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr *ngIf="payment?.tipsAmount > 0">
              <th>Tips</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.tipsAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Rounding</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.roundingAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Net</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.netAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Paid</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.paidAmount | number: '1.2'}}
                </span>
              </td>
            </tr>
            <tr>
              <th>Change</th>
              <td>
                <span class="w-100px text-right d-block p-0">
                {{payment?.changeAmount | number: '1.2'}}
                </span>
              </td>
            </tr>


          </table>
        </div>


      </div>
    </div>
  </div>
</div>
