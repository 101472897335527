import {Printer} from './gen';
import PrinterTypeEnum = Printer.PrinterTypeEnum;
import {SecondScreenModel} from '../@models/second-screen.model';

export function isObjEmpty(obj: any): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
}

export function calculateRoundingAmount(amount: number) {
  let nearestCent = roundDecimal(amount);
  let rounding = +nearestCent - amount;
  return rounding.toFixed(3);
}

export function roundDecimal(number) {
  const roundedNumber = Math.round(number * 20) / 20;
  return roundedNumber.toFixed(3);
}

export function alignCenter(text, maxLength = 32) {
  const lines = text.split('\n');
  return lines.map((x) => `<C>${x}</C>`).join('\n');
}
export function alignCenter2(text, maxLength = 32) {
  const lines = text.split('\n');
  const centeredLines = lines
    .filter((line) => line)
    .map((line) => {
      const paddingLength = Math.floor((maxLength - line.length) / 2);
      const padding = paddingLength > 0 ? ' '.repeat(paddingLength) : '';
      return padding + line;
    });
  return centeredLines.join('\n');
}

export function printz(
  printer: {
    printerName: string;
    printerType: PrinterTypeEnum;
    vendorId: string;
    ipAddress: string;
    macAddress: string;
  },
  textToPrints: string[] = []
) {
  if (window['ReactNativeWebView']) {
    window['ReactNativeWebView'].postMessage(
      JSON.stringify({
        type: 'print',
        payload: {
          printer: printer,
          data: [...textToPrints],
        },
      })
    );
  }
}

export function kickCashDrawerz() {
  if (window['ReactNativeWebView']) {
    window['ReactNativeWebView'].postMessage(
      JSON.stringify({
        type: 'kickCashDrawer',
      })
    );
  }
}

export function secondScreen(data: SecondScreenModel) {
  if (window['ReactNativeWebView']) {
    window['ReactNativeWebView'].postMessage(
      JSON.stringify({
        type: 'secondScreen',
        payload: {data: data},
      })
    );
  }
}

/**
 * only applicable for cloudinary service
 * @param originalURL
 * @param width 200
 * @param height 200
 */
export function resizeImageUrl(originalURL, width: string = '200', height: string = '') {
  let resizeAttr = '';
  if (width) {
    resizeAttr += 'w_' + width;
  }
  if (height) {
    resizeAttr += 'h_' + width;
  }
  return originalURL.replace('/upload/', '/upload/' + resizeAttr + '/');
}
