/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Merchant } from './merchant';


export interface Expenses { 
    amount?: number;
    cashInAmount?: number;
    cashOutAmount?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    description?: string;
    expensesCategory?: Expenses.ExpensesCategoryEnum;
    expensesFrequency?: Expenses.ExpensesFrequencyEnum;
    expensesType?: Expenses.ExpensesTypeEnum;
    id?: number;
    merchant?: Merchant;
    repeatEndDate?: string;
    repeatInterval?: number;
    repeatOnDay?: number;
    repeatStartDate?: string;
    repeating?: boolean;
    updateTime?: string;
    updatedByName?: string;
}
export namespace Expenses {
    export type ExpensesCategoryEnum = 'BANK_FEES' | 'CHARITABLE_DONATIONS' | 'EMPLOYEE_BENEFITS' | 'EQUIPMENT_PURCHASES' | 'INSURANCE' | 'INVENTORY' | 'LEGAL_AND_PROFESSIONAL_FEES' | 'MARKETING_AND_ADVERTISING' | 'MEALS_AND_ENTERTAINMENT' | 'MISCELLANEOUS' | 'OFFICE_SUPPLIES' | 'OTHERS' | 'POSTAGE_AND_SHIPPING' | 'RENT_MORTGAGE' | 'REPAIRS_AND_MAINTENANCE' | 'SALARIES_AND_WAGES' | 'SOFTWARE_SUBSCRIPTIONS' | 'TAXES' | 'TRAINING_AND_DEVELOPMENT' | 'TRANSPORTATION' | 'TRAVEL_EXPENSES' | 'UTILITIES';
    export const ExpensesCategoryEnum = {
        BankFees: 'BANK_FEES' as ExpensesCategoryEnum,
        CharitableDonations: 'CHARITABLE_DONATIONS' as ExpensesCategoryEnum,
        EmployeeBenefits: 'EMPLOYEE_BENEFITS' as ExpensesCategoryEnum,
        EquipmentPurchases: 'EQUIPMENT_PURCHASES' as ExpensesCategoryEnum,
        Insurance: 'INSURANCE' as ExpensesCategoryEnum,
        Inventory: 'INVENTORY' as ExpensesCategoryEnum,
        LegalAndProfessionalFees: 'LEGAL_AND_PROFESSIONAL_FEES' as ExpensesCategoryEnum,
        MarketingAndAdvertising: 'MARKETING_AND_ADVERTISING' as ExpensesCategoryEnum,
        MealsAndEntertainment: 'MEALS_AND_ENTERTAINMENT' as ExpensesCategoryEnum,
        Miscellaneous: 'MISCELLANEOUS' as ExpensesCategoryEnum,
        OfficeSupplies: 'OFFICE_SUPPLIES' as ExpensesCategoryEnum,
        Others: 'OTHERS' as ExpensesCategoryEnum,
        PostageAndShipping: 'POSTAGE_AND_SHIPPING' as ExpensesCategoryEnum,
        RentMortgage: 'RENT_MORTGAGE' as ExpensesCategoryEnum,
        RepairsAndMaintenance: 'REPAIRS_AND_MAINTENANCE' as ExpensesCategoryEnum,
        SalariesAndWages: 'SALARIES_AND_WAGES' as ExpensesCategoryEnum,
        SoftwareSubscriptions: 'SOFTWARE_SUBSCRIPTIONS' as ExpensesCategoryEnum,
        Taxes: 'TAXES' as ExpensesCategoryEnum,
        TrainingAndDevelopment: 'TRAINING_AND_DEVELOPMENT' as ExpensesCategoryEnum,
        Transportation: 'TRANSPORTATION' as ExpensesCategoryEnum,
        TravelExpenses: 'TRAVEL_EXPENSES' as ExpensesCategoryEnum,
        Utilities: 'UTILITIES' as ExpensesCategoryEnum
    };
    export type ExpensesFrequencyEnum = 'DAILY' | 'MONTHLY' | 'ONE_TIME' | 'WEEKLY' | 'YEARLY';
    export const ExpensesFrequencyEnum = {
        Daily: 'DAILY' as ExpensesFrequencyEnum,
        Monthly: 'MONTHLY' as ExpensesFrequencyEnum,
        OneTime: 'ONE_TIME' as ExpensesFrequencyEnum,
        Weekly: 'WEEKLY' as ExpensesFrequencyEnum,
        Yearly: 'YEARLY' as ExpensesFrequencyEnum
    };
    export type ExpensesTypeEnum = 'CASH_IN' | 'CASH_OUT';
    export const ExpensesTypeEnum = {
        In: 'CASH_IN' as ExpensesTypeEnum,
        Out: 'CASH_OUT' as ExpensesTypeEnum
    };
}


