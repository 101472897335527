import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import {
  PagePaymentMethod,
  PagePosItemVsMerchant,
  PaymentMethod,
  PaymentMethodControllerService,
  PosItemVsMerchantControllerService,
} from './gen';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  cachePaymentMethod$: Observable<PagePaymentMethod>;

  constructor(
    private router: Router,
    private paymentMethodService: PaymentMethodControllerService,
  ) {}

  getCachePagedPaymentMethod(): Observable<PagePosItemVsMerchant> {
    if (!this.cachePaymentMethod$) {
      this.cachePaymentMethod$ = this.paymentMethodService
        .getPagedPaymentMethodPost({
          pageRequest: {},
        })
        .pipe(
          map((pivm) => {
            return pivm;
          }),
          shareReplay(1),
        );
    }

    return this.cachePaymentMethod$;
  }
}
