import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  input: string = '0.00';
  title: any;

  constructor(private dialogRef: MatDialogRef<string>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = data?.title || '';
  }

  ngOnInit(): void {}

  appendToInput(value: string) {
    this.input = (+this.input * 10 + +value / 100).toFixed(2) + '';
  }

  clearInput() {
    this.input = '0.00';
  }

  backspace() {
    this.input = (+this.input.slice(0, -1) / 10).toFixed(2) + '';
    if (this.input === '0.' || this.input === '') {
      this.input = '0.00';
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  update() {
    this.dialogRef.close(this.input);
  }
}
