import {ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Merchant} from '../../@services/gen';
import {AuthService} from '../../@services/auth.service';
import {UserModel} from '../../@models/user.model';
import {LocalstorageService} from '../../@services/localstorage.service';
import {kickCashDrawerz} from '../../@services/utils';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  merchant: Merchant = {};
  user: UserModel;
  currentTime: Date = new Date();
  currentTimeIntervalId: number;
  version: string = packageJson.version;

  constructor(private customAuthService: AuthService, private ls: LocalstorageService) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.merchant = this.ls.getMerchant();
  }

  ngOnDestroy(): void {
    clearInterval(this.currentTimeIntervalId);
  }

  kickCashDrawer() {
    kickCashDrawerz();
  }

  reload() {
    window.location.reload();
  }
}
