import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  private scanData = new Subject<string>();
  private tempData: string = '';
  private scanTimeout: any;

  constructor() {
    this.listenToScanner();
  }

  getScanData() {
    return this.scanData.asObservable();
  }

  private listenToScanner() {
    console.log("Init Barcode scanner");
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (this.scanTimeout) {
        clearTimeout(this.scanTimeout);
      }

      if (event.key === 'Enter') {
        this.scanData.next(this.tempData);
        this.tempData = '';
      } else {
        this.tempData += event.key;
      }

      this.scanTimeout = setTimeout(() => {
        this.tempData = '';
      }, 500); // Adjust the timeout duration as needed
    });
  }
}
