import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../@services/notification.service';
import {LocalstorageService} from '../../../@services/localstorage.service';
import {Membership, MembershipControllerService} from '../../../@services/gen';

@Component({
  selector: 'app-membership-editor-dialog',
  templateUrl: './membership-editor-dialog.component.html',
  styleUrls: ['./membership-editor-dialog.component.scss'],
})
export class MembershipEditorDialogComponent {
  membership: Membership = {};
  title = 'Add New Customer';
  loading: boolean;

  constructor(
    private dialogRef: MatDialogRef<MembershipEditorDialogComponent>,
    private customerService: MembershipControllerService,
    private ns: NotificationService,
    private ls: LocalstorageService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.membership = data.customer;
  }

  submit() {
    this.loading = true;
    this.customerService
      .upsertMembershipPost({
        customer: {...this.membership, merchant: {id: this.ls.getMerchant().id}},
      })
      .subscribe(
        (customer) => {
          this.ns.showNotification({message: 'Customer has been added'});
          this.loading = false;
          this.dialogRef.close(customer);
        },
        (error) => {
          this.ns.showNotification({message: 'Error while adding the customer'});
          this.loading = false;
        }
      );
  }
}
