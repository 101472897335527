<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="content__filter mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <span class="fs-3 mb-0 fw-bold me-3">Inventory #{{inventory?.inventoryNo}}</span>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <button class="btn btn-lg btn-default fw-semibold mb-0 d-block py-3 me-3"
                      [routerLink]="['/stock']">
                <i class="fa fa-arrow-left"></i>
                Back to list
              </button>
              <button class="btn btn-lg fw-semibold mb-0 d-block py-3 text-white bg-danger ms-3"
                      (click)="voidInventory(inventory); $event.stopPropagation()">
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </div>
          </div>
        </div>

        <div class="content__body">
          <table class="table table-bordered bg-white">
            <tbody>
            <tr>
              <th class="w-25">Inventory Date</th>
              <td>{{inventory?.creationTime | date: 'dd/MM/yy HH:mm'}}</td>
            </tr>
            <tr>
              <th>Inventory No</th>
              <td>
                <a class="p-0" [routerLink]="['/stock-detail']" [queryParams]="{id: inventory?.id}">
                  {{inventory?.inventoryNo}}
                </a>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="mt-4">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h4 class="mb-3">Inventory Items</h4>
              <button class="btn btn-sm btn-outline-primary fw-semibold mb-0 d-block py-3 ms-4"
                      (click)="addStock(); $event.stopPropagation()">
                <i class="fa fa-trash"></i>
                Add Stock Item
              </button>
            </div>

            <table class="table table-bordered bg-white">
              <thead>
              <tr>
                <th>No</th>
                <th>Item Name</th>
                <th>Quantity On Hand</th>
                <th>Adjusted Qty</th>
                <th>Average Cost</th>
                <th>Price</th>
                <th>Remarks</th>
                <th class="text-center">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of inventory?.inventoryItems; let i = index">
                <td>{{i + 1}}</td>
                <td>{{item?.posItemVsMerchant?.posItem?.name | titlecase}}</td>
                <td>{{item?.quantityOnHand}}</td>
                <td>{{item?.quantityAdjusted}}</td>
                <td>{{item?.averageCost | number: '1.2'}}</td>
                <td>{{item?.price | number: '1.2'}}</td>
                <td>{{item?.remarks ? item?.remarks : '-'}}</td>
                <td class="text-center">
                  <button class="btn btn-sm btn-outline-primary me-2" (click)="editItem(item)">
                    <i class="fa fa-edit"></i> Edit
                  </button>
                  <button class="btn btn-sm btn-outline-danger" (click)="deleteItem(item)">
                    <i class="fa fa-trash"></i> Delete
                  </button>
                </td>
              </tr>
              <tr *ngIf="!inventory?.inventoryItems?.length">
                <td colspan="7" class="text-center">
                  <div class="d-flex flex-row align-items-center justify-content-center">
                    <i class="fa fa-info-circle me-2"></i>
                    <span>No items to be displayed</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
