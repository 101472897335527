import {Injectable} from '@angular/core';
import {printz} from './utils';
import {PaymentService} from './payment.service';
import {LocalstorageService} from './localstorage.service';
import {Subject, timer} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
export class KitchenService {
  incomingOrder$: Subject<void>;
  incomingOrderTimer$;

  constructor(
    private customPaymentService: PaymentService,
    private ls: LocalstorageService,
    private authService: AuthService
  ) {}

  startTimerIncomingKitchenOrder() {
    let user = this.authService.getUser();
    // assume cashier as the HOST. So store must have cashier logged-in in order to print order receipt
    if (user && user.role.toLowerCase() === 'cashier') {
      console.log(`startTimerIncomingKitchenOrder`);

      if (!this.incomingOrder$) {
        this.incomingOrder$ = new Subject<void>();
        this.listenToIncomingKitchenOrder();
      }
      if (!this.incomingOrderTimer$) {
        this.incomingOrderTimer$ = timer(new Date(), 5000).subscribe((value) => this.incomingOrder$.next());
      }
    }
  }

  listenToIncomingKitchenOrder() {
    this.incomingOrder$.subscribe((incomingOrder) => {
      console.log(`listeningOrder: ${new Date()}`);
      let merchant = this.ls.getMerchant();
      for (let mvp of merchant.merchantVsPrinters) {
        if (mvp.printOrder && mvp.kitchen) {
          //keluar
          console.log(`asassas`, mvp.printOrder);
          this.customPaymentService.getOrderReceiptUnprinted(
            merchant.id,
            mvp.kitchen.id,
            mvp.printer.paperSizeType == 'BIG' ? 45 : 32,
            (text, hasData) => {
              //tak keLuar
              console.log(`asdasdasd`, text);
              console.log(`mvp order`, mvp);
              console.log(`hasData`, hasData);
              if (hasData) {
                printz(
                  {
                    printerName: mvp.printer.printerName,
                    printerType: mvp.printer.printerType,
                    vendorId: mvp.printer.printerVendorId,
                    ipAddress: mvp.ipAddress,
                    macAddress: mvp.macAddress,
                  },
                  [text]
                );
              }
            }
          );
        }
      }
    });
  }
}
