<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header pos-with-sidebar pos-mobile-sidebar-toggled" id="pos">
      <div class="pos-container">

        <app-header></app-header>

        <div class="pos-content overflow-y-auto thin-scroll-container">
          <div class="pos-content-container p-3">

            <div class="d-flex flex-row justify-content-start align-items-center mb-3">
              <div class="me-4 d-flex align-items-center">
                <div class="me-2 rounded w-10px h-10px bg-green"></div>
                <span>Available</span>
              </div>
              <div class="me-4 d-flex align-items-center">
                <div class="me-2 rounded w-10px h-10px bg-orange"></div>
                <span>In Use</span>
              </div>
              <div class="me-4 d-flex align-items-center">
                <div class="me-2 rounded w-10px h-10px bg-red"></div>
                <span>On Hold</span>
              </div>
            </div>

            <div class="row gx-3">
              <div class="pb-2" style="width: calc(100% / 5);" *ngFor="let item of tables"
                   (click)="selectTable(item.id)">
                <div class="pos-checkout-table"
                     [ngClass]="{'in-use': item.totalOrder > 0, 'has-unconfirmed': item.hasUnconfirmed, 'available': item.totalOrder == 0, 'selected': selectedTable?.id === item.id}">
                  <div class="pos-checkout-table-container" data-toggle="select-table">
                    <div class="pos-checkout-table-header">
                      <div class="status"><i class="fa fa-circle"></i></div>
                      <div class="fw-bold fs-4">{{ item.name }}</div>
                      <div class="fs-13px text-body text-opacity-50">{{ item.totalOrder }} order(s)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pos-sidebar" id="pos-sidebar">
          <div class="pos-sidebar-header">
            <div class="back-btn">
              <button
                type="button"
                data-toggle-class="pos-mobile-sidebar-toggled"
                data-toggle-target="#pos"
                class="btn">
                <i class="fa fa-chevron-left"></i>
              </button>
            </div>
            <div class="icon"><i class="fa fa-table"></i></div>
            <div class="title">Table {{ selectedTable?.name || "-" }}</div>

            <div class="title" *ngIf="selectedTable && unpaidOrders.length > 0" (click)="splitBill(selectedTable)">
              <i class="fa-solid fa-file-invoice-dollar"></i>
              Split Bill
            </div>

            <div class="title" *ngIf="merchant.natureOfBusiness !== 'FNB'">Counter {{ selectedTable?.name || "-" }}</div>
            <div *ngIf="selectedTable" (click)="addOrder(selectedTable)">
              <i class="fa fa-plus"></i> Add Order
            </div>
          </div>

          <hr class="m-0 opacity-1" />

          <div class="pos-sidebar-body thin-scroll-container" data-scrollbar="true" data-height="100%"
               style="height: 100%">

            <div
              class="h-100 d-flex align-items-center justify-content-center text-center p-20"
              *ngIf="(unpaidOrders.length == 0 && selectedTable && !loading.unpaidOrder)">
              <div>
                <div class="mb-3 mt-n5">
                  <i class="fa fa-shopping-cart fa-3x text-primary"></i>
                </div>
                <h5 class="text-primary">No order found</h5>
                <button class="btn btn-lg btn-primary bg-primary text-white border-0 mt-3 py-3"
                        (click)="addOrder(selectedTable)">Add Order
                </button>
              </div>
            </div>

            <div
              class="h-100 d-flex flex-column align-items-center justify-content-center text-center p-20"
              *ngIf="loading.unpaidOrder">
              <i class="fa fa-sync fa-2x mb-3 text-primary"></i>
              <h5 class="text-primary">Loading orders</h5>
            </div>

            <div
              class="h-100 d-flex align-items-center justify-content-center text-center p-20"
              *ngIf="!selectedTable">
              <div>
                <div class="mb-3 mt-n5">
                  <i class="fa fa-info-circle fa-3x"></i>
                </div>
                <h5>Select a table</h5>
              </div>
            </div>

            <div
              [class.bg-danger-100]="pastOrder?.orderStatus === 'NEW'"
              *ngFor="let pastOrder of unpaidOrders">
              <div class="w-100 pos-order p-2 py-1  align-items-center border-start-0 border-end-0 fs-15px"
                   style="border-bottom:0px solid; background-color:#FFD909;"
                   matRipple
                   mat-button [matMenuTriggerFor]="menuRef"
              >
                <div class="fw-bold">#{{ pastOrder?.orderNo }}</div>
                <div>
                  {{ pastOrder?.creationTime | date : "dd/MM HH:mm" }}
                </div>
                <div
                  class="fw-semibold"
                  [class.text-danger]="pastOrder?.orderStatus === 'NEW' || pastOrder?.orderStatus === 'UNPAID' || pastOrder?.orderStatus === 'CANCELLED' || pastOrder?.orderStatus === 'PARTIALLY_PAID'"
                  [class.text-success]="pastOrder?.orderStatus === 'PAID'">
                  {{ pastOrder?.orderStatus === 'NEW' ? 'To Confirm' : pastOrder?.orderStatus | removeUnderscores | titlecase }}
                </div>

                <div class="ms-auto">
                  <button class="btn p-0 px-2">
                    <mat-icon class="mat-18 fs-5 lh-base">edit</mat-icon>
                  </button>

                  <mat-menu #menuRef="matMenu">
                    <button mat-menu-item class="btn btn-outline-blue border-0 me-4" (click)="editOrder(pastOrder)">
                      <i class="fa fa-edit me-2"></i>
                      <span>Edit order</span>
                    </button>
                    <button mat-menu-item class="btn btn-outline-blue border-0 me-4" (click)="transferTable(pastOrder)">
                      <i class="fa fa-table me-2"></i>
                      <span>Transfer table</span>
                    </button>
                    <button mat-menu-item class="btn btn-outline-pink border-0" (click)="cancelOrder(pastOrder)">
                      <i class="fa fa-trash me-2"></i>
                      <span>Cancel order</span>
                    </button>
                  </mat-menu>
                </div>

              </div>

<!--              customer info -->
              <div class="order d-flex flex-row justify-content-start w-100 py-1 px-2">
                <div class="d-flex flex-row" *ngIf="pastOrder?.customer">
                  <div class="d-flex flex-column w-100">
                    <span class="text-left">{{ pastOrder?.customer?.name }}</span>
                    <span class="text-left">{{ pastOrder?.customer?.phoneNo }}</span>
                  </div>
                </div>
              </div>



              <div class="pos-order border-top-0 p-2 pb-0 flex-column">
                <ng-container *ngFor="let item of pastOrder?.orderItems; let i = index">
                  <div class="d-flex row m-0">
                    <div class="pos-order-product col-8">
                      <div class="flex-1">
                        <div class="fs-16px mb-1">{{ i + 1 }}) <span class="fw-bold">{{ item.name }}</span>
                        </div>
                        <div class="fs-12px mb-1" *ngIf="item?.instructions">"<span class="fst-italic">{{ item.instructions }}"</span></div>

                        <div class="mb-2 ps-20px text-muted-700 small">
                          <ng-container *ngFor="let variant of item.varianceModel">
                            <span>
                              - {{ variant.title }}
                              <ul class="mb-0">
                                <ng-container *ngFor="let variantOption of variant.options">
                                  <li>
                                    {{ variantOption.name }} +RM
                                    {{ variantOption.price | number : "1.2" }}
                                  </li>
                                </ng-container>
                              </ul>
                            </span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      x{{item?.toBePaidQuantity}}
                    </div>
                    <div class="pos-order-price col-4 d-flex flex-column">
                      <div *ngIf="item.discountPercentage > 0" class="flex-1 text-right fs-16px">
                        RM {{ getItemSubTotalWithDiscount(item) | number : "1.2" }}</div>
                      <div class="flex-1 text-right fs-16px"
                           [class.text-gray-500]="item.discountAmount > 0 ? 'line-through': ''"
                           [class.small]="item.discountAmount > 0 ? 'line-through': ''"
                           [style.text-decoration]="item.discountAmount > 0 ? 'line-through': ''">
                        <span>RM {{ getItemSubTotal(item) | number : "1.2" }}</span>
                        <span class="ms-1" *ngIf="item?.discountAmount >0">({{item?.discountPercentage}}%)</span>

                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

            </div>
          </div>

          <div class="pos-sidebar-footer">

            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="px-3">
                  <div class="d-flex w-100 justify-content-between fs-18px fw-semibold">
                    <span>Qty</span>
                    <span class="text-right">{{ toBePaidQty }}</span>
                    <span>Grand Total</span>
                    <span class="text-right">{{ toBePaidTotal | number: "1.2" }}</span>
                  </div>
                </mat-expansion-panel-header>
                <dl class="space-y-1 py-2 text-sm text-gray-700">
                  <div class="d-flex justify-content-between">
                    <dt>Subtotal</dt>
                    <dd>{{ subtotal | number: "1.2" }}</dd>
                  </div>

                  <div class="d-flex justify-content-between">
                    <dt>SST</dt>
                    <dd>{{ tax1Amount | number: "1.2" }}</dd>
                  </div>

                  <div class="d-flex justify-content-between">
                    <dt>Serv Charge</dt>
                    <dd>{{ tax2Amount | number: "1.2" }}</dd>
                  </div>

                  <div class="d-flex justify-content-between">
                    <dt>Item Discount</dt>
                    <dd>
                      -{{
                      discountItemAmount | number: "1.2"
                      }}
                    </dd>
                  </div>

                  <div class="d-flex justify-content-between">
                    <dt>Rounding</dt>
                    <dd>
                      {{ roundingAmount | number: "1.2" }}
                    </dd>
                  </div>
                </dl>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="mt-3">
              <div class="d-flex">
                <button role="button"
                        class="btn btn-default w-100 me-10px d-flex flex-column align-items-center justify-content-center"
                        (click)="printBill()"
                        [disabled]="unpaidOrders.length == 0">

                  <i class="fa fa-file-invoice fa-fw fa-lg my-10px d-block"></i>
                  <span class="small fw-semibold d-block">Bill</span>
                </button>

                <button role="button"
                        class="btn btn-success text-white w-100 me-10px d-flex flex-column align-items-center justify-content-center"
                        (click)="confirmOrder()"
                        [disabled]="newOrders.length == 0">
                  <i class="fa fa-check-circle fa-lg my-10px d-block"></i>
                  <span class="small fw-semibold">Confirm</span>
                </button>

                <button *ngIf="userRole == 'cashier' || userRole == 'admin' || userRole == 'manager'" role="button"
                        class="btn btn-default bg-primary text-white w-100 d-flex flex-column align-items-center justify-content-center"
                        (click)="pay()"
                        [disabled]="unpaidOrders.length == 0">
                  <i class="fa fa-credit-card fa-lg my-10px d-block"></i>
                  <span class="small fw-semibold">Pay</span>
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
