/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomDateDto } from './customDateDto';
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedInventoryOrderQuery { 
    date?: CustomDateDto;
    merchantCreatedById?: number;
    merchantCreatedForId?: number;
    pageRequest?: CustomPageRequest;
    search?: string;
    status?: GetPagedInventoryOrderQuery.StatusEnum;
}
export namespace GetPagedInventoryOrderQuery {
    export type StatusEnum = 'CONFIRM' | 'NEW' | 'PREPARING' | 'REJECT';
    export const StatusEnum = {
        Confirm: 'CONFIRM' as StatusEnum,
        New: 'NEW' as StatusEnum,
        Preparing: 'PREPARING' as StatusEnum,
        Reject: 'REJECT' as StatusEnum
    };
}


