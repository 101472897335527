<h1 matDialogTitle>{{ title }}</h1>

<mat-dialog-content>
  <div class="mt-2 d-flex flex-column">
    <mat-radio-group
      class="mb-4"
      aria-label="Select an option" [(ngModel)]="expenses.expensesType">
      <mat-radio-button [value]="'CASH_IN'">Cash In (+)</mat-radio-button>
      <mat-radio-button [value]="'CASH_OUT'">Cash Out (-)</mat-radio-button>
    </mat-radio-group>

    <mat-form-field floatLabel="always" appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput [(ngModel)]="expenses.description"
             type="text"
             required
             placeholder="">
    </mat-form-field>

    <mat-form-field floatLabel="always" appearance="outline">
      <mat-label>Amount</mat-label>
      <input matInput [(ngModel)]="expenses.amount"
             type="number"
             required
             placeholder="">
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button matDialogClose>Close</button>
  <button mat-raised-button color="primary"
          [disabled]="!expenses.description || (!expenses.amount && expenses.amount !== 0)"
          (click)="saveExpenses()">Save
  </button>

</mat-dialog-actions>
