/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Printer { 
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    description?: string;
    id?: number;
    paperSizeType?: Printer.PaperSizeTypeEnum;
    printerName?: string;
    printerType?: Printer.PrinterTypeEnum;
    printerVendorId?: string;
    updateTime?: string;
    updatedByName?: string;
}
export namespace Printer {
    export type PaperSizeTypeEnum = 'BIG' | 'SMALL';
    export const PaperSizeTypeEnum = {
        Big: 'BIG' as PaperSizeTypeEnum,
        Small: 'SMALL' as PaperSizeTypeEnum
    };
    export type PrinterTypeEnum = 'Bluetooth' | 'LAN' | 'USB';
    export const PrinterTypeEnum = {
        Bluetooth: 'Bluetooth' as PrinterTypeEnum,
        Lan: 'LAN' as PrinterTypeEnum,
        Usb: 'USB' as PrinterTypeEnum
    };
}


