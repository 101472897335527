import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "./auth.service";
import {TdDialogService} from "@covalent/core/dialogs";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private customAuthService: AuthService,
              private ds: TdDialogService) {
  }

  canActivate(): boolean {
    const token = this.customAuthService.getUser()?.token; // Assuming the token is stored in localStorage

    if (token) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      this.ds.openAlert({
        message: "Session invalid. Please log in again."
      });
      return false;
    }
  }
}
