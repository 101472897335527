import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  Merchant,
  OrderControllerService, OrderReq,
  PaymentControllerService,
  PaymentMethod,
  PaymentMethodControllerService,
  TableMerchant
} from "../../@services/gen";
import {printz, secondScreen} from '../../@services/utils';
import {AuthService} from '../../@services/auth.service';
import {PaymentService} from '../../@services/payment.service';
import {Router} from '@angular/router';
import {take} from 'rxjs';
import {Location} from '@angular/common';
import {StaffMealControllerService} from '../../@services/gen/api/staffMealController.service';
import {StaffMeal} from '../../@services/gen/model/staffMeal';
import {OrderService} from '../../@services/order.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {CalculatorComponent} from '../../@components/calculator/calculator.component';
import {UserModel} from '../../@models/user.model';
import {LocalstorageService} from '../../@services/localstorage.service';
import {NotificationService} from '../../@services/notification.service';
import {PaymentMethodService} from '../../@services/payment-method.service';
import {Order} from 'src/app/@services/gen/model/order';
import {Payment} from '../../@services/gen/model/payment';
import {PaymentDiscountDialogComponent} from '../payment-discount-dialog/payment-discount-dialog.component';
import {ExtendedOrder} from "../pos/pos-split-bill-dialog/pos-split-bill-dialog.component";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @ViewChild('referenceNoRef') referenceNoRef: ElementRef;
  @ViewChild('paidAmountRef') paidAmountRef: ElementRef;

  unpaidOrders: ExtendedOrder[] = [];
  paidAmount: number = 0;
  subtotal: number = 0;
  roundingAmount: number = 0;
  tax1Amount: number = 0;
  tax2Amount: number = 0;
  netAmount: number = 0;
  toBePaidTotal: number = 0;
  discountAmount: number = 0;
  discountPercentage: number = 0;
  tipsAmount: number = 0;
  discountItemAmount: number = 0;
  table: TableMerchant;
  paymentMethods: PaymentMethod[] = [];
  selectedPaymentMethod: PaymentMethod = {id: 1, name: 'Cash'};
  referenceNo: string = '';
  errMsg: string = '';
  staffMeal: StaffMeal;
  bankNotes = [];
  loading = {
    submitting: false,
  };
  paymentDiscount: number = 0;
  private user: UserModel;
  private merchant: Merchant = {};
  selectedBankNote: { name: string; value: number };

  constructor(
    private orderService: OrderControllerService,
    private paymentService: PaymentControllerService,
    private paymentMethodService: PaymentMethodControllerService,
    private customPaymentMethodService: PaymentMethodService,
    private customAuthService: AuthService,
    private customPaymentService: PaymentService,
    private router: Router,
    private location: Location,
    private staffMealService: StaffMealControllerService,
    private customOrderService: OrderService,
    private ds: TdDialogService,
    private ls: LocalstorageService,
    private ns: NotificationService
  ) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.merchant = this.ls.getMerchant();

    this.customPaymentMethodService.getCachePagedPaymentMethod().subscribe((res) => {
      this.paymentMethods = res.content || [];
      this.changePaymentMethod({id: 1, name: 'Cash'});
    });

    this.customPaymentService.unpaidOrdersObs$.pipe(take(1)).subscribe((orders) => {
      if (orders.length == 0) {
        this.router.navigate(['/']);
        return;
      }

      this.unpaidOrders = orders.map(order => order as ExtendedOrder);
      this.table = this.unpaidOrders[0].tableCustomer;

      this.unpaidOrders.forEach(x => {
        return x.discountAmount = 0;
      })
      this.calculateOrderTotalAmount();

      this.generateBankNotes(this.getToBePaidTotal());
    });
  }

  calculateOrderTotalAmount() {
    console.log(`calc total amount`, this.unpaidOrders);
    let calculatedValues = this.customOrderService.calculateOrderValues(this.unpaidOrders);
    this.subtotal = calculatedValues.paymentSubtotalItem;
    this.tax1Amount = calculatedValues.tax1Amount;
    this.tax2Amount = calculatedValues.tax2Amount;
    this.roundingAmount = calculatedValues.roundingAmount;
    this.netAmount = calculatedValues.netAmount + this.tipsAmount;
    this.discountAmount = calculatedValues.discountAmount;
    this.discountItemAmount = calculatedValues.paymentDiscountItemOrder;
    this.toBePaidTotal = calculatedValues.paymentTotalOrder + this.tipsAmount;

    return 0;
  }

  generateBankNotes(netAmount: number) {
    function checkTenthLessThanFive(number) {
      const tenth = Math.floor(number % 10);
      return tenth <= 4.99;
    }

    let bankNotes = [];
    bankNotes.push({name: `RM ${netAmount.toFixed(2)}`, value: +netAmount.toFixed(2)});

    if (checkTenthLessThanFive(+netAmount)) {
      let nearest5 = Math.ceil(netAmount / 5) * 5;
      bankNotes.push({name: `RM ${nearest5}`, value: nearest5});
    }

    let nearest10 = Math.ceil(netAmount / 10) * 10;
    bankNotes.push({name: `RM ${nearest10}`, value: nearest10});

    let nearest20 = Math.ceil(netAmount / 20) * 20;
    bankNotes.push({name: `RM ${nearest20}`, value: nearest20});

    let nearest50 = Math.ceil(netAmount / 50) * 50;
    bankNotes.push({name: `RM ${nearest50}`, value: nearest50});

    let nearest100 = Math.ceil(netAmount / 100) * 100;
    bankNotes.push({name: `RM ${nearest100}`, value: nearest100});

    let res = [];
    for (let bn of bankNotes) {
      if (res.findIndex((x) => x.name === bn.name) == -1) {
        res.push(bn);
      }
    }

    this.bankNotes = [...res];
  }

  submit() {
    this.errMsg = '';

    // if (!this.paidAmount || this.paidAmount < this.getNetAmount() || this.paidAmount == 0) {
    //   return;
    // }

    this.loading.submitting = true;

    if (this.selectedPaymentMethod.name.toLowerCase() === 'staff meal') {
      this.staffMealService
        .getStaffMealByUsernamePost({
          username: this.referenceNo,
        })
        .subscribe(
          (res) => {
            if (res) {
              if (res.balance < this.getToBePaidTotal()) {
                this.loading.submitting = false;
                this.staffMeal = res;
                this.errMsg = `Error: Insufficient Balance. RM${res.balance}`;
                return;
              }
            } else {
              this.loading.submitting = false;
              this.errMsg = `Error: Staff not found`;
              return;
            }

            this.pay();
          },
          (error) => {
            this.errMsg = `Error: ${error.error.errorCode}`;
            this.loading.submitting = false;
          }
        );
    } else {
      this.pay();
    }
  }

  private pay() {
    let merchant = this.ls.getMerchant();
    let payment: Payment = {
      merchant: merchant,
      tableId: this.table.id,
      paymentNo: this.generatePaymentNo(),
      paymentMethod: this.selectedPaymentMethod,
      ewalletReferenceNo: this.referenceNo || null,
      paymentItems: this.unpaidOrders.map((o) => {
        return {
          order: o,
        };
      }),
    };

    payment.totalAmount = this.subtotal;
    payment.paidAmount = this.paidAmount;
    payment.roundingAmount = this.roundingAmount;
    payment.tax1Amount = this.tax1Amount;
    payment.tax2Amount = this.tax2Amount;
    payment.discountAmount = this.discountAmount;
    payment.discountPercentage = this.discountPercentage;
    payment.tipsAmount = this.tipsAmount;
    payment.discountItemAmount = this.discountItemAmount;
    payment.changeAmount = +(this.paidAmount - this.getToBePaidTotal()).toFixed(2);
    payment.netAmount = this.getToBePaidTotal();

    this.loading.submitting = true;
    let dialogRef = this.ns.showNotification({
      message: 'Payment in progress...',
      icon: 'fa fa-sync',
      manualClose: true,
    });
    this.paymentService
      .upsertPaymentPost({
        payment: payment,
      })
      .subscribe(
        (res) => {
          console.log(`res`, res);
          dialogRef.close();
          this.printReceipt(res.id);

          this.router.navigate(['/payment-success'], {
            queryParams: {
              paymentId: res.id,
            },
          });

          this.loading.submitting = false;
        },
        (error) => {
          this.loading.submitting = false;
        }
      );
  }

  private printReceipt(paymentId: number) {
    for (let mvp of this.merchant.merchantVsPrinters) {
      let dialogRef = this.ns.showNotification({
        message: 'Reprint receipt',
        manualClose: true,
        icon: 'fa fa-sync',
      });

      if (mvp.printReceipt) {
        this.customPaymentService.getPaymentReceipt(paymentId, mvp.printer.paperSizeType == 'BIG' ? 45 : 32, (res) => {
          dialogRef.close();
          console.log(`mvp receipt`, mvp);
          printz(
            {
              printerName: mvp.printer.printerName,
              printerType: mvp.printer.printerType,
              vendorId: mvp.printer.printerVendorId,
              ipAddress: mvp.ipAddress,
              macAddress: mvp.macAddress,
            },
            [res]
          );
        });
      } else {
        dialogRef.close();
      }
    }
  }

  getToBePaidTotal() {
    return +this.toBePaidTotal.toFixed(2);
  }

  generatePaymentNo() {
    const prefix = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    const suffix = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, '0');
    return `${prefix}-${suffix}`;
  }

  changePaymentMethod(item: PaymentMethod) {
    this.errMsg = '';
    this.selectedPaymentMethod = item;

    if (item.name.toLowerCase() !== 'cash') {
      this.paidAmount = this.getToBePaidTotal();
    } else {
      this.referenceNo = '';
      this.paidAmount = 0;
    }

    setTimeout(() => {
      if (item.name.toLowerCase() === 'cash') {
        // this.paidAmountRef.nativeElement.focus();
      } else {
        this.referenceNoRef.nativeElement.focus();
      }
    });
  }

  changeBankNote(item: {name: string; value: number}) {
    this.selectedBankNote = item;
    this.paidAmount = item.value;
  }

  back() {
    this.location.back();
  }

  isDisabled() {
    if (this.isStaffMeal()) {
      if (!this.referenceNo) {
        return true;
      }
    }

    let changeAmount = (this.paidAmount - this.getToBePaidTotal()).toFixed(2);
    if(+changeAmount < 0) {
      return true;
    }
    // return (
    //   !this.paidAmount ||
    //   +this.paidAmount?.toFixed(2) < +this.getNetAmount() ||
    //   this.paidAmount == 0 ||
    //   +changeAmount < 0
    // );

    if(this.paidAmount == 0 && this.getToBePaidTotal() > 0){
      return true
    }

    return false;
  }

  isStaffMeal() {
    return this.selectedPaymentMethod.name.toLowerCase() === 'staff meal';
  }

  isCash() {
    return this.selectedPaymentMethod.name.toLowerCase() === 'cash';
  }

  updateCashPaidAmount(evt: string) {
    this.paidAmount = +evt ?? 0;
  }

  updateDiscountAmount({cashDiscount, discountPercentage}) {
    this.discountAmount = +cashDiscount ?? 0;
    this.discountPercentage = +discountPercentage ?? 0;
    this.divideBillDiscount(this.discountAmount, this.unpaidOrders);
    this.calculateOrderTotalAmount();
    this.changePaymentMethod(this.selectedPaymentMethod);
    this.generateBankNotes(this.getToBePaidTotal());
  }

  updateTipsAmount(evt: string) {
    this.tipsAmount = +evt ?? 0;
    this.calculateOrderTotalAmount();
    this.changePaymentMethod(this.selectedPaymentMethod);
    this.generateBankNotes(this.getToBePaidTotal());
  }

  displayDiscountDialog() {
    // reset discount
    this.updateDiscountAmount({cashDiscount: 0, discountPercentage: 0});
    this.ds
      .open(PaymentDiscountDialogComponent, {
        data: {
          totalAmount: this.getToBePaidTotal(),
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if(res){
          this.updateDiscountAmount(res);
          this.updateSecondScreen();
        }
      });
  }

  displayCalculator(type: 'cash' | 'discount' | 'tips') {
    let title = '';
    switch (type) {
      case 'cash':
        title = 'Enter tendered amount';
        break;
      case 'discount':
        title = 'Enter discount amount';
        break;
      case 'tips':
        title = 'Enter tips amount';
        break;
    }

    this.ds
      .open(CalculatorComponent, {
        data: {title: title},
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        switch (type) {
          case 'cash':
            this.updateCashPaidAmount(res);
            break;
          case 'discount':
            this.updateDiscountAmount(res);
            break;
          case 'tips':
            this.updateTipsAmount(res);
            break;
        }

        this.updateSecondScreen();
      });
  }

  private updateSecondScreen() {
    secondScreen({
      merchantName: this.merchant.companyName,
      merchantLogoUrl: this.merchant['merchantLogoSmall'],
      orderItems: this.unpaidOrders.flatMap((o) => o.orderItems.filter((oi) => !oi.deleted)),
      payment: undefined,
      discountAmount: this.discountAmount,
      discountItemAmount: this.unpaidOrders.reduce((a, b) => a + b.discountItemAmount, 0),
      netAmount: this.getToBePaidTotal(),
      quantity: this.unpaidOrders
        .flatMap((o) => o.orderItems.filter((oi) => !oi.deleted))
        .map((x) => x.quantity)
        .reduce((a, b) => a + b, 0),
      roundingAmount: this.roundingAmount,
      subtotal: this.subtotal,
      tax1Amount: this.tax1Amount,
      tax2Amount: this.tax2Amount,
      totalAmount: this.subtotal,
    });
  }

  divideBillDiscount(totalDiscount, orders) {
    const totalOrders = orders.length;

    const discountPerOrder = totalDiscount / totalOrders;

    let remainingDiscount = totalDiscount;
    const lastOrderIndex = orders.length - 1;

    for (let i = 0; i < lastOrderIndex; i++) {
      const currentDiscount = +discountPerOrder.toFixed(2);
      remainingDiscount -= currentDiscount;
      orders[i].discountAmount = currentDiscount;
    }

    orders[lastOrderIndex].discountAmount = remainingDiscount;
  }
}
