import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrderItem} from "../../../@services/gen/model/orderItem";
import {
  GetPagedOrderQuery,
  OrderControllerService, OrderItemReq, OrderReq,
  TableMerchant,
  TableMerchantControllerService
} from "../../../@services/gen";
import * as dayjs from "dayjs";
import {UserShiftService} from "../../../@services/user-shift.service";
import {Order} from "../../../@services/gen/model/order";
import OrderStatusEnum = OrderItemReq.OrderStatusEnum;
import {Subject} from "rxjs";

export interface ExtendedOrderItems extends OrderItemReq {
  selected: boolean;
  tempId: string;
  tempQty: number;
  order: OrderReq;
}

export interface ExtendedOrder extends OrderReq {
  toBePaidTotal: number;
  orderItems: ExtendedOrderItems[];
}

@Component({
  selector: "app-pos-split-bill-dialog",
  templateUrl: "./pos-split-bill-dialog.component.html",
  styleUrls: ["./pos-split-bill-dialog.component.scss"],
})
export class PosSplitBillDialogComponent {
  tableMerchant: TableMerchant;
  merchantId: number;
  unpaidOrders: ExtendedOrder [];
  selectedRows: boolean[] = [];
  loadUnpaidOrders$: Subject<OrderReq[]> = new Subject<OrderReq[]>();

  constructor(private dialogRef: MatDialogRef<PosSplitBillDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private orderService: OrderControllerService, private customUserShiftService: UserShiftService) {
    this.tableMerchant = data.tableMerchant;
    this.merchantId = data.merchantId;
    this.loadUnpaidOrders(data.tableMerchant);
  }

  private loadUnpaidOrders(table: TableMerchant) {
    let shiftStartDate = this.customUserShiftService.getUserShift().start;
    this.orderService
      .getPagedOrderPost({
        merchantId: this.merchantId,
        tableId: table.id,
        orderStatusEnum: [GetPagedOrderQuery.OrderStatusEnumEnum.Unpaid, GetPagedOrderQuery.OrderStatusEnumEnum.PartiallyPaid],
        orderItemStatusEnum: [GetPagedOrderQuery.OrderStatusEnumEnum.Unpaid, GetPagedOrderQuery.OrderStatusEnumEnum.PartiallyPaid,],
        startDate: shiftStartDate,
        endDate: dayjs(new Date()).endOf('day').toString(),
        pageRequest: {
          page: 0,
          size: 9999,
          orders: [{direction: 'DESC', property: 'creationTime'}],
        },
      })
      .subscribe((res) => {

        this.unpaidOrders = (res.content as OrderReq[]).map(order => ({
          ...order,
          orderItems: order.orderItems.flatMap(item =>
            Array.from({length: item.quantity - item.paidQuantity}, (_, quantityIndex) => ({
              ...item,
              order: {id: order.id},
              netAmount: (item.netAmount - (item.netAmount * (item.discountPercentage / 100))) / (item.quantity),
              tempQty: 1,
              selected: false,
              tempId: `${item.id}_${quantityIndex + 1}`
            }))
          )
        })) as ExtendedOrder[];

        this.loadUnpaidOrders$.next(this.unpaidOrders);
      });
  }

  toggleItemSelection(orderIndex: number, itemIndex: number) {
    this.unpaidOrders[orderIndex].orderItems[itemIndex].selected = !this.unpaidOrders[orderIndex].orderItems[itemIndex].selected;
  }

  isItemSelected(orderIndex: number, itemIndex: number): boolean {
    return this.unpaidOrders[orderIndex].orderItems[itemIndex].selected;
  }

  toggleRowSelection(orderIndex: number) {
    this.unpaidOrders[orderIndex].orderItems.forEach(item => {
      item.selected = !item.selected;
    });
  }

  getOrdersWithSelectedItems(): ExtendedOrder[] {
    const ordersWithSelectedItems: ExtendedOrder[] = [];

    this.unpaidOrders.forEach(order => {
      const selectedItems = order.orderItems.filter(item => item.selected);
      if (selectedItems.length > 0) {
        ordersWithSelectedItems.push({ ...order, orderItems: selectedItems });
      }
    });

    console.log(this.unpaidOrders);
    return ordersWithSelectedItems;
  }


  submit() {
    const ordersWithSelectedItems = this.getOrdersWithSelectedItems();
    const combinedItemsMap = new Map<number, ExtendedOrderItems>();

    ordersWithSelectedItems.forEach(order => {
      order.orderItems.forEach(orderItem => {
        // Check if the item is selected
        if (orderItem.selected) {
          const existingItem = combinedItemsMap.get(orderItem.id);
          if (existingItem) {
            console.log("Item already exists");
            // If item already exists, add its quantity to the existing one
            console.log(existingItem);
            existingItem.paidQuantity = existingItem.paidQuantity + 1;
            existingItem.toBePaidQuantity = existingItem.toBePaidQuantity + 1;
          } else {
            console.log("New item");
            // If item doesn't exist, add it to the map
            orderItem.paidQuantity = orderItem.paidQuantity + 1;
            orderItem.toBePaidQuantity = orderItem.toBePaidQuantity + 1;
            combinedItemsMap.set(orderItem.id, { ...orderItem });
          }
        }
      });
    });

    // Convert map values back to an array of items
    const combinedItems = Array.from(combinedItemsMap.values());

    // Update orderItems with combined items
    ordersWithSelectedItems.forEach(order => {
      order.orderItems = order.orderItems.filter(orderItem => !orderItem.selected).concat(combinedItems);

      order.orderItems = order.orderItems.filter(item => {
        return item.order.id === order.id;
      });

      let paidAmount = 0.0;

      order.orderItems.forEach(orderItem => {
        paidAmount = paidAmount + (orderItem.netAmount * orderItem.toBePaidQuantity);
      })

      order.toBePaidTotal = paidAmount;
    });


    this.dialogRef.close({
      selectedPaidOrder: ordersWithSelectedItems,
    });
  }

  checkIfSelectItem(){
    if(this.unpaidOrders){
      for (const order of this.unpaidOrders) {
        for (const item of order.orderItems) {
          if (item.selected) {
            console.log(true); // At least one item is selected
            return false;
          }
        }
      }
      console.log(false); // No items are selected
    }

    return true;
  }
}
