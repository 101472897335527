/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomDateDto } from './customDateDto';
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedInventoryQuery { 
    date?: CustomDateDto;
    merchantId?: number;
    pageRequest?: CustomPageRequest;
    reason?: GetPagedInventoryQuery.ReasonEnum;
    search?: string;
}
export namespace GetPagedInventoryQuery {
    export type ReasonEnum = 'INITIAL_STOCK' | 'PAYMENT_ORDER' | 'STOCK_IN' | 'STOCK_OUT';
    export const ReasonEnum = {
        InitialStock: 'INITIAL_STOCK' as ReasonEnum,
        PaymentOrder: 'PAYMENT_ORDER' as ReasonEnum,
        StockIn: 'STOCK_IN' as ReasonEnum,
        StockOut: 'STOCK_OUT' as ReasonEnum
    };
}


