<div class="pos-header">
  <div class="logo d-flex align-items-center">
    <a (click)="reload(); $event.preventDefault()">
      <img src="./assets/img/duck-pos-dark.png" style="height: 25px;">
    </a>
    <span class="ms-2 text-gray-500">v{{version}}</span>
  </div>
  <div class="nav w-100">
    <div class="nav-item">
      <a routerLink="/" class="text-decoration-none fs-6 nav-link">
        <div class="d-flex flex-column align-items-center ">
          <i class="fa fa-star text-yellow-400"></i>
          <span>New Sales</span>
        </div>
      </a>
    </div>
    <div class="nav-item">
      <a routerLink="/expenses-list" class="text-decoration-none fs-6 nav-link">
        <div class="d-flex flex-column align-items-center ">
          <i class="fa fa-money-bill text-yellow-400"></i>
          <span>Cash In Out</span>
        </div>
      </a>
    </div>
    <div class="nav-item">
      <a routerLink="/payment-list" class="text-decoration-none fs-6 nav-link">
        <div class="d-flex flex-column align-items-center ">
          <i class="fa fa-history text-yellow-400"></i>
          <span>Sales History</span>
        </div>
      </a>
    </div>

<!--    <div class="nav-item">-->
<!--      <a routerLink="/stock" class="text-decoration-none fs-6 nav-link">-->
<!--        <div class="d-flex flex-column align-items-center ">-->
<!--          <i class="fa fa-history text-yellow-400"></i>-->
<!--          <span>Stock Adjustment</span>-->
<!--        </div>-->
<!--      </a>-->
<!--    </div>-->
<!--    <div class="nav-item">-->
<!--      <a href="" (click)="kickCashDrawer();$event.preventDefault(); " class="text-decoration-none fs-6 nav-link">-->
<!--        <div class="d-flex flex-column align-items-center ">-->
<!--          <i class="fa fa-cash-register text-yellow-400"></i>-->
<!--          <span>Cash Drawer</span>-->
<!--        </div>-->
<!--      </a>-->
<!--    </div>-->
    <div class="nav-item">
      <a routerLink="/user-profile" class="nav-link">
        <div class="d-flex justify-content-between align-items-center">
          <div style="height: 40px; width: 40px; border-radius: 50%; border: 1px yellow solid"
               class="d-flex justify-content-center align-items-center me-2">
            <img
              *ngIf="merchant?.merchantLogo"
              [ngStyle]="{'background-image': 'url(' +merchant?.['merchantLogoSmall']+')'}" class="w-100 h-100"
              style="border-radius: 50%; background-size: cover; background-repeat: no-repeat; background-position: center" />
            <span *ngIf="!merchant?.['merchantLogoSmall']">{{user?.username | slice:0:2 | uppercase}}</span>
          </div>
          <span>{{user?.username}} <br>
            <span class="small float-end" style="color: #fcd609; font-size: 11px; text-align: right">{{user.role | titlecase}}</span></span>
        </div>
      </a>
    </div>
  </div>
</div>
