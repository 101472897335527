<mat-dialog-content>
  <div class="w-100">

    <div class="fs-4 text-black mb-4 fw-bold">Enter discount amount</div>

    <div class="d-flex flex-column">
      <h5 class="text-dark-400 mb-2">
        <span>Cash Discount (RM)</span>
      </h5>
      <div class="fs-3 fw-bold">RM
        <input type="number"
               class="p-2 px-3 rounded w-200px"
               style="border: 1px solid var(--bs-border-color)"
               tabindex="-1"
               [(ngModel)]="cashDiscount"
               (ngModelChange)="calculate('cash')">
      </div>
    </div>

    <hr class="my-3">

    <div class="d-flex flex-column">
      <h5 class="text-dark-400 mb-2">
        <span>Percentage Discount (%)</span>
      </h5>
      <div class="d-flex justify-content-center align-content-around mb-2">
        <ng-container *ngFor="let item of percentagePresets; let i=index">
          <ng-container>
            <button
              class="border-0 border-gray-500 rounded p-2 py-4 me-2 w-100px fs-5"
              [ngClass]="{'bg-primary text-white': selectedPercentageDiscountIndex === i}"
              (click)="selectPercentageDiscount(item, i)">{{ item }} {{ item !== 'custom' ? '%' : '' }}
            </button>
          </ng-container>
        </ng-container>
      </div>

      <div class="d-flex flex-column">
        <div class="fs-3 fw-bold"
             *ngIf="showPercentageDiscountInput">
          <input type="number"
                 class="p-2 px-3 rounded mb-3 w-200px"
                 style="border: 1px solid var(--bs-border-color)"
                 tabindex="-1"
                 [(ngModel)]="discountPercentage"
                 (ngModelChange)="calculate('percentage')">
          %
        </div>
      </div>
    </div>
    <hr class="my-3">

    <table>
      <tr>
        <td class="h5 w-200px">Item</td>
        <td class="h5 text-right">{{orderItem?.name}} (x{{orderItem?.quantity}})</td>
      </tr>
      <tr>
        <td class="h5 w-200px">To Pay</td>
        <td class="h5 text-right">RM {{orderItem?.subtotal | number: '1.2'}}</td>
      </tr>
      <tr>
        <td class="h5">After Discount</td>
        <td class="h5 text-right text-green">RM {{ (orderItem?.subtotal - cashDiscount) | number: '1.2' }}</td>
      </tr>
    </table>

  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 p-3">
    <div class="col-4 me-3">
      <button class="btn btn-default border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button" (click)="submit()">
      <button
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center">
        <i class="fa fa-check-circle me-2"></i>
        <span>Apply Discount</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
