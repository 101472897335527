/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Merchant } from './merchant';
import { InventoryOrderItem } from './inventoryOrderItem';


export interface InventoryOrder { 
    confirmedBy?: string;
    confirmedDate?: string;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    deliveryDate?: string;
    id?: number;
    inventoryOrderAction?: InventoryOrder.InventoryOrderActionEnum;
    inventoryOrderItems?: Array<InventoryOrderItem>;
    itemType?: InventoryOrder.ItemTypeEnum;
    merchant?: Merchant;
    merchantCreatedBy?: Merchant;
    merchantCreatedFor?: Merchant;
    orderNo?: string;
    receiveStatus?: InventoryOrder.ReceiveStatusEnum;
    receivedBy?: string;
    receivedDate?: string;
    remark?: string;
    status?: InventoryOrder.StatusEnum;
    supplierName?: string;
    updateTime?: string;
    updatedByName?: string;
}
export namespace InventoryOrder {
    export type InventoryOrderActionEnum = 'ADMIN_APPROVED' | 'ADMIN_REJECT' | 'MERCHANT_RECEIVED';
    export const InventoryOrderActionEnum = {
        AdminApproved: 'ADMIN_APPROVED' as InventoryOrderActionEnum,
        AdminReject: 'ADMIN_REJECT' as InventoryOrderActionEnum,
        MerchantReceived: 'MERCHANT_RECEIVED' as InventoryOrderActionEnum
    };
    export type ItemTypeEnum = 'POS' | 'RAW_MATERIAL';
    export const ItemTypeEnum = {
        Pos: 'POS' as ItemTypeEnum,
        RawMaterial: 'RAW_MATERIAL' as ItemTypeEnum
    };
    export type ReceiveStatusEnum = 'FULLY_RECEIVED' | 'NOT_RECEIVED' | 'PARTIAL_RECEIVED';
    export const ReceiveStatusEnum = {
        FullyReceived: 'FULLY_RECEIVED' as ReceiveStatusEnum,
        NotReceived: 'NOT_RECEIVED' as ReceiveStatusEnum,
        PartialReceived: 'PARTIAL_RECEIVED' as ReceiveStatusEnum
    };
    export type StatusEnum = 'CONFIRM' | 'NEW' | 'PREPARING' | 'REJECT';
    export const StatusEnum = {
        Confirm: 'CONFIRM' as StatusEnum,
        New: 'NEW' as StatusEnum,
        Preparing: 'PREPARING' as StatusEnum,
        Reject: 'REJECT' as StatusEnum
    };
}


