export * from './authController.service';
import { AuthControllerService } from './authController.service';
export * from './basicErrorController.service';
import { BasicErrorControllerService } from './basicErrorController.service';
export * from './dashboardController.service';
import { DashboardControllerService } from './dashboardController.service';
export * from './expensesController.service';
import { ExpensesControllerService } from './expensesController.service';
export * from './expensesHistoryController.service';
import { ExpensesHistoryControllerService } from './expensesHistoryController.service';
export * from './inventoryController.service';
import { InventoryControllerService } from './inventoryController.service';
export * from './inventoryOrderController.service';
import { InventoryOrderControllerService } from './inventoryOrderController.service';
export * from './kitchenController.service';
import { KitchenControllerService } from './kitchenController.service';
export * from './kitchenOrderController.service';
import { KitchenOrderControllerService } from './kitchenOrderController.service';
export * from './membershipController.service';
import { MembershipControllerService } from './membershipController.service';
export * from './merchantController.service';
import { MerchantControllerService } from './merchantController.service';
export * from './merchantVsPrinterController.service';
import { MerchantVsPrinterControllerService } from './merchantVsPrinterController.service';
export * from './merchantVsTaxController.service';
import { MerchantVsTaxControllerService } from './merchantVsTaxController.service';
export * from './merchantVsUserController.service';
import { MerchantVsUserControllerService } from './merchantVsUserController.service';
export * from './metricController.service';
import { MetricControllerService } from './metricController.service';
export * from './orderController.service';
import { OrderControllerService } from './orderController.service';
export * from './orderItemController.service';
import { OrderItemControllerService } from './orderItemController.service';
export * from './paymentController.service';
import { PaymentControllerService } from './paymentController.service';
export * from './paymentMethodController.service';
import { PaymentMethodControllerService } from './paymentMethodController.service';
export * from './paymentOrderItemController.service';
import { PaymentOrderItemControllerService } from './paymentOrderItemController.service';
export * from './posItemCategoryController.service';
import { PosItemCategoryControllerService } from './posItemCategoryController.service';
export * from './posItemController.service';
import { PosItemControllerService } from './posItemController.service';
export * from './posItemRawMaterialController.service';
import { PosItemRawMaterialControllerService } from './posItemRawMaterialController.service';
export * from './posItemUnitController.service';
import { PosItemUnitControllerService } from './posItemUnitController.service';
export * from './posItemVsMerchantController.service';
import { PosItemVsMerchantControllerService } from './posItemVsMerchantController.service';
export * from './printerController.service';
import { PrinterControllerService } from './printerController.service';
export * from './reportController.service';
import { ReportControllerService } from './reportController.service';
export * from './staffController.service';
import { StaffControllerService } from './staffController.service';
export * from './staffMealConfigController.service';
import { StaffMealConfigControllerService } from './staffMealConfigController.service';
export * from './staffMealController.service';
import { StaffMealControllerService } from './staffMealController.service';
export * from './tableMerchantController.service';
import { TableMerchantControllerService } from './tableMerchantController.service';
export * from './taxConfigController.service';
import { TaxConfigControllerService } from './taxConfigController.service';
export * from './uploaderController.service';
import { UploaderControllerService } from './uploaderController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userRoleController.service';
import { UserRoleControllerService } from './userRoleController.service';
export const APIS = [AuthControllerService, BasicErrorControllerService, DashboardControllerService, ExpensesControllerService, ExpensesHistoryControllerService, InventoryControllerService, InventoryOrderControllerService, KitchenControllerService, KitchenOrderControllerService, MembershipControllerService, MerchantControllerService, MerchantVsPrinterControllerService, MerchantVsTaxControllerService, MerchantVsUserControllerService, MetricControllerService, OrderControllerService, OrderItemControllerService, PaymentControllerService, PaymentMethodControllerService, PaymentOrderItemControllerService, PosItemCategoryControllerService, PosItemControllerService, PosItemRawMaterialControllerService, PosItemUnitControllerService, PosItemVsMerchantControllerService, PrinterControllerService, ReportControllerService, StaffControllerService, StaffMealConfigControllerService, StaffMealControllerService, TableMerchantControllerService, TaxConfigControllerService, UploaderControllerService, UserControllerService, UserRoleControllerService];
