import {Injectable} from '@angular/core';
import {LocalstorageService} from './localstorage.service';
import {TaxConfig} from './gen';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  constructor(private ls: LocalstorageService) {}

  getTaxRates(): {tax1Config: TaxConfig; tax2Config: TaxConfig} {
    let result = {
      tax1Config: null,
      tax2Config: null,
    };

    for (let i = 0; i < this.ls.getMerchant().merchantVsTaxes.length; i++) {
      let merchantVsTax = this.ls.getMerchant().merchantVsTaxes[i];
      if (i == 0) {
        result.tax1Config = merchantVsTax?.taxConfig;
      } else {
        result.tax2Config = merchantVsTax?.taxConfig;
      }
    }

    return result;
  }
}
