import {Injectable} from '@angular/core';
import {UserModel} from '../@models/user.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _key = 'dposPosUser';

  constructor(private router: Router) {}

  saveUser(user: UserModel) {
    localStorage.setItem(this._key, JSON.stringify(user));
  }

  getUser(): UserModel {
    let userJson = localStorage.getItem(this._key);
    let userModel = JSON.parse(userJson) as UserModel;
    return userModel;
  }
}
