import {Component, OnDestroy, OnInit} from "@angular/core";
import {
  Merchant,
  OrderControllerService,
  OrderReq,
  PagePosItemVsMerchant,
  PaymentControllerService,
  PosItemCategory,
  PosItemVsMerchant,
  TableMerchant,
  TableMerchantControllerService,
  TaxConfig,
} from '../../@services/gen';
import {TdDialogService} from '@covalent/core/dialogs';
import {PosItemDialogComponent} from './pos-product-dialog/pos-item-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {PosItemService} from '../../@services/pos-item.service';
import {TaxService} from '../../@services/tax.service';
import {OrderService} from '../../@services/order.service';
import {PosDiscountDialogComponent} from './pos-discount-dialog/pos-discount-dialog.component';
import {Subscription, take, tap} from "rxjs";
import {NotificationService} from '../../@services/notification.service';
import {CalculatorComponent} from '../../@components/calculator/calculator.component';
import {secondScreen} from '../../@services/utils';
import {PaymentService} from '../../@services/payment.service';
import {LocalstorageService} from '../../@services/localstorage.service';
import {Order} from 'src/app/@services/gen/model/order';
import {OrderItem} from '../../@services/gen/model/orderItem';
import {AuthService} from '../../@services/auth.service';
import {MembershipSearchDialogComponent} from '../membership/membership-search-dialog/membership-search-dialog.component';
import OrderStatusEnum = Order.OrderStatusEnum;
import KitchenOrderStatusEnum = Order.KitchenOrderStatusEnum;
import {BarcodeScannerService} from "../../@services/barcode-scanner.service";
import {ExtendedOrder, ExtendedOrderItems} from "./pos-split-bill-dialog/pos-split-bill-dialog.component";

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss'],
})
export class PosComponent implements OnInit {
  order: ExtendedOrder;
  selectedCategory: PosItemCategory = {id: 0};
  posCategories: PosItemCategory[] = [];
  posItems?: PosItemVsMerchant[] = [];
  filteredPosItems: PosItemVsMerchant[] = [];
  merchant: Merchant = {};
  table: TableMerchant = {};
  loading = {
    submitting: false,
    holding: false,
  };
  taxRates: {tax1Config: TaxConfig; tax2Config: TaxConfig};
  orderQuantity: number = 0;
  message: string = 'FNB';

  scannedData: string = '';
  private subscription: Subscription;

  constructor(
    private customPosItemService: PosItemService,
    private orderService: OrderControllerService,
    private paymentService: PaymentControllerService,
    private ns: NotificationService,
    private tableService: TableMerchantControllerService,
    private ds: TdDialogService,
    private ls: LocalstorageService,
    private route: ActivatedRoute,
    private router: Router,
    private customTaxService: TaxService,
    private customOrderService: OrderService,
    private customPaymentService: PaymentService,
    private customAuthService: AuthService,
    private barcodeScannerService: BarcodeScannerService,
    private posItemService: PosItemService
  ) {}

  ngOnInit(): void {
    this.merchant = this.ls.getMerchant();
    this.taxRates = this.customTaxService.getTaxRates();

    this.route.queryParams.subscribe((qp) => {
      if (qp['tableId']) {
        this.tableService
          .getTableMerchantPost({
            id: +qp['tableId'],
          })
          .subscribe((res) => {
            this.table = res;

            if (qp['orderId']) {
              this.orderService
                .getOrderPost({
                  id: +qp['orderId'],
                })
                .subscribe((o) => {
                  this.order = o as ExtendedOrder;
                  this.calculateOrderTotalAmount();
                });
            } else {
              this.initOrder();
            }

            this.load();
          });
      }
    });


    this.subscription = this.barcodeScannerService.getScanData().subscribe(data => {
      this.scannedData = data;
      this.scanBarcode(this.scannedData)
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  load() {
    this.loadPosItems();
  }

  private loadPosItems() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.customPosItemService
      .getCachePagedPosItemVsMerchant(this.merchant.id)
      .pipe(tap((value) => {}))
      .subscribe(
        (res) => {
          if (res && res.content) {
            dialogRef.close();

            this.posItems = res.content;
            this.filteredPosItems = res.content;

            this.populateCategorys(res);
            this.changeCategory(this.posCategories[0].id);
          } else {
            dialogRef.close();
          }
        },
        (error) => {
          dialogRef.close();
        }
      );
  }

  private populateCategorys(res: PagePosItemVsMerchant) {
    // unique categories
    let posItemCategories = res.content.map((x) => x.posItem.posItemCategory);
    for (let posItemCategory of posItemCategories) {
      if (this.posCategories.findIndex((x) => x.id === posItemCategory.id) == -1) {
        this.posCategories.push(posItemCategory);
      }
    }
  }

  changeCategory(catId: number) {
    this.selectedCategory.id = catId;
    this.filteredPosItems = this.posItems.filter((x) => x.posItem.posItemCategory.id === catId);
  }

  searchMenu(value: any) {
    if (!value) {
      this.filteredPosItems = this.posItems.filter(
        (x) => this.selectedCategory.id === 0 || x.posItem.posItemCategory.id === this.selectedCategory.id
      );
    } else {
      this.filteredPosItems = this.posItems.filter(
        (x) =>
          x?.posItem?.name.toLowerCase().includes(value.toLowerCase()) || x.barcode?.toLowerCase().includes(value.toLowerCase()) ||
          (x.posItem?.skuNo && x.posItem?.skuNo.toLowerCase().includes(value.toLowerCase()))
      );
    }
  }

  addItem(item: PosItemVsMerchant) {
    if (item.posItem.variance) {
      item.posItem.varianceModel = [...JSON.parse(item.posItem.variance)];
    }

    if (item.posItem.varianceModel.length > 0) {
      this.ds
        .open(PosItemDialogComponent, {
          width: '90%',
          height: '90%',
          data: {
            orderItem: {
              name: item.posItem.name,
              price: item.price,
              skuNo: item.posItem.skuNo,
              quantity: 1,
              subtotal: item.price,
              discountAmount: 0,
              total: 0,
              posItemVsMerchant: item,
              averageCost: item.averageCost ?? 0,
              deleted: false,
            } as ExtendedOrderItems,
          },
        })
        .afterClosed()
        .subscribe((res: ExtendedOrderItems) => {
          if (res) {
            this.updateOrder(res);
          }
        });
    } else {
      let res = {
        name: item.posItem.name,
        price: item.price,
        skuNo: item.posItem.skuNo,
        quantity: 1,
        subtotal: item.price,
        discountAmount: 0,
        total: 0,
        posItemVsMerchant: item,
        averageCost: item.averageCost ?? 0,
        deleted: false,
      } as ExtendedOrderItems;
      this.updateOrder(res);
    }
  }

  editItem(orderItem: OrderItem) {
    let item = orderItem.posItemVsMerchant;
    if (item.posItem.variance) {
      item.posItem.varianceModel = [...JSON.parse(item.posItem.variance)];
    }

    this.ds
      .open(PosItemDialogComponent, {
        width: '90%',
        height: '90%',
        data: {
          orderItem: orderItem,
        },
      })
      .afterClosed()
      .subscribe((res: ExtendedOrderItems) => {
        if (res) {
          this.updateOrder(res, true);
        }
      });
  }

  removeItem(index) {
    this.order.orderItems.filter((x) => !x.deleted)[index].deleted = true;
    this.order.orderItems = [...this.order.orderItems];
    this.calculateOrderTotalAmount();
    this.updateSecondScreen();
  }

  updateOrder(orderItem: ExtendedOrderItems, isUpdate: boolean = false) {
    let generatedId = orderItem.skuNo + orderItem.variance;
    orderItem['generatedId'] = generatedId;

    let index = this.order.orderItems.findIndex((x) => x['generatedId'] === generatedId && !x.deleted);
    if (index > -1) {
      if (isUpdate) {
        this.order.orderItems[index].quantity = orderItem.quantity;
      } else {
        this.order.orderItems[index].quantity += orderItem.quantity;
      }
    } else {
      this.order.orderItems = [orderItem, ...this.order.orderItems];
    }

    this.calculateOrderTotalAmount();

    this.updateSecondScreen();
  }

  calculateOrderTotalAmount() {
    const calculatedValues = this.customOrderService.calculateOrderValues([this.order]);
    this.order.subtotal = calculatedValues.subtotal;
    this.order.tax1Amount = calculatedValues.tax1Amount;
    this.order.tax2Amount = calculatedValues.tax2Amount;
    this.order.totalAmount = calculatedValues.totalAmount;
    this.order.roundingAmount = calculatedValues.roundingAmount;
    this.order.netAmount = calculatedValues.netAmount;
    this.order.averageCost = +calculatedValues.averageCost;
    this.order.discountItemAmount = +calculatedValues.discountItemAmount;
    this.orderQuantity = +calculatedValues.quantity;
  }

  clearCart() {
    this.order.orderItems = [];
    this.calculateOrderTotalAmount();
    this.updateSecondScreen();
  }

  hold() {
    this.loading.holding = true;
    this.orderService.upsertOrderPost({order: this.order}).subscribe((res) => {
      this.ns.showNotification({message: 'Order has been hold', icon: 'fa fa-info'});
      this.navigateToHome();
      this.loading.holding = false;
    });
  }

  submitOrder() {
    this.loading.submitting = true;
    let dialogRef = this.ns.showNotification({
      message: this.order?.id > 0 ? 'Updating order...' : 'Submitting order...',
      icon: 'fa fa-info',
      manualClose: true,
    });

    this.orderService.upsertOrderPost({order: this.order}).subscribe((res) => {
      dialogRef.close();

      if (this.order.id > 0) {
        this.navigateToHome();
      } else {
        this.loading.submitting = false;
        const updatedOrder = {
          ...res,
          kitchenOrderStatus: KitchenOrderStatusEnum.Confirmed,
          orderStatus: OrderStatusEnum.Unpaid,
        };

        this.upsertOrderAndNavigate(updatedOrder);
      }

      if (this.merchant.natureOfBusiness === 'FNB') {
        this.message = 'Sending order to kitchen';
      } else {
        this.message = 'Processing';
      }

      let dialogRef2 = this.ns.showNotification({
        message: this.message,
        manualClose: true,
        icon: 'fa fa-sync',
      });

      setTimeout(() => {
        dialogRef2.close();
      }, 2000);
    });
  }

  private upsertOrderAndNavigate(order: any) {
    this.orderService.upsertOrderPost({order}).subscribe(() => {
      this.navigateToHome();
    });
  }

  private navigateToHome() {
    this.router.navigate(['/'], {
      queryParams: {
        tableId: this.table.id,
      },
    });
  }

  private initOrder() {
    this.order = {
      id: 0,
      orderItems: [],
      orderType: this.table.orderType,
      orderStatus: OrderStatusEnum.New,
      merchant: {id: this.merchant.id},
      tableCustomer: {
        id: this.table.id,
      },
    } as ExtendedOrder;

    this.calculateOrderTotalAmount();
  }

  addQty(item: OrderItem) {
    let index = this.order.orderItems.findIndex((x) => x['generatedId'] === item['generatedId']);

    if (index > -1) {
      this.order.orderItems[index].quantity += 1;
    }

    this.calculateOrderTotalAmount();
    this.updateSecondScreen();
  }

  minusQty(item: OrderItem) {
    let index = this.order.orderItems.findIndex((x) => x['generatedId'] === item['generatedId']);
    if (index > -1) {
      if (this.order.orderItems[index].quantity > 1) {
        this.order.orderItems[index].quantity -= 1;
      }
    }

    this.calculateOrderTotalAmount();
    this.updateSecondScreen();
  }

  refreshMenu() {
    this.customPosItemService.cachePosItemVsMerchant$ = null;
    this.filteredPosItems = [];
    this.posCategories = [];
    this.load();
  }

  editDiscount(item: OrderItem) {
    this.ds
      .open(PosDiscountDialogComponent, {
        minWidth: '600px',
        data: {
          orderItem: item,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          item.discountAmount = res?.cashDiscount || 0;
          item.discountPercentage = res?.discountPercentage || 0;
          this.calculateOrderTotalAmount();
          this.updateSecondScreen();
        }
      });
  }

  editPrice(item: OrderItem) {
    let price = item?.posItemVsMerchant?.price;
    let variancePrice: number = 0;
    if (item.varianceModel && item.varianceModel.length > 0) {
      variancePrice = item.varianceModel
        .flatMap((x) => x.options)
        .reduce((previousValue, currentValue) => previousValue + currentValue.price, 0);
    }
    let totalPrice = price + variancePrice;

    this.ds
      .open(CalculatorComponent, {
        data: {
          title: `Original price: ${totalPrice?.toFixed(2)} per unit. <br> Enter new price : <br>`,
        },
      })
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          if (val !== '0.00') {
            item['overridePrice'] = true;
            item.price = val;
            this.calculateOrderTotalAmount();
            this.updateSecondScreen();
          } else if (val == '0.00') {
            this.ds.openAlert({
              message: 'Price cannot be 0',
            });
          }
        }
      });
  }

  private updateSecondScreen() {
    secondScreen({
      merchantName: this.merchant.companyName,
      merchantLogoUrl: this.merchant['merchantLogoSmall'],
      orderItems: this.order.orderItems.filter((oi) => !oi.deleted),
      payment: undefined,
      discountAmount: this.order.discountAmount,
      discountItemAmount: this.order.discountItemAmount,
      netAmount: this.order.netAmount,
      quantity: this.orderQuantity,
      roundingAmount: this.order.roundingAmount,
      subtotal: this.order.subtotal,
      tax1Amount: this.order.tax1Amount,
      tax2Amount: this.order.tax2Amount,
      totalAmount: this.order.totalAmount,
    });
  }

  searchCustomer() {
    this.ds
      .open(MembershipSearchDialogComponent, {
        width: '50%',
        data: {
          customer: this.order.membership || {},
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((customer) => {
        if (customer) {
          this.order.membership = {...customer};
        }
      });
  }

  removeCustomer() {
    this.ds
      .openConfirm({
        title: 'Remove Customer',
        message: 'Remove Customer?',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.order.membership = null;
        }
      });
  }

  scanBarcode(barcode) {
    this.posItemService.getPosItemByBarcode(barcode, this.posItems).subscribe(item => {
      this.addItem(item);
    });
  }
}
