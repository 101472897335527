<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical  pos-mobile-sidebar-toggled" id="pos">
      <div class="pos-container">

        <app-header></app-header>

        <div class="d-flex">
          <div class="d-flex flex-row flex-wrap " style="height: fit-content;">
            <span class="h5 w-100 mb-3">Select payment method</span>
            <button class="btn btn-lg me-3 mb-2 col-5 bg-white h-55px"
                    matRipple
                    [ngClass]="{'btn-outline-warning border-3': selectedPaymentMethod.id === item.id}"
                    *ngFor="let item of paymentMethods"
                    (click)="changePaymentMethod(item)">
              <div class="d-flex flex-row align-items-center justify-content-center px-1">
                <div class="w-50px me-2" *ngIf="item?.logoUrl">
                  <img src="{{item?.logoUrl}}" alt="" class="w-30px">
                </div>
                <span class="text-primary text-left w-135px">{{item.name}}</span>
              </div>
            </button>

            <ng-container *ngIf="isCash()">
              <div class="d-flex flex-row flex-wrap mt-4 w-100" style="height: fit-content;">
                <span class="h5 w-100 mb-3">Quick Pay</span>
                <button class="btn btn-lg me-3 mb-2 col-5 bg-white h-55px"
                        matRipple
                        *ngFor="let item of bankNotes"
                        [ngClass]="{'btn-outline-warning border-3': selectedBankNote === item}"
                        (click)="changeBankNote(item)">
                  <div class="d-flex flex-row align-items-center justify-content-center">
                    <span class="text-primary text-left">{{item.name}}</span>
                  </div>
                </button>
              </div>
            </ng-container>
          </div>

          <!--          payment summary-->
          <div class="d-flex flex-column col-7">
            <span class="h5 w-100 mb-3">Payment Summary</span>
            <div class="bg-white col-12 d-flex flex-column justify-content-start pb-3 pt-3 px-4 rounded">
              <div class="w-300px">
                <h4>Table: {{table?.name}}</h4>
              </div>

              <ng-container *ngIf="!isCash()">
                <div class="w-300px mt-3">
                  <h5
                    class="text-dark-400">{{ isStaffMeal() ? 'Staff No #' : 'Reference No #' }}</h5>
                  <input #referenceNoRef type="text" [(ngModel)]="referenceNo" class="p-2 h3 w-100 rounded">
                  <span class="text-danger" *ngIf="errMsg">{{errMsg}}</span>

                  <!--                <ng-container *ngIf="isStaffMeal()">-->
                  <!--                  <span>{{staffMeal?.dposUser | json  }}</span>-->
                  <!--                </ng-container>-->
                </div>
              </ng-container>

              <ng-container>
                <div class="w-300px mt-3">
                  <h5 class="text-dark-400">
                    <i class="fa fa-percentage me-3"></i>
                    <span>Discount</span>
                  </h5>
                  <div class="d-flex justify-content-between align-items-center"
                       role="button"
                       (click)="displayDiscountDialog()">
                    <h3 class="mb-0 me-3 text-red-600">RM -{{discountAmount | number: '1.2'}} ({{discountPercentage}}%)</h3>
                    <i class="fa fa-calculator fs-20px"></i>
                  </div>
                </div>
              </ng-container>

              <ng-container>
                <div class="w-300px mt-3">
                  <h5 class="text-dark-400">
                    <i class="fas fa-coins me-3"></i>
                    <span>Tips</span>
                  </h5>
                  <div class="d-flex justify-content-between align-items-center"
                       role="button"
                       (click)="displayCalculator('tips')">
                    <h3 class="mb-0 me-3 text-green-600">RM {{tipsAmount | number: '1.2'}}</h3>
                    <i class="fa fa-calculator fs-20px"></i>
                  </div>

                </div>
              </ng-container>

              <div class="w-300px mt-3">
                <h5 class="text-dark-400">
                  <i class="fa fa-cash-register me-3"></i>
                  <span>Total Due</span></h5>
                <h3 class="mb-0">RM {{getToBePaidTotal() | number: '1.2'}}</h3>
              </div>

              <ng-container>
                <div class="w-300px mt-3">
                  <h5 class="text-dark-400">
                    <i class="far fa-credit-card me-3"></i>
                    <span>Cash Received</span>
                  </h5>
                  <div class="d-flex justify-content-between align-items-center"
                       role="button"
                       (click)="displayCalculator('cash')">
                    <h3 #paidAmountRef class="mb-0 me-3 text-green-600">RM {{paidAmount | number: '1.2'}}</h3>
                    <i class="fa fa-calculator fs-20px"></i>
                  </div>
                </div>
              </ng-container>

              <div class="w-300px mt-3">
                <h5 class="text-dark-400">
                  <i class="fas fa-exchange-alt me-3"></i>
                  <span>Change</span></h5>
                <h3 class="text-blue-700 mb-0">
                  RM {{((paidAmount - (getToBePaidTotal())) || 0) | number: '1.2'}}</h3>
              </div>

              <div class="row w-100 justify-content-center mt-3">

                <div class="col-4">
                  <button class="btn btn-lg btn-default fw-semibold mb-0 d-block py-3 w-100" (click)="back()">Cancel
                  </button>
                </div>

                <div class="col-8" role="button">
                  <button
                    class="btn btn-lg btn-primary bg-green-600 text-white border-0 fw-semibold d-flex justify-content-center align-items-center py-3 m-0 w-100"
                    (click)="submit()"
                    [disabled]="isDisabled() || loading.submitting">
                    <i class="fa fa-credit-card ms-2 me-2"></i>
                    Pay
                  </button>
                </div>

              </div>

            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</div>
