/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Kitchen } from './kitchen';
import { OrderItemRes } from './orderItemRes';
import { KitchenOrderItem } from './kitchenOrderItem';


export interface KitchenOrderRes { 
    actualPreparedDuration?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    end?: string;
    estimatedPreparedDuration?: number;
    id?: number;
    kitchen?: Kitchen;
    kitchenOrderItems?: Array<KitchenOrderItem>;
    orderItemIdList?: Array<string>;
    orderItemIds?: string;
    orderItems?: Array<OrderItemRes>;
    orderType?: KitchenOrderRes.OrderTypeEnum;
    start?: string;
    totalQuantity?: number;
    updateTime?: string;
    updatedByName?: string;
}
export namespace KitchenOrderRes {
    export type OrderTypeEnum = 'DINE_IN' | 'TAKE_AWAY';
    export const OrderTypeEnum = {
        DineIn: 'DINE_IN' as OrderTypeEnum,
        TakeAway: 'TAKE_AWAY' as OrderTypeEnum
    };
}


