import {Component} from '@angular/core';
import {AuthControllerService, MerchantControllerService, UserControllerService} from '../../@services/gen';
import {AuthService} from '../../@services/auth.service';
import {Router} from '@angular/router';
import {LocalstorageService} from '../../@services/localstorage.service';
import {NotificationService} from '../../@services/notification.service';
import {UserShiftService} from "../../@services/user-shift.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  username: string;
  password: string;
  loading: boolean;
  errMsg: string;
  showPassword = false;
  currentYear = new Date().getFullYear();

  constructor(
    private authService: AuthControllerService,
    private customAuthService: AuthService,
    private router: Router,
    private userService: UserControllerService,
    private merchantService: MerchantControllerService,
    private ls: LocalstorageService,
    private ns: NotificationService,
    private userShiftService: UserShiftService
  ) {}

  login(): void {
    let dialogRef = this.ns.showNotification({
      message: 'Logging in...',
      manualClose: true,
      icon: 'fa fa-sync',
    });

    this.errMsg = '';

    this.loading = true;
    this.authService
      .loginPost({
        username: this.username,
        password: this.password,
      })
      .subscribe(
        async (res) => {
          this.customAuthService.saveUser(res['user']);
          await this.loadMerchant();

          let userShift = await this.loadUserShift();
          if (!userShift) {
            this.router.navigate(['/shift-opening']);
          } else {

            this.userShiftService.saveUserShift(userShift);
            this.router.navigate(['/']);
          }
          dialogRef.close();
          this.loading = false;
        },
        (res) => {
          dialogRef.close();
          this.loading = false;
          this.errMsg = res?.error?.message ?? 'Invalid username or password';
        }
      );
  }

  async loadUserShift() {
    return await this.userService
      .getUserShiftPost({
        merchantId: this.ls.getMerchant().id,
      })
      .toPromise();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  async loadMerchant() {
    let merchant = await this.merchantService
      .getMerchantPost({
        id: this.customAuthService.getUser().merchants[0].id,
      })
      .toPromise();

    this.ls.saveMerchant(merchant);
  }
}
