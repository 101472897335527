/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PosItemVarianceModel } from './posItemVarianceModel';
import { PosItemVsMerchant } from './posItemVsMerchant';
import { OrderReq } from './orderReq';
import { PosItemCategory } from './posItemCategory';


export interface OrderItemReq { 
    actualPreparedDuration?: number;
    actualServedDuration?: number;
    averageCost?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    discountAmount?: number;
    discountPercentage?: number;
    discountRemarks?: string;
    generatedId?: string;
    id?: number;
    instructions?: string;
    itemCategory?: PosItemCategory;
    kitchenStatus?: OrderItemReq.KitchenStatusEnum;
    name?: string;
    netAmount?: number;
    order?: OrderReq;
    orderStatus?: OrderItemReq.OrderStatusEnum;
    orderType?: OrderItemReq.OrderTypeEnum;
    paidAmount?: number;
    paidQuantity?: number;
    posItemVsMerchant?: PosItemVsMerchant;
    price?: number;
    printedQuantity?: number;
    quantity?: number;
    roundingAmount?: number;
    skuNo?: string;
    subtotal?: number;
    tax1Amount?: number;
    tax2Amount?: number;
    toBePaidAmount?: number;
    toBePaidQuantity?: number;
    total?: number;
    updateTime?: string;
    updatedByName?: string;
    variance?: string;
    varianceModel?: Array<PosItemVarianceModel>;
    voidAmount?: number;
    voidQuantity?: number;
}
export namespace OrderItemReq {
    export type KitchenStatusEnum = 'CANCELLED' | 'CONFIRMED' | 'NEW' | 'PREPARING' | 'READY' | 'SERVED';
    export const KitchenStatusEnum = {
        Cancelled: 'CANCELLED' as KitchenStatusEnum,
        Confirmed: 'CONFIRMED' as KitchenStatusEnum,
        New: 'NEW' as KitchenStatusEnum,
        Preparing: 'PREPARING' as KitchenStatusEnum,
        Ready: 'READY' as KitchenStatusEnum,
        Served: 'SERVED' as KitchenStatusEnum
    };
    export type OrderStatusEnum = 'CANCELLED' | 'NEW' | 'PAID' | 'PARTIALLY_PAID' | 'PARTIALLY_VOID' | 'PAYMENT_VOIDED' | 'UNFULFILLED' | 'UNPAID';
    export const OrderStatusEnum = {
        Cancelled: 'CANCELLED' as OrderStatusEnum,
        New: 'NEW' as OrderStatusEnum,
        Paid: 'PAID' as OrderStatusEnum,
        PartiallyPaid: 'PARTIALLY_PAID' as OrderStatusEnum,
        PartiallyVoid: 'PARTIALLY_VOID' as OrderStatusEnum,
        PaymentVoided: 'PAYMENT_VOIDED' as OrderStatusEnum,
        Unfulfilled: 'UNFULFILLED' as OrderStatusEnum,
        Unpaid: 'UNPAID' as OrderStatusEnum
    };
    export type OrderTypeEnum = 'DINE_IN' | 'TAKE_AWAY';
    export const OrderTypeEnum = {
        DineIn: 'DINE_IN' as OrderTypeEnum,
        TakeAway: 'TAKE_AWAY' as OrderTypeEnum
    };
}


