import {Component, OnInit} from '@angular/core';
import {Merchant, UserControllerService, UserShiftRes} from '../../../@services/gen';
import {AuthService} from '../../../@services/auth.service';
import {kickCashDrawerz, printz} from "../../../@services/utils";
import {Location} from '@angular/common';
import {LocalstorageService} from '../../../@services/localstorage.service';
import {Router} from '@angular/router';
import {PosItemService} from '../../../@services/pos-item.service';
import {NotificationService} from '../../../@services/notification.service';
import {PaymentService} from '../../../@services/payment.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {KitchenService} from '../../../@services/kitchen.service';
import {UserShiftService} from "../../../@services/user-shift.service";

@Component({
  selector: 'app-shift-closing',
  templateUrl: './shift-closing.component.html',
  styleUrls: ['./shift-closing.component.scss'],
})
export class ShiftClosingComponent implements OnInit {
  title = 'Shift Closing Form';
  merchant: Merchant;
  closingCash: number;
  loading = {
    shiftSummary: false,
  };
  shiftSummary: UserShiftRes;

  constructor(
    private userService: UserControllerService,
    private customPosItemService: PosItemService,
    private customPaymentService: PaymentService,
    private customKitchenService: KitchenService,
    public location: Location,
    private router: Router,
    private ls: LocalstorageService,
    private ns: NotificationService,
    private ds: TdDialogService,
    private userShiftService: UserShiftService

  ) {}

  ngOnInit(): void {
    this.merchant = this.ls.getMerchant();
    this.load();
    kickCashDrawerz();
  }

  private load() {
    this.loadShiftSummary();
  }

  private loadShiftSummary() {
    this.loading.shiftSummary = true;
    this.userService
      .getUserShiftSummaryPost({
        merchantId: this.ls.getMerchant().id,
      })
      .subscribe(
        (value) => {
          this.loading.shiftSummary = false;
          this.shiftSummary = value;
        },
        (error) => {
          this.loading.shiftSummary = false;
        }
      );
  }

  submit() {
    let dialogRef = this.ns.showNotification({
      message: 'Please wait...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.userService
      .startStopUserShiftPost({
        actionType: 'finish',
        userShift: {
          closingCash: this.closingCash,
          merchant: {id: this.merchant.id},
        },
      })
      .subscribe(
        async (userShift) => {
          this.printClosingReport(userShift, () => {});
          dialogRef.close();
          this.customPosItemService.cachePosItemVsMerchant$ = null;

          if (this.customKitchenService.incomingOrderTimer$) {
            this.customKitchenService.incomingOrderTimer$.unsubscribe();
            this.customKitchenService.incomingOrderTimer$ = null;
          }

          if (this.customKitchenService.incomingOrder$) {
            this.customKitchenService.incomingOrder$.unsubscribe();
            this.customKitchenService.incomingOrder$ = null;
          }

          this.userShiftService.clearUserShift();
          this.ls.clear();
          this.router.navigate(['/login']);
        },
        (error) => {
          dialogRef.close();
          this.ds.openAlert({
            message: error?.error?.message,
          });
        }
      );
  }

  private printClosingReport(userShift: UserShiftRes, cb: Function) {
    for (let mvp of this.merchant.merchantVsPrinters) {
      console.log(`mvp`, mvp);
      if (mvp.printClosingReceipt) {
        this.customPaymentService.getClosingReceipt(
          userShift.id,
          mvp.printer.paperSizeType == 'BIG' ? 45 : 32,
          (res) => {
            printz(
              {
                printerName: mvp.printer.printerName,
                printerType: mvp.printer.printerType,
                vendorId: mvp.printer.printerVendorId,
                ipAddress: mvp.ipAddress,
                macAddress: mvp.macAddress,
              },
              [res]
            );
          }
        );
      }
    }
    cb();
  }
}
