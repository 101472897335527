import {Component, OnInit} from '@angular/core';
import * as dayjs from 'dayjs';
import {PaymentControllerService, TableMerchant, UserControllerService} from '../../@services/gen';
import {AuthService} from '../../@services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {NotificationService} from '../../@services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import PaymentStatusEnum = Payment.PaymentStatusEnum;
import {isObjEmpty} from '../../@services/utils';
import {LocalstorageService} from '../../@services/localstorage.service';
import {Payment} from "../../@services/gen/model/payment";
import {UserShiftService} from "../../@services/user-shift.service";

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
})
export class PaymentListComponent implements OnInit {
  startDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  endDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  payments: Payment[] = [];
  filteredPayments: Payment[] = [];
  search: string = '';
  title: string = 'Payment History';
  paidChecked: boolean = true;
  voidChecked: boolean = true;
  totalPaidAmount: number = 0;
  totalNetAmount: number = 0;
  totalChangeAmount: number = 0;
  tableId: number = 0;
  tables: TableMerchant[] = [];
  txStatus: string = 'PAID';
  txStatusEnum: PaymentStatusEnum;
  datePreset: string = 'today';

  constructor(
    private paymentService: PaymentControllerService,
    private customAuthService: AuthService,
    private userService: UserControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private sb: MatSnackBar,
    private ns: NotificationService,
    private ls: LocalstorageService,
    private ds: TdDialogService,
    private userShiftService: UserShiftService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((qp) => {
      if (isObjEmpty(qp)) {

        let userShift = this.userShiftService.getUserShift();

        if(this.userShiftService.getUserShift()){
          this.updateFilter({
            datePreset: 'session',
            startDate: dayjs(userShift.start).toDate(),
            endDate: dayjs().add(1, 'hour').toDate(), // Add 1 hour to the current time
            txStatus: 'PAID',
          });
        }

        return;
      }

      this.startDate = qp['startDate'];
      this.endDate = qp['endDate'];
      this.txStatus = qp['txStatus'];
      this.txStatusEnum = this.convertTxStatusToEnum(this.txStatus);
      this.datePreset = qp['datePreset'];

      this.load();
    });
  }

  load() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading payments...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.paymentService
      .getPagedPaymentPost({
        startDate: dayjs(this.startDate).toString(),
        endDate: dayjs(this.endDate).endOf('day').toString(),
        merchantId: this.ls.getMerchant().id,
        tableId: this.tableId,
        search: this.search,
        paymentStatus: this.txStatusEnum,
        pageRequest: {
          orders: [{direction: 'DESC', property: 'creationTime'}],
        },
      })
      .subscribe((res) => {
        dialogRef.close();

        if (res) {
          this.payments = res.content;
          this.filteredPayments = [...this.payments];
          this.populateTableList();
          this.filterByStatus();
          this.calculateTotal();
        }
      });
  }

  private calculateTotal() {
    this.totalPaidAmount = this.filteredPayments.reduce((a, b) => a + b.paidAmount, 0);
    this.totalNetAmount = this.filteredPayments.reduce((a, b) => a + b.netAmount, 0);
    this.totalChangeAmount = this.filteredPayments.reduce((a, b) => a + b.changeAmount, 0);
  }

  private populateTableList() {
    let tableMerchants = this.filteredPayments.map((p) => p.tableMerchant);
    for (let tm of tableMerchants) {
      if (this.tables.findIndex((x) => x.id === tm.id) == -1) {
        this.tables.push(tm);
      }
    }
    this.tables.sort((a, b) => a.name.localeCompare(b.name));
  }

  voidPayment(payment: Payment) {
    this.ds
      .openConfirm({
        title: 'Void Payment',
        message: 'Are you sure to void this payment? This action cannot be undone',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.paymentService
            .voidPaymentPost({
              payment: payment,
            })
            .subscribe((res) => {
              this.ns.showNotification({message: 'Payment has been voided'});
              this.load();
            });
        }
      });
  }

  filterByStatus() {
    if (this.voidChecked && this.paidChecked) {
      this.filteredPayments = [...this.payments];
    } else if (this.paidChecked && !this.voidChecked) {
      this.filteredPayments = [...this.payments.filter((x) => x.paymentStatus === 'PAID')];
    } else if (!this.paidChecked && this.voidChecked) {
      this.filteredPayments = [...this.payments.filter((x) => x.paymentStatus === 'VOIDED')];
    } else {
      this.filteredPayments = [];
    }
    this.calculateTotal();
  }

  updateFilter(evt: {startDate: Date; endDate: Date; txStatus?: string; datePreset?: string}) {
    this.router.navigate(['/payment-list'], {
      queryParams: {
        datePreset: evt.datePreset ?? this.datePreset,
        startDate: evt.startDate ?? this.startDate,
        endDate: evt.endDate ?? this.endDate,
        txStatus: evt.txStatus ?? this.txStatus,
      },
    });
  }

  changeDate(evt: {startDate: Date; endDate: Date; datePreset: string}) {
    console.log(``, evt);
    this.updateFilter({
      startDate: evt.startDate,
      endDate: evt.endDate,
      datePreset: evt.datePreset,
    });
  }

  changeTxStatus(evt: string) {
    this.txStatusEnum = this.convertTxStatusToEnum(evt);
    this.updateFilter({
      datePreset: this.datePreset,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
      txStatus: evt,
    });
  }

  private convertTxStatusToEnum(evt: string): PaymentStatusEnum {
    switch (evt) {
      case 'ALL':
        return null;
      case 'PAID':
        return PaymentStatusEnum.Paid;
      case 'VOIDED':
        return PaymentStatusEnum.Voided;
      default:
        return null;
    }
  }
}
