import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterUndeleted',
})
export class FilterUndeletedPipe implements PipeTransform {
  transform(items: any[]): any[] {
    if (!items) {
      return [];
    }

    return items.filter((item) => !item.deleted);
  }
}
