<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header" id="pos">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="d-flex  align-items-center flex-column card card-body flex-grow-0">
          <span class="fs-3 fw-bold mb-3 d-block">{{title}}</span>

          <form class="d-flex flex-column gap-2  fw-semibold align-items-center justify-content-center">

            <label for="closingCash">Opening Cash (RM): </label>
            <input id="closingCash" name="closingCash" type="number"
                   class="rounded p-2"
                   autofocus
                   [(ngModel)]="openingCash" *ngIf="role == 'cashier'">

            <p *ngIf="role != 'cashier'" style="color: red">*Please ask cashier to make POS opening!</p>

            <div class="d-flex align-items-center mt-4">
              <button class="btn btn-lg btn-default me-3 fw-semibold mb-0 d-block py-3" type="submit" (click)="cancel()">Cancel</button>
              <button *ngIf="role == 'cashier'" class="btn btn-lg bg-primary text-white fw-semibold mb-0 d-block py-3" type="submit"
                      [disabled]="openingCash === null || openingCash === undefined" (click)="submit()">Submit
              </button>

            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
