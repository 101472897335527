<mat-dialog-content>
  <div class="w-100">
    <div class="fs-4 text-black mb-4 fw-bold">Item Detail</div>

    <form [formGroup]="itemForm">
      <div class="mb-3">
        <label class="form-label">Barcode</label>
        <input type="text" formControlName="barcode" class="form-control">
      </div>
      <div class="mb-3">
        <label class="form-label">Item Name</label>
        <input type="text" formControlName="itemName" class="form-control">
      </div>
      <div class="mb-3">
        <label class="form-label">SKU No</label>
        <input type="text" formControlName="skuNo" class="form-control">
      </div>
      <div class="mb-3">
        <label class="form-label">Price</label>
        <input type="text" formControlName="price" class="form-control">
      </div>

      <div class="mb-3">
        <label class="form-label">Current Stock</label>
        <input type="number" formControlName="currentStock" class="form-control" [disabled]="true">
      </div>
      <div class="mb-3">
        <label class="form-label">On Hand Quantity</label>
        <input type="number" formControlName="onHandQty" class="form-control" (change)="updateQtyAdjusted()" [disabled]="true">
      </div>
      <div class="mb-3">
        <label class="form-label">Quantity</label>
        <input type="number" formControlName="qtyAdjusted" class="form-control" (change)="updateOnHandQty()">
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 p-3">
    <div class="col-4 me-3">
      <button class="btn btn-default border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button" (click)="submit()">
      <button
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center">
        <i class="fa fa-check-circle me-2"></i>
        <span>Add Stock</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
