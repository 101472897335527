import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UserShiftService} from "../../@services/user-shift.service";

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @Input() value: string = 'today';
  @Output() dateChangeEvt: EventEmitter<{
    startDate: Date;
    endDate: Date;
    datePreset: string;
  }> = new EventEmitter<{
    startDate: Date;
    endDate: Date;
    datePreset: string;
  }>();

  constructor(private userShiftService: UserShiftService) {
  }
  ngOnInit(): void {}

  changeDate() {

    if(this.userShiftService.getUserShift()){
      this.value = 'session';
    }

    let startDate, endDate;
    switch (this.value) {
      case 'session':
        let userShift = this.userShiftService.getUserShift();

        startDate = new Date(userShift.start);
        startDate.setDate(startDate.getDate());
        startDate.setHours(startDate.getHours());
        endDate = new Date();
        endDate.setDate(endDate.getDate());
        endDate.setHours(endDate.getHours() + 1);
        break;
      case 'today':
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'this-week':
        startDate = new Date();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'this-month':
        startDate = new Date();
        startDate.setDate(1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        endDate.setHours(23, 59, 59, 999);
        break;
    }

    this.dateChangeEvt.emit({
      startDate,
      endDate,
      datePreset: this.value,
    });
  }
}
