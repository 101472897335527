import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  Inventory,
  InventoryControllerService,
  InventoryItem,
  PosItemControllerService,
  PosItemVsMerchant, PosItemVsMerchantControllerService
} from "../../../@services/gen";

@Component({
  selector: 'app-payment-discount-dialog',
  templateUrl: './stock-dialog.component.html',
  styleUrls: ['./stock-dialog.component.scss'],
})
export class StockDialogComponent {
  itemForm: FormGroup;
  item: PosItemVsMerchant;
  inventory: Inventory;
  inventoryItem: InventoryItem;
  qtyOnhand: number;
  qtyAdjusted: number;

  constructor(
    private fb: FormBuilder,
    private posItemVsMerchantControllerService: PosItemVsMerchantControllerService,
    private inventoryControllerService: InventoryControllerService,
    private dialogRef: MatDialogRef<StockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    console.log(data);
    this.item = data?.posItemVsMerchant;
    this.inventory = data?.inventory;

    console.log(data);

    this.itemForm = this.fb.group({
      barcode: [this.item?.barcode, Validators.required],
      itemName: [this.item?.posItem.name, Validators.required],
      skuNo: [this.item?.posItem.skuNo, Validators.required],
      price: [this.item?.price, Validators.required],
      cost: [this.item?.cost, Validators.required],
      currentStock: [this.item?.stock, [Validators.required, Validators.min(0)]],
      qtyAdjusted: [this.qtyAdjusted, [Validators.required, Validators.min(0)]],
      onHandQty: [this.qtyOnhand, [Validators.required, Validators.min(0)]]
    });

    this.getPosItem();
  }

  getPosItem(){
    if(this.item){
      this.posItemVsMerchantControllerService.getPosItemVsMerchantPost({id: this.item?.id}).subscribe((x) => {
        this.item = x;

        this.updateOnHandQty();
        this.updateQtyAdjusted();
      });
    }
  }

  submit() {
    // if (this.itemForm.valid) {
    //   const formData = this.itemForm.value;
    //   // Handle form submission logic here, e.g., make a service call
    //   console.log('Form Data:', formData);
    // } else {
    //   // Handle form invalid case
    //   console.log('Form is invalid');
    // }

    const formData = this.itemForm.value;

    // Check if item with matching posItemVsMerchant exists
    const existingItemIndex = this.inventory.inventoryItems.findIndex(x => x.posItemVsMerchant.id === this.item.id);

    if (existingItemIndex !== -1) {
      // Update existing item
      this.inventory.inventoryItems[existingItemIndex] = {
        ...this.inventory.inventoryItems[existingItemIndex],
        posItemVsMerchant: {id: this.item.id},
        price: formData.price,
        stock: this.item.stock,
        quantityAdjusted: formData.qtyAdjusted,
        quantityOnHand: formData.onHandQty
      };
    } else {
      // Add new item
      this.inventory.inventoryItems.push({
        posItemVsMerchant: {id: this.item.id},
        price: formData.price,
        stock: this.item.stock,
        quantityAdjusted: formData.qtyAdjusted,
        quantityOnHand: formData.onHandQty,
      });
    }

    console.log(this.inventory);
    const command = {
      inventory: this.inventory,
    };

    this.inventoryControllerService.upsertInventoryPost(command).subscribe(
      (res) => {
        this.dialogRef.close({
        });
      },
      (error) => {

      }
    );
  }

  updateOnHandQty() {
    const qtyAdjusted = this.itemForm.get('qtyAdjusted')?.value || 0;
    const stock = this.itemForm.get('currentStock')?.value || 0;
    this.itemForm.get('onHandQty')?.setValue(stock + qtyAdjusted, { emitEvent: false });
  }

  updateQtyAdjusted() {
    const onHandQty = this.itemForm.get('onHandQty')?.value || 0;
    const stock = this.itemForm.get('currentStock')?.value || 0;
    this.itemForm.get('qtyAdjusted')?.setValue(onHandQty - stock, { emitEvent: false });
  }
}
