/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportMenuDto { 
    merchantType?: Array<string>;
    natureOfBusiness?: Array<string>;
    reportCode?: string;
    roles?: Array<string>;
    title?: string;
}

