import {Component, OnInit} from '@angular/core';
import * as dayjs from 'dayjs';
import {Expenses, ExpensesControllerService, UserControllerService} from '../../../@services/gen';
import {AuthService} from '../../../@services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {NotificationService} from '../../../@services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isObjEmpty} from '../../../@services/utils';
import {LocalstorageService} from '../../../@services/localstorage.service';
import {ExpensesDialogComponent} from '../expenses-dialog/expenses-dialog.component';
import {UserShiftService} from "../../../@services/user-shift.service";

@Component({
  selector: 'app-expenses-list',
  templateUrl: './expenses-list.component.html',
  styleUrls: ['./expenses-list.component.scss'],
})
export class ExpensesListComponent implements OnInit {
  startDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  endDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  expenses: Expenses[] = [];
  filteredExpenses: Expenses[] = [];
  search: string = '';
  title: string = 'Cash In / Out History';
  totalAmount: number = 0;
  tableId: number = 0;
  datePreset: string = 'today';

  constructor(
    private expenseservice: ExpensesControllerService,
    private customAuthService: AuthService,
    private userService: UserControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private sb: MatSnackBar,
    private ns: NotificationService,
    private ls: LocalstorageService,
    private ds: TdDialogService,
    private userShiftService: UserShiftService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((qp) => {
      if (isObjEmpty(qp)) {
        let userShift = this.userShiftService.getUserShift();

        if(this.userShiftService.getUserShift()){
          this.updateFilter({
            datePreset: 'session',
            startDate: dayjs(userShift.start).toDate(),
            endDate: dayjs().add(1, 'hour').toDate(), // Add 1 hour to the current time
          });
        }

        return;
      }

      this.startDate = qp['startDate'];
      this.endDate = qp['endDate'];
      this.datePreset = qp['datePreset'];

      this.load();
    });
  }

  load() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading expenses...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.expenseservice
      .getPagedExpensesPost({
        merchantId: this.ls.getMerchant().id,
        date: {
          start: dayjs(this.startDate).toString(),
          end: dayjs(this.endDate).endOf('day').toString(),
        },
        expensesType: null,
        pageRequest: {
          page: 0,
          size: 999
        },
        search: ""
      })
      .subscribe((res) => {
        dialogRef.close();

        if (res) {
          this.expenses = res.content;
          this.expenses.map((value) => {
            switch (value.expensesType) {
              case 'CASH_OUT':
                value.amount = value.amount * -1;
                break;
            }
          });
          this.filteredExpenses = [...this.expenses];
          this.calculateTotal();
        }
      });
  }

  private calculateTotal() {
    this.totalAmount = this.filteredExpenses.reduce((a, b) => a + b.amount, 0);
  }

  updateFilter(evt: {startDate: Date; endDate: Date; datePreset?: string}) {
    this.router.navigate(['/expenses-list'], {
      queryParams: {
        datePreset: evt.datePreset ?? this.datePreset,
        startDate: evt.startDate ?? this.startDate,
        endDate: evt.endDate ?? this.endDate,
      },
    });
  }

  changeDate(evt: {startDate: Date; endDate: Date; datePreset: string}) {
    console.log(``, evt);
    this.updateFilter({
      startDate: evt.startDate,
      endDate: evt.endDate,
      datePreset: evt.datePreset,
    });
  }

  voidExpenses(expenses: Expenses) {
    let copy = {...expenses, deleted: true};

    this.ds
      .openConfirm({
        title: 'Void Expenses',
        message: 'Are you sure to void this expenses? This action cannot be undone',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.expenseservice
            .upsertExpensesPost({
              expenses: copy,
            })
            .subscribe((res) => {
              this.ns.showNotification({message: 'Expenses has been voided'});
              this.load();
            });
        }
      });
  }

  addExpenses() {
    this.ds
      .open(ExpensesDialogComponent, {
        minWidth: '50%',
      })
      .afterClosed()
      .subscribe((res) => {
        this.load();
      });
  }
}
