/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomDateDto } from './customDateDto';
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedExpensesQuery { 
    date?: CustomDateDto;
    expensesType?: GetPagedExpensesQuery.ExpensesTypeEnum;
    merchantId?: number;
    pageRequest?: CustomPageRequest;
    search?: string;
}
export namespace GetPagedExpensesQuery {
    export type ExpensesTypeEnum = 'CASH_IN' | 'CASH_OUT';
    export const ExpensesTypeEnum = {
        In: 'CASH_IN' as ExpensesTypeEnum,
        Out: 'CASH_OUT' as ExpensesTypeEnum
    };
}


