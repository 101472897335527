import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CheckoutComponent} from './@pages/checkout/checkout.component';
import {PosComponent} from './@pages/pos/pos.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PosItemDialogComponent} from './@pages/pos/pos-product-dialog/pos-item-dialog.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CovalentDialogsModule} from '@covalent/core/dialogs';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BASE_PATH, UserControllerService} from './@services/gen';
import {environment} from '../environments/environment';
import {PaymentComponent} from './@pages/payment/payment.component';
import {LoginComponent} from './@pages/login/login.component';
import {AuthService} from './@services/auth.service';
import {PaymentResultComponent} from './@pages/payment-result/payment-result.component';
import {PaymentListComponent} from './@pages/payment-list/payment-list.component';
import {HeaderComponent} from './@components/header/header.component';
import {JwtInterceptor} from './@services/jwt.interceptor';
import {ShiftOpeningComponent} from './@pages/shift/shift-opening/shift-opening.component';
import {ShiftClosingComponent} from './@pages/shift/shift-closing/shift-closing.component';
import {AutofocusDirective} from './@directives/autofocus.directive';
import {UserProfileComponent} from './@pages/user-profile/user-profile.component';
import {CalculatorComponent} from './@components/calculator/calculator.component';
import {PosDiscountDialogComponent} from './@pages/pos/pos-discount-dialog/pos-discount-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {PaymentDetailComponent} from './@pages/payment-detail/payment-detail.component';
import {MatMenuModule} from '@angular/material/menu';
import {NotificationModalComponent} from './@components/notification-modal/notification-modal.component';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {DateSelectorComponent} from './@components/date-selector/date-selector.component';
import {FilterUndeletedPipe} from './@pipes/filter-undeleted.pipe';
import {MatButtonModule} from '@angular/material/button';
import {ExpensesListComponent} from './@pages/expenses/expenses-list/expenses-list.component';
import {ExpensesDialogComponent} from './@pages/expenses/expenses-dialog/expenses-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {PaymentDiscountDialogComponent} from './@pages/payment-discount-dialog/payment-discount-dialog.component';
import {AuthInterceptor} from './@services/auth.interceptor';
import {UserShiftService} from './@services/user-shift.service';
import {MembershipEditorDialogComponent} from './@pages/membership/membership-editor-dialog/membership-editor-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MembershipSearchDialogComponent} from './@pages/membership/membership-search-dialog/membership-search-dialog.component';
import {TableListComponent} from './@pages/table-list/table-list.component';
import {PosSplitBillDialogComponent} from "./@pages/pos/pos-split-bill-dialog/pos-split-bill-dialog.component";
import {RemoveUnderscoresPipe} from "./@pipes/remove-underscores.pipe";
import { StockComponent } from './@pages/stock/stock.component';
import { StockDetailComponent } from './@pages/stock/stock-detail/stock-detail.component';
import {StockDialogComponent} from "./@pages/stock/stock-dialog/stock-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    PosComponent,
    PosItemDialogComponent,
    PaymentComponent,
    PaymentResultComponent,
    LoginComponent,
    PaymentListComponent,
    PaymentDetailComponent,
    HeaderComponent,
    ShiftOpeningComponent,
    ShiftClosingComponent,
    ExpensesListComponent,
    ExpensesDialogComponent,
    MembershipEditorDialogComponent,
    MembershipSearchDialogComponent,

    UserProfileComponent,
    CalculatorComponent,
    PosDiscountDialogComponent,
    PaymentDiscountDialogComponent,
    NotificationModalComponent,
    DateSelectorComponent,

    AutofocusDirective,

    FilterUndeletedPipe,
    TableListComponent,
    StockComponent,
    StockDetailComponent,
    StockDialogComponent,
    PosSplitBillDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    CovalentDialogsModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RemoveUnderscoresPipe,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthService,
    UserShiftService,
    UserControllerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiUrl,
    },
  ],
  bootstrap: [AppComponent],
  exports: [HeaderComponent],
})
export class AppModule {}
