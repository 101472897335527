import { Component, OnInit } from "@angular/core";
import * as dayjs from "dayjs";
import { ActivatedRoute, Router } from "@angular/router";
import { isObjEmpty } from "../../@services/utils";
import { NotificationService } from "../../@services/notification.service";
import { LocalstorageService } from "../../@services/localstorage.service";
import { TdDialogService } from "@covalent/core/dialogs";
import { UserShiftService } from "../../@services/user-shift.service";
import { Inventory, InventoryControllerService, InventoryItem, Merchant } from "../../@services/gen";

import TypeEnum = Inventory.TypeEnum;

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {
  startDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  endDate: string = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
  title: string = 'Stock adjustment';
  datePreset: string = 'today';

  inventories: Inventory[] = [];
  filteredInventory: Inventory[] = [];
  inventory: Inventory;
  merchantData: Merchant;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ns: NotificationService,
    private ls: LocalstorageService,
    private ds: TdDialogService,
    private userShiftService: UserShiftService,
    private inventoryControllerService: InventoryControllerService
  ) {}

  ngOnInit() {
    this.merchantData = this.ls.getMerchant();

    this.route.queryParams.subscribe((qp) => {
      if (isObjEmpty(qp)) {
        const userShift = this.userShiftService.getUserShift();
        if (userShift) {
          this.updateFilter({
            datePreset: 'session',
            startDate: dayjs(userShift.start).toDate(),
            endDate: dayjs().add(1, 'hour').toDate(),
          });
        }
        return;
      }

      this.startDate = qp['startDate'];
      this.endDate = qp['endDate'];
      this.datePreset = qp['datePreset'];

      this.load();
    });
  }

  updateFilter(evt: { startDate: Date; endDate: Date; txStatus?: string; datePreset?: string }) {
    this.router.navigate(['/stock'], {
      queryParams: {
        datePreset: evt.datePreset ?? this.datePreset,
        startDate: evt.startDate ?? this.startDate,
        endDate: evt.endDate ?? this.endDate,
      },
    });
  }

  changeDate(evt: { startDate: Date; endDate: Date; datePreset: string }) {
    console.log(evt);
    this.updateFilter({
      startDate: evt.startDate,
      endDate: evt.endDate,
      datePreset: evt.datePreset,
    });
  }

  load() {
    const dialogRef = this.ns.showNotification({
      message: 'Loading payments...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.inventoryControllerService
      .getPagedInventoryPost({
        date: {
          start: dayjs(this.startDate).toString(),
          end: dayjs(this.endDate).endOf('day').toString(),
          dateOptionSelect: "custom"
        },
        merchantId: this.ls.getMerchant().id,
        reason: null,
        search: "",
        pageRequest: {
          orders: [{ direction: 'DESC', property: 'creationTime' }],
        },
      })
      .subscribe((res) => {
        dialogRef.close();
        if (res) {
          this.inventories = res.content;
          this.filteredInventory = this.inventories.filter(item => item.type === TypeEnum.StockIn || item.type === TypeEnum.StockOut);
        }
      }, error => {
        dialogRef.close();
        this.ns.showNotification({ message: 'Failed to load payments', icon: 'fa fa-times' });
      });
  }

  addInventory() {
    this.inventory = {
      adjustmentDate: new Date().toISOString(),
      id: null,
      inventoryItems: [],
      itemType: Inventory.ItemTypeEnum.Pos,
      merchant: { id: this.merchantData.id } as Merchant,
      remark: '',
      sourceNo: '',
      type: Inventory.TypeEnum.StockIn
    } as Inventory;

    const command = { inventory: this.inventory };

    this.inventoryControllerService.upsertInventoryPost(command).subscribe(
      (res) => {
        this.ns.showNotification({ message: 'Inventory added successfully', icon: 'fa fa-check' });
        this.router.navigate(['/stock-list'], {
          queryParams: {
            id: res.id,
          }
        });

        this.load(); // Reload the inventory list
      },
      (error) => {
        this.ns.showNotification({ message: 'Failed to add inventory', icon: 'fa fa-times' });
      }
    );
  }
}
