import {Component} from "@angular/core";
import {Expenses, ExpensesControllerService} from "../../../@services/gen";
import {MatDialogRef} from "@angular/material/dialog";
import {LocalstorageService} from "../../../@services/localstorage.service";
import {kickCashDrawerz} from "../../../@services/utils";

@Component({
  selector: "app-expenses-dialog",
  templateUrl: "./expenses-dialog.component.html",
  styleUrls: ["./expenses-dialog.component.scss"]
})
export class ExpensesDialogComponent {

  expenses: Expenses = {
    expensesType: "CASH_IN"
  };
  title: string = "Record Expenses";

  constructor(private expensesService: ExpensesControllerService,
              private dialogRef: MatDialogRef<ExpensesDialogComponent>,
              private ls: LocalstorageService) {
    this.expenses.merchant = ls.getMerchant();
  }

  saveExpenses() {
    this.expensesService.upsertExpensesPost({
      expenses: this.expenses
    }).subscribe(res => {
      this.dialogRef.close(res);
      this.kickCashDrawer();
    });
  }

  kickCashDrawer() {
    kickCashDrawerz();
  }
}
