import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  GetPagedOrderQuery,
  Merchant,
  OrderControllerService,
  OrderReq,
  TableMerchant,
  TableMerchantControllerService,
  TaxConfig,
  UserShiftRes,
} from '../../@services/gen';
import {ActivatedRoute, Router} from '@angular/router';
import * as dayjs from 'dayjs';
import {TdDialogService} from '@covalent/core/dialogs';
import {printz, secondScreen} from '../../@services/utils';
import {AuthService} from '../../@services/auth.service';
import {UserModel} from '../../@models/user.model';
import {PaymentService} from '../../@services/payment.service';
import {forkJoin, Subject, Subscription, switchMap, take, tap} from "rxjs";
import {LocalstorageService} from '../../@services/localstorage.service';
import {TaxService} from '../../@services/tax.service';
import {OrderService} from '../../@services/order.service';
import {NotificationService} from '../../@services/notification.service';
import {Order} from 'src/app/@services/gen/model/order';
import {KitchenService} from '../../@services/kitchen.service';
import {UserShiftService} from '../../@services/user-shift.service';
import {TableListComponent} from '../table-list/table-list.component';
import OrderStatusEnum = Order.OrderStatusEnum;
import KitchenOrderStatusEnum = Order.KitchenOrderStatusEnum;
import {
  ExtendedOrder,
  ExtendedOrderItems,
  PosSplitBillDialogComponent
} from "../pos/pos-split-bill-dialog/pos-split-bill-dialog.component";
import {BarcodeScannerService} from "../../@services/barcode-scanner.service";
import {PosItemService} from "../../@services/pos-item.service";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  tables: TableMerchant[] = [];
  merchant: Merchant = {};
  unpaidOrders: ExtendedOrder[] = [];
  newOrders: ExtendedOrder[] = [];
  subtotal: number = 0;
  tax1Amount: number = 0;
  tax2Amount: number = 0;
  totalAmount: number = 0;
  roundingAmount: number = 0;
  discountItemAmount: number = 0;
  netAmount: number = 0;
  orderQuantity: number = 0;
  toBePaidTotal: number = 0;
  toBePaidQty: number = 0;
  selectedTable: TableMerchant;
  user: UserModel;
  taxRates: {tax1Config: TaxConfig; tax2Config: TaxConfig};
  loadUnpaidOrders$: Subject<OrderReq[]> = new Subject<OrderReq[]>();
  selectedTable$: Subject<number> = new Subject<number>();
  loading = {
    unpaidOrder: false,
  };
  userRole;
  private intervalId: number | null = null;
  private userShift: UserShiftRes;

  constructor(
    private tableService: TableMerchantControllerService,
    private orderService: OrderControllerService,
    private customerAuthService: AuthService,
    private customPaymentService: PaymentService,
    private customKitchenService: KitchenService,
    private ds: TdDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private ns: NotificationService,
    private ls: LocalstorageService,
    private customTaxService: TaxService,
    private customOrderService: OrderService,
    private customUserShiftService: UserShiftService,
    private usershiftservice: UserShiftService,
  ) {}

  ngOnInit(): void {
    console.log(`init checkout`);
    this.user = this.customerAuthService.getUser();
    this.userRole = this.user.role;

    this.merchant = this.ls.getMerchant();
    this.userShift = this.customUserShiftService.getUserShift();
    this.taxRates = this.customTaxService.getTaxRates();

    this.resetSecondScreen();
    this.initListener();

    this.route.queryParams.subscribe((qp) => {
      let tableId = qp['tableId'];
      if (tableId) {
        this.selectTable(+tableId);
      }
    });

    this.load();

    this.intervalId = window.setInterval(() => {
      this.loadTablesWithoutRefresh();
    }, 10000); // 10000 milliseconds = 10 seconds
  }

  private initListener() {
    this.customKitchenService.startTimerIncomingKitchenOrder();
    this.customPaymentService.paymentComplete$.subscribe((isComplete) => {
      if (isComplete) {
        this.ns.showNotification({message: 'Order has been paid'});
        this.loadTables();
        this.unpaidOrders = [];
        this.calculateOrderTotalAmount();
      }
    });

    this.selectedTable$
      .pipe(
        tap((val) => {
          this.selectedTable = {id: val};
          this.unpaidOrders = [];
          this.newOrders = [];
          this.calculateOrderTotalAmount();
        }),
        switchMap((val) => {
          return this.tableService.getTableMerchantPost({
            id: val,
          });
        })
      )
      .subscribe((table) => {
        this.selectedTable = table;
        let selectedTableIndex = this.tables.findIndex((x) => x.id === table.id);
        if (selectedTableIndex > -1) {
          this.tables[selectedTableIndex] = table;
        }

        if (table.totalOrder > 0) {
          this.loadUnpaidOrders(table);
        } else {
          this.resetSecondScreen();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private load() {
    this.loadTables();
    this.usershiftservice.checkShiftValid();
  }

  private loadTables() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading tables...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.tableService
      .getPagedTableMerchantPost({
        merchantId: this.merchant.id,
        search: '',
        pageRequest: {
          page: 0,
          size: 999,
        },
      })
      .subscribe((res) => {
        dialogRef.close();

        this.tables = res.content;
      });
  }

  private loadTablesWithoutRefresh() {
    this.tableService
      .getPagedTableMerchantPost({
        merchantId: this.merchant.id,
        search: '',
        pageRequest: {
          page: 0,
          size: 999,
        },
      })
      .subscribe((res) => {
        this.tables = res.content;

        //check shift valid
        this.usershiftservice.checkShiftValid();
      });
  }

  selectTable(tableId: number) {
    this.selectedTable$.next(tableId);
    this.usershiftservice.checkShiftValid();
  }

  private loadUnpaidOrders(table: TableMerchant) {
    let shiftStartDate = this.customUserShiftService.getUserShift().start;
    this.loading.unpaidOrder = true;
    this.orderService
      .getPagedOrderPost({
        merchantId: this.merchant.id,
        tableId: table.id,
        orderStatusEnum: [GetPagedOrderQuery.OrderStatusEnumEnum.New, GetPagedOrderQuery.OrderStatusEnumEnum.Unpaid, GetPagedOrderQuery.OrderStatusEnumEnum.PartiallyPaid],
        orderItemStatusEnum: [GetPagedOrderQuery.OrderStatusEnumEnum.Unpaid, GetPagedOrderQuery.OrderStatusEnumEnum.PartiallyPaid],
        startDate: shiftStartDate,
        endDate: dayjs(new Date()).endOf('day').toString(),
        pageRequest: {
          page: 0,
          size: 9999,
          orders: [{direction: 'DESC', property: 'creationTime'}],
        },
      })
      .subscribe((res) => {
        this.loading.unpaidOrder = false;
        this.unpaidOrders = (res.content as OrderReq[]).map(order => ({
          ...order,
          discountAmount: 0,
          orderItems: order.orderItems.map(item => ({
            ...item,
            tempQty: item.quantity - item.paidQuantity,
            paidQuantity: item.quantity,
            toBePaidQuantity: item.quantity - item.paidQuantity
          })),
        })) as ExtendedOrder[];

        this.newOrders = this.unpaidOrders.filter((x) => x.orderStatus === OrderStatusEnum.New);
        this.calculateOrderTotalAmount();

        this.loadUnpaidOrders$.next(this.unpaidOrders);

        secondScreen({
          merchantName: this.merchant.companyName,
          merchantLogoUrl: this.merchant['merchantLogoSmall'],
          orderItems: this.unpaidOrders.flatMap((o) => o.orderItems),
          payment: undefined,
          discountAmount: 0,
          discountItemAmount: this.discountItemAmount,
          netAmount: this.netAmount,
          quantity: this.orderQuantity,
          roundingAmount: this.roundingAmount,
          subtotal: this.subtotal,
          tax1Amount: this.tax1Amount,
          tax2Amount: this.tax2Amount,
          totalAmount: this.totalAmount,
        });
      });


  }

  calculateOrderTotalAmount() {
    const calculatedValues = this.customOrderService.calculateOrderValues(this.unpaidOrders);

    this.subtotal = calculatedValues.paymentSubtotalOrder;
    this.tax1Amount = calculatedValues.tax1Amount;
    this.tax2Amount = calculatedValues.tax2Amount;
    this.totalAmount = calculatedValues.totalAmount;
    this.roundingAmount = calculatedValues.roundingAmount;
    this.discountItemAmount = calculatedValues.paymentDiscountItemOrder;
    this.netAmount = calculatedValues.netAmount;
    this.orderQuantity = calculatedValues.quantity;
    this.toBePaidTotal = calculatedValues.paymentTotalOrder;
    this.toBePaidQty = calculatedValues.paymentQtyOrder;
  }

  pay() {
    this.selectTable(this.selectedTable.id);

    this.loadUnpaidOrders$
      .pipe(
        take(1),
        tap((vals) => console.log('Unpaid orders loaded. Size: ', vals.length))
      )
      .subscribe((newOrders) => {
        if (this.newOrders.length > 0) {
          this.ds.openAlert({
            title: 'Warning',
            message: `You have ${this.newOrders.length} unconfirmed orders. Please confirm or cancel the order before proceed to payment`,
          });
          return;
        }

        this.customPaymentService.unpaidOrdersSub$.next(this.unpaidOrders);
        this.router.navigate(['/payment']);
      });
  }

  printBill() {
    for (let mvp of this.merchant.merchantVsPrinters) {
      if (mvp.printReceipt) {
        this.customPaymentService.getPaymentBill(
          this.selectedTable.id,
          mvp.printer.paperSizeType == 'BIG' ? 45 : 32,
          (res) => {
            printz(
              {
                printerName: mvp.printer.printerName,
                printerType: mvp.printer.printerType,
                vendorId: mvp.printer.printerVendorId,
                ipAddress: mvp.ipAddress,
                macAddress: mvp.macAddress,
              },
              [res]
            );
          }
        );
      }
    }
  }

  addOrder(table: TableMerchant) {
    this.router.navigate(['/pos'], {
      queryParams: {
        tableId: table.id,
      },
    });
  }

  editOrder(order: Order) {
    this.router.navigate(['/pos'], {
      queryParams: {
        tableId: this.selectedTable.id,
        orderId: order.id,
      },
    });
  }


  confirmOrder() {
    let obs = this.newOrders.map((order) => {
      let updatedOrderItems = order.orderItems.map(item => ({
        ...item,
        status: OrderStatusEnum.Unpaid,
        paidAmount: 0,
        paidQuantity: 0
      }));

      let copyOrder = {
        ...order,
        orderItems: updatedOrderItems,
        orderStatus: OrderStatusEnum.Unpaid,
        kitchenOrderStatus: KitchenOrderStatusEnum.Confirmed,
      };

      console.log(copyOrder);
      return this.orderService.upsertOrderPost({order: copyOrder});
    });
    forkJoin(obs).subscribe((value) => {
      this.ns.showNotification({message: 'Order has been sent to the kitchen!'});
      this.selectTable(this.selectedTable.id);
    });
  }

  cancelOrder(order: Order) {
    this.ds
      .openConfirm({
        title: 'Cancel Order',
        message: 'Are you sure to cancel this order? This action cannot be undone',
        acceptButton: 'Yes',
        cancelButton: 'No',
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          let notiRef = this.ns.showNotification({message: 'Cancelling order...'});
          this.orderService
            .getOrderPost({
              id: order.id,
            })
            .subscribe((refreshedOrder) => {
              let payload = {...refreshedOrder, orderStatus: 'CANCELLED'} as Order;
              this.orderService
                .upsertOrderPost({
                  order: payload,
                })
                .subscribe(
                  (x) => {
                    notiRef.close();
                    this.ns.showNotification({message: 'Order has been cancelled'});
                    this.selectTable(this.selectedTable.id);
                  },
                  (error) => {
                    notiRef.close();
                    this.ns.showNotification({message: 'Error while cancelling order.'});
                  }
                );
            });
        }
      });
  }

  private resetSecondScreen() {
    secondScreen({
      merchantName: this.merchant.companyName,
      merchantLogoUrl: this.merchant['merchantLogoSmall'],
      orderItems: [],
      payment: undefined,
      discountAmount: 0,
      discountItemAmount: 0,
      netAmount: 0,
      quantity: 0,
      roundingAmount: 0,
      subtotal: 0,
      tax1Amount: 0,
      tax2Amount: 0,
      totalAmount: 0,
    });
  }

  transferTable(pastOrder: OrderReq) {
    this.ds
      .open(TableListComponent, {
        width: '600px',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: TableMerchant) => {
        if (res) {
          this.orderService
            .transferTablePost({
              tableId: res.id,
              orderIds: [pastOrder.id],
            })
            .pipe(take(1))
            .subscribe((value) => {
              this.ns.showNotification({message: 'Order has been successfully transferred'});
              this.loadTablesWithoutRefresh();
            });
        }
      });
  }

  splitBill(table: TableMerchant) {
    this.selectTable(this.selectedTable.id);

    this.ds
      .open(PosSplitBillDialogComponent, {
        width: '600px',
        data: {
          tableMerchant: table,
          merchantId: this.merchant.id
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res && res.selectedPaidOrder) {
          this.unpaidOrders = (res.selectedPaidOrder as ExtendedOrder[]);
          this.customPaymentService.unpaidOrdersSub$.next(this.unpaidOrders);
          this.router.navigate(['/payment']);
        }
      });
  }

  getItemSubTotal(item: ExtendedOrderItems){
    let totalPerItem = item.subtotal / item?.quantity;
    let subTotal = totalPerItem * item?.toBePaidQuantity;
    return subTotal;
  }

  getItemSubTotalWithDiscount(item: ExtendedOrderItems){
    let totalPerItem = item.subtotal / item?.quantity;
    let subTotal = totalPerItem * item?.toBePaidQuantity;
    let subTotalAfterDiscount = subTotal - (subTotal * (item.discountPercentage / 100));
    return subTotalAfterDiscount;
  }
}

