/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InventoryItem } from './inventoryItem';
import { Merchant } from './merchant';


export interface Inventory { 
    adjustmentDate?: string;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    id?: number;
    inventoryItems?: Array<InventoryItem>;
    inventoryNo?: string;
    itemType?: Inventory.ItemTypeEnum;
    merchant?: Merchant;
    remark?: string;
    sourceNo?: string;
    type?: Inventory.TypeEnum;
    updateTime?: string;
    updatedByName?: string;
}
export namespace Inventory {
    export type ItemTypeEnum = 'POS' | 'RAW_MATERIAL';
    export const ItemTypeEnum = {
        Pos: 'POS' as ItemTypeEnum,
        RawMaterial: 'RAW_MATERIAL' as ItemTypeEnum
    };
    export type TypeEnum = 'INITIAL_STOCK' | 'PAYMENT_ORDER' | 'STOCK_IN' | 'STOCK_OUT';
    export const TypeEnum = {
        InitialStock: 'INITIAL_STOCK' as TypeEnum,
        PaymentOrder: 'PAYMENT_ORDER' as TypeEnum,
        StockIn: 'STOCK_IN' as TypeEnum,
        StockOut: 'STOCK_OUT' as TypeEnum
    };
}


