import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent {
  icon: string;
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.icon = data.icon;
    this.message = data.message;
  }
}
