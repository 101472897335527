<mat-dialog-content>
  <div class="w-100">

    <div class="fs-5 mb-4 fw-bold">Select Item to Pay</div>

    <div class="d-flex flex-column">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Items</th>
          <th scope="col">Qty</th>
          <th scope="col">Price</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let order of unpaidOrders; let orderIndex = index">
          <tr style="background-color: #FFD909">
            <th scope="row">
            </th>
            <td><b>#{{order.orderNo}}</b></td>
            <td>{{order.creationTime | date : "dd/MM HH:mm"}}</td>
            <td>
              <div
                class="fw-semibold"
                [class.text-danger]="order?.orderStatus === 'NEW' || order?.orderStatus === 'UNPAID' || order?.orderStatus === 'CANCELLED' || order?.orderStatus === 'PARTIALLY_PAID'"
                [class.text-success]="order?.orderStatus === 'PAID'">
                {{ order?.orderStatus === 'NEW' ? 'To Confirm' : order?.orderStatus | removeUnderscores | titlecase }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of order.orderItems; let itemIndex = index" (click)="toggleItemSelection(orderIndex, itemIndex)">
            <td>
              <input type="checkbox" aria-label="Checkbox for following text input" [checked]="isItemSelected(orderIndex, itemIndex)">
            </td>
            <td>{{item.name}}</td>
            <td>x{{item.tempQty}}</td>
            <td>RM {{item.netAmount}}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 p-3">
    <div class="col-4 me-3">
      <button class="btn btn-default border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button">
      <button (click)="submit()" [disabled]="checkIfSelectItem()"
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center">
        <i class="fa fa-credit-card fa-lg my-10px d-block me-2"></i>
        <span>Pay</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
