<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header pos-with-sidebar pos-mobile-sidebar-toggled" id="pos">
      <div class="pos-container">
        <app-header></app-header>

        <div class="d-flex justify-content-between align-items-center px-2 mt-2">
          <input
            #searchRef
            type="text"
            name=""
            id=""
            placeholder="Search menu.."
            class="w-100 p-2 px-4 rounded me-3"
            style="border: 1px solid var(--bs-border-color)"
            (input)="searchMenu($event.target.value)" />
          <button class="btn" (click)="searchRef.value = ''; searchMenu('')">
            <i class="fa fa-times-circle"></i>
          </button>

        </div>
        <div class="d-flex justify-content-between align-items-start py-2 px-2">
          <div class="h-100 ps w-100    me-3" data-scrollbar="true" data-skip-mobile="true">
            <div class="d-flex flex-row overflow-auto" style="white-space: nowrap">
              <button class="btn btn-default me-2 category w-auto h-50px py-2"
                      [ngClass]="{active: item.id === selectedCategory.id}"
                      *ngFor="let item of posCategories"
                      (click)="changeCategory(item.id)">
                {{ item.name }}
              </button>
            </div>

          </div>

          <button class="h-100  border-0 rounded btn small py-2"
                  (click)="refreshMenu()"><i class="fa fa-sync"></i>
          </button>
        </div>

        <div class="pos-content overflow-y-auto thin-scroll-container">
          <div class="pos-content-container h-100" style="padding-top: 0 !important;">
            <div class="d-flex flex-wrap gx-4 mt-2 thin-scroll-container">
              <div
                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 pb-2"
                data-type="snacks"
                *ngFor="let item of filteredPosItems"
                (click)="addItem(item)">
                <div class="pos-product"
                     matRipple
                     [matRippleCentered]="true"
                     [matRippleColor]="'rgba(255,194,4,0.53)'"
                >
                  <img
                    class="img"
                    loading="lazy"
                    *ngIf="item.posItem?.imageUrl"
                    [ngStyle]="{'background-image': 'url(' + item.posItem.imageUrl + ')'}" />
                  <div class="img justify-content-center align-items-center d-flex" *ngIf="!item.posItem?.imageUrl">
                    <img src="assets/img/no-img.jpg" alt="" class="h-120px">
                  </div>
                  <div class="bg-opacity-70 bg-gray-900 top-0 end-0 h-50px info position-absolute w-35 z-3"
                       style="border-bottom-left-radius: 10px; display: flex; align-items: center;">
                    <div class="text-end " style="color: #ffc400">RM {{ item?.price | number : "1.2" }}</div>
                  </div>

                  <div class="bg-opacity-70 bg-gray-900 bottom-0 h-50px info position-absolute w-100 z-3">
                    <div class="opacity-0">{{ item.posItem.name }}</div>
                    <!--                    <div class="text-center" style="font-size: 10px">RM {{ item?.price | number : "1.2" }}</div>-->
                  </div>
                  <div class="info position-absolute bottom-0 h-50px p-1 text-center w-100 justify-content-center z-3">
                    <div class="text-white">{{ item.posItem.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pos-sidebar" id="pos-sidebar">
          <div class="h-100 d-flex flex-column p-0">
            <div class="pos-sidebar-header d-flex flex-column justify-content-start w-100">
              <div class="w-100 d-flex flex-row py-1">
                <div class="back-btn">
                  <button
                    type="button"
                    data-toggle-class="pos-mobile-sidebar-toggled"
                    data-toggle-target="#pos"
                    class="btn">
                    <i class="fa fa-chevron-left"></i>
                  </button>
                </div>
                <div class="icon"><i class="fa fa-table"></i></div>
                <div class="title">Table {{ table?.name }}</div>
                <div class="order">
                  Order No: <span class="fw-semibold">#{{ order?.orderNo }}</span>
                </div>
              </div>

<!--              customer info -->
              <div class="order d-flex flex-row justify-content-start w-100 py-1">
                <div class="d-flex flex-row" *ngIf="order?.membership">
                  <div class="d-flex flex-column w-100 me-3">
                    <span class="text-left">{{ order?.membership?.name }}</span>
                    <span class="text-left">{{ order?.membership?.phoneNo }}</span>
                  </div>

                  <button
                    class="btn btn-default border-0"
                    (click)="removeCustomer()">
                    <i class="fa fa-trash text-danger"></i>
                  </button>

                </div>
                <ng-container *ngIf="!order?.membership">
                  <button
                    class="btn btn-default border-0"
                    (click)="searchCustomer()">
                    <span class="me-2">Add customer</span>
                    <i class="fa fa-user-plus"></i>
                  </button>
                </ng-container>
              </div>

            </div>
            <div
              class="pos-sidebar-body thin-scroll-container tab-content"
              data-scrollbar="true"
              data-height="100%"
              style="height: 100%">
              <div class="tab-pane fade h-100 show active" id="newOrderTab" role="tabpanel">
                <ng-container *ngFor="let item of order?.orderItems | filterUndeleted; let i = index">
                  <div class="pos-order">
                    <div class="pos-order-product">
                      <div class="flex-1">
                        <div class="fs-16px fw-bold mb-1">{{ item.name }}</div>
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="fs-16px text-blue-700 me-3" role="button" (click)="editItem(item)">
                            <i class="fa fa-edit"></i>
                            Edit Item
                          </div>
                          <div class="fs-16px text-blue-700" role="button" (click)="editDiscount(item)">
                            <i class="fa fa-percentage"></i>
                            Discount
                          </div>
                        </div>

                        <div class="fs-12px mb-1" *ngIf="item.instructions">"<span
                          class="fst-italic">{{ item.instructions }}"</span></div>

                        <div class="mb-2 mt-2">
                          <ng-container *ngFor="let variant of item.varianceModel">
                            <span>
                              - {{ variant.title }}
                              <ul class="mb-0">
                                <ng-container *ngFor="let variantOption of variant.options">
                                  <span class="d-inline-block" style="min-width: 150px; max-width: 200px">
                                    {{ variantOption.name }}
                                  </span>
                                  <span>
                                    +RM{{ variantOption.price | number : "1.2" }}
                                  </span>
                                </ng-container>
                              </ul>
                            </span>
                          </ng-container>
                          <!--                          - size: large<br>-->
                          <!--                          - less ice-->
                        </div>
                        <div class="d-flex align-items-center">
                          <button class="btn btn-primary btn-sm" (click)="minusQty(item)">
                            <i class="fa fa-minus"></i>
                          </button>
                          <!--                          <input-->
                          <!--                            type="text"-->
                          <!--                            class="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"-->
                          <!--                            value="{{ item?.quantity }}" />-->
                          <span class="w-25 text-center"> {{ item?.quantity }}</span>
                          <button class="btn btn-primary btn-sm" (click)="addQty(item)">
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="pos-order-price d-flex flex-column">
                      <div *ngIf="item.discountAmount > 0" class="flex-1 text-right fs-16px">
                        RM {{ item.subtotal - item.discountAmount | number : "1.2" }}
                      </div>
                      <div class="flex-1 text-right fs-16px"
                           [class.text-gray-500]="item.discountAmount > 0 ? 'line-through': ''"
                           [class.small]="item.discountAmount > 0 ? 'line-through': ''"
                           [style.text-decoration]="item.discountAmount > 0 ? 'line-through': ''">
                        <span>RM {{ item.subtotal | number : "1.2" }}</span>
                        <span class="ms-1" *ngIf="item?.discountAmount >0">({{ item?.discountPercentage }}%)</span>
                      </div>
                      <div class="text-end">
                        <button class="btn btn-default btn-sm border-0" (click)="item['isRemoved'] = true">
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                      </div>
                    </div>

                    <div
                      class="pos-order-confirmation text-center d-flex flex-column justify-content-center"
                      *ngIf="item['isRemoved']">
                      <div class="mb-1">
                        <i class="fa fa-trash fs-36px lh-1 text-body text-opacity-25"></i>
                      </div>
                      <div class="mb-2">Remove {{ item.name }}?</div>
                      <div>
                        <button
                          class="btn btn-default btn-sm ms-auto me-4 width-100px"
                          (click)="item['isRemoved'] = false">
                          No
                        </button>
                        <button class="btn btn-danger btn-sm width-100px" (click)="removeItem(i)">Yes</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="pos-sidebar-footer">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header class="px-3">
                    <div class="d-flex w-100 justify-content-between fs-18px fw-semibold">
                      <span>Qty</span>
                      <span class="text-right">{{ orderQuantity }}</span>
                      <span>Grand Total</span>
                      <span class="text-right">{{ order?.netAmount | number: "1.2" }}</span>
                    </div>
                  </mat-expansion-panel-header>
                  <dl class="space-y-1 py-2 text-sm text-gray-700">
                    <div class="d-flex justify-content-between">
                      <dt>Subtotal</dt>
                      <dd>{{ order?.subtotal | number: "1.2" }}</dd>
                    </div>

                    <div class="d-flex justify-content-between">
                      <dt>SST</dt>
                      <dd>{{ order?.tax1Amount | number: "1.2" }}</dd>
                    </div>

                    <div class="d-flex justify-content-between">
                      <dt>Serv Charge</dt>
                      <dd>{{ order?.tax2Amount | number: "1.2" }}</dd>
                    </div>

                    <div class="d-flex justify-content-between">
                      <dt>Item Discount</dt>
                      <dd>
                        -{{
                          order?.discountItemAmount | number: "1.2"
                        }}
                      </dd>
                    </div>

                    <div class="d-flex justify-content-between">
                      <dt>Rounding</dt>
                      <dd>
                        {{ order?.roundingAmount | number: "1.2" }}
                      </dd>
                    </div>
                  </dl>
                </mat-expansion-panel>
              </mat-accordion>

              <div class="mt-3">
                <div class="d-flex">
                  <button
                    class="btn btn-default text-danger border-0 me-10px flex-fill d-flex align-items-center justify-content-center"
                    (click)="clearCart()"
                    *ngIf="order?.id == 0"
                    [disabled]="order?.orderItems?.length == 0 || loading.holding">
                    <span class="d-flex flex-column align-items-center">
                      <i class="fa fa-trash fa-lg my-10px d-block"></i>
                      <span class="small fw-semibold">Clear</span>
                    </span>
                  </button>
                  <button
                    class="btn btn-default bg-secondary text-white border-0 me-10px flex-fill d-flex align-items-center justify-content-center"
                    (click)="hold()"
                    *ngIf="order?.id == 0"
                    [disabled]="order?.orderItems?.length == 0 || loading.holding">
                    <span class="d-flex flex-column align-items-center">
                      <i class="fa fa-shopping-cart fa-lg my-10px d-block"></i>
                      <span class="small fw-semibold">Hold</span>
                    </span>
                  </button>
                  <button
                    class="btn btn-default bg-primary text-white flex-fill d-flex align-items-center justify-content-center"
                    (click)="submitOrder()"
                    [disabled]="order?.orderItems?.length == 0 || loading.submitting">
                    <span class="d-flex flex-column align-items-center">
                      <i class="fa fa-cash-register fa-lg my-10px d-block"></i>
                      <span class="small fw-semibold">{{ order?.id > 0 ? 'Update Order' : 'Submit Order' }}</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      class="pos-mobile-sidebar-toggler"
      data-toggle-class="pos-mobile-sidebar-toggled"
      data-toggle-target="#pos">
      <i class="fa fa-shopping-bag"></i>
      <span class="badge">{{ order?.orderItems.length }}</span>
    </a>
  </div>
</div>
