<mat-dialog-content>
  <div class="w-100">

    <div class="fs-4 text-black mb-4 fw-bold">{{ title }}</div>
    <form class="d-flex flex-column" #form="ngForm">

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="membership.name"
               minlength="3"
               name="name"
               type="text"
               required
               placeholder="">
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Phone No</mat-label>
        <input matInput [(ngModel)]="membership.phoneNo"
               name="phoneNo"
               type="tel"
               required
               placeholder="">
        <mat-hint>eg: 60123456789</mat-hint>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Gender</mat-label>
        <mat-select [(ngModel)]="membership.gender"
                    name="gender"
                    placeholder="Select gender">
          <mat-option [value]="'male'">Male</mat-option>
          <mat-option [value]="'female'">Female</mat-option>
          <mat-option [value]="'unknown'">Unknown</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="membership.email"
               name="email"
               type="email"
               placeholder="">
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Date of Birth</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="membership.dob" name="dob">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </form>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 p-3">
    <div class="col-4 me-3">
      <button class="btn btn-default border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button">
      <button
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center"
        [disabled]="form.invalid"
        (click)="submit()">
        <i class="fa fa-plus-circle me-2"></i>
        <span>Add</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
