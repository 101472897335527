<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical" id="pos">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="d-flex justify-content-center align-items-center flex-column card card-body">

          <div class="row justify-content-center align-items-center flex-column">
            <h2 class="text-center"></h2>
            <h2 class="text-center">
              <i class="fa fa-check-circle me-2 text-green"></i>
              <span>Order has been paid</span>
              </h2>
          </div>


          <div class="row justify-content-center align-items-center flex-column mt-4 w-400px fs-4 gap-2">
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="col-6">Receipt No:</label>
              <span class="col-auto text-right">{{payment?.paymentNo}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="col-6">Paid On:</label>
              <span class="col-auto text-right">{{payment?.creationTime | date: 'dd/MM/yy HH:mm'}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="col-6">Pay Using:</label>
              <div class="d-flex flex-row">
                <img src="{{payment?.paymentMethod?.logoUrl}}"
                     class="h-25px me-3"
                     *ngIf="payment?.paymentMethod?.logoUrl">
                <span class="col-auto text-right">{{payment?.paymentMethod?.name}}</span>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center" *ngIf="payment?.ewalletReferenceNo">
              <label for="" class="col-6">Ref:</label>
              <span class="col-auto text-right">{{payment?.ewalletReferenceNo}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="col-6">Total Bill:</label>
              <span class="col-auto text-right">{{payment?.netAmount | number: '1.2'}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <label for="" class="col-6">Tendered Amount:</label>
              <span class="col-auto text-right">{{payment?.paidAmount | number: '1.2'}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center fw-bold text-blue-700">
              <label for="" class="col-6">Change:</label>
              <span class="col-auto text-right">{{payment?.changeAmount | number: '1.2'}}</span>
            </div>

          </div>

          <div class="d-flex flex-column w-100 justify-content-center align-items-center mt-5">

            <div class="d-flex w-100 justify-content-center align-items-center">
              <div class="col-lg-3 col-12 col-sm-auto  me-3">
                <button class="btn btn-default fw-semibold mb-0 d-block py-3 w-100" (click)="reprintReceipt()">
                  <i class="fa fa-print me-2"></i>
                  Print Copy Receipt
                </button>
              </div>
              <div class="col-lg-3 col-12 col-sm-auto ">
                <button class="btn btn-default fw-semibold mb-0 d-block py-3 w-100" (click)="kickCashDrawer()">
                  <i class="fa fa-cash-register me-2"></i>
                  Open Cash Drawer
                </button>
              </div>

            </div>

            <div class="col-lg-6 col-12 col-sm-auto mt-3">
              <button class="btn btn-default fw-semibold mb-0 d-block py-3 w-100"
                      [routerLink]="['/payment-detail']" [queryParams]="{paymentId: payment?.id}">
                <i class="fa fa-file-invoice me-2"></i>
                View Order
              </button>
            </div>
            <div class="col-lg-6 col-12 col-sm-auto mt-3">
              <button class="btn btn-lg btn-primary bg-primary text-white border-0 fw-semibold mb-0 d-block py-3 w-100"
                      [routerLink]="['/']">
                <i class="fa fa-star me-2"></i>
                New Sales
              </button>
            </div>

          </div>

        </div>


      </div>
    </div>
  </div>
</div>
