import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrderItem} from "../../../@services/gen/model/orderItem";

@Component({
  selector: 'app-pos-discount-dialog',
  templateUrl: './pos-discount-dialog.component.html',
  styleUrls: ['./pos-discount-dialog.component.scss'],
})
export class PosDiscountDialogComponent {
  cashDiscount: number = 0;
  discountPercentage: number = 0;
  orderItem: OrderItem;
  percentagePresets = ['custom', '5', '10', '15', '20', '50', '100'];
  showPercentageDiscountInput: boolean;
  selectedPercentageDiscountIndex: number;

  constructor(private dialogRef: MatDialogRef<PosDiscountDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.orderItem = data.orderItem;
  }

  submit() {
    this.dialogRef.close({
      cashDiscount: this.cashDiscount,
      discountPercentage: this.discountPercentage,
    });
  }

  calculate(type: 'cash' | 'percentage') {
    switch (type) {
      case 'cash':
        this.calculateDiscountPercentage();
        break;
      case 'percentage':
        this.calculateCashDiscount();
        break;
    }
  }

  calculateDiscountPercentage() {
    if (this.orderItem.subtotal <= 0 || this.cashDiscount <= 0 || this.cashDiscount > this.orderItem.subtotal) {
      this.discountPercentage = 0;
    }
    this.discountPercentage = +((this.cashDiscount / this.orderItem.subtotal) * 100).toFixed(2);
  }

  calculateCashDiscount() {
    if (this.orderItem.subtotal <= 0 || this.discountPercentage < 0 || this.discountPercentage > 100) {
      this.cashDiscount = 0;
    }

    this.cashDiscount = +((this.discountPercentage / 100) * this.orderItem.subtotal).toFixed(2);
  }

  selectPercentageDiscount(item: string, itemIndex: number) {
    this.selectedPercentageDiscountIndex = itemIndex;
    this.showPercentageDiscountInput = false;
    if (item === 'custom') {
      this.showPercentageDiscountInput = true;
    } else {
      this.discountPercentage = +item;
      this.calculate('percentage');
    }
  }
}
