import {Injectable} from '@angular/core';
import {calculateRoundingAmount} from './utils';
import {OrderReq} from "./gen";
import {TaxService} from './tax.service';
import {Order} from "./gen/model/order";
import {ExtendedOrder} from "../@pages/pos/pos-split-bill-dialog/pos-split-bill-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private customTaxService: TaxService) {}

  calculateOrderValues(orders: ExtendedOrder[]) {
    let taxRates = this.customTaxService.getTaxRates();

    let quantity = 0;
    let subtotal = 0;
    let tax1Amount = 0;
    let tax2Amount = 0;
    let totalAmount = 0;
    let roundingAmount = 0;
    let netAmount = 0;
    let averageCost = 0;
    let discountAmount = 0;
    let discountItemAmount = 0;
    let paymentSubtotalItem = 0;
    let paymentDiscountItem = 0;
    let paymentQtyItem = 0;
    let paymentTotalOrder = 0;
    let paymentSubtotalOrder = 0;
    let paymentQtyOrder = 0;
    let paymentDiscountItemOrder = 0;

    for (let order of orders) {
      let orderItems = order.orderItems.filter((x) => !x.deleted);
      if (orderItems) {
        for (let orderItem of [...orderItems]) {
          let variantPrice = 0;
          if (!orderItem['overridePrice']) {
            if (orderItem.varianceModel) {
              for (let variant of orderItem.varianceModel) {
                for (let variantOption of variant.options) {
                  variantPrice += variantOption.price;
                }
              }
            }
          }

          orderItem.subtotal = +((orderItem.quantity * (+orderItem.price + variantPrice))).toFixed(2);

          orderItem.tax1Amount = +(
            (orderItem.subtotal - orderItem.discountAmount) *
            (taxRates.tax1Config?.rate ?? 0)
          ).toFixed(2);
          orderItem.tax2Amount = +(
            (orderItem.subtotal - orderItem.discountAmount) *
            (taxRates.tax2Config?.rate ?? 0)
          ).toFixed(2);
          orderItem.total = +(orderItem.subtotal + orderItem.tax1Amount + orderItem.tax2Amount).toFixed(2);
          orderItem.roundingAmount = +calculateRoundingAmount(+orderItem.total);
          orderItem.netAmount = +(orderItem.total + orderItem.roundingAmount).toFixed(2);

          //Get Subtotal item with discount
          let oneItemNetAmountWithDiscount = +(orderItem.netAmount - (orderItem.netAmount * (orderItem.discountPercentage/ 100))) / orderItem.quantity;
          orderItem.paidAmount = +(oneItemNetAmountWithDiscount * orderItem.toBePaidQuantity).toFixed(2);

          paymentDiscountItem = paymentDiscountItem + orderItem.toBePaidQuantity * ((orderItem.netAmount * (orderItem.discountPercentage/ 100)) / orderItem.quantity);
          paymentSubtotalItem = paymentSubtotalItem + orderItem.paidAmount;
          paymentQtyItem += orderItem.toBePaidQuantity;


          //Get Subtotal order without discount
          let oneItemNetAmount = +(orderItem.netAmount) / orderItem.quantity;
          paymentSubtotalOrder = paymentSubtotalOrder + +(oneItemNetAmount * orderItem.toBePaidQuantity).toFixed(2);
        }

        let _tax1Amount = +orderItems.reduce((a, b) => a + b.tax1Amount, 0).toFixed(2);
        let _tax2Amount = +orderItems.reduce((a, b) => a + b.tax2Amount, 0).toFixed(2);
        let _roundingAmount = +orderItems.reduce((a, b) => a + b.roundingAmount, 0).toFixed(2);
        let _subtotal = orderItems.map((x) => x.subtotal).reduce((prev, curr) => prev + curr, 0);
        let _averageCost = +orderItems.reduce((a, b) => a + b.averageCost, 0).toFixed(2);
        let _discountItemAmount = +orderItems.reduce((a, b) => a + b.discountAmount, 0).toFixed(2);
        let _quantity = +orderItems.reduce((a, b) => a + b.quantity, 0);

        order.subtotal = _subtotal;
        order.tax1Amount = _tax1Amount;
        order.tax2Amount = _tax2Amount;
        order.discountItemAmount = _discountItemAmount;
        order.totalAmount =
          order.subtotal +
          order.tax1Amount +
          order.tax2Amount -
          (order.discountItemAmount || 0) -
          (order.discountAmount || 0);
        order.roundingAmount = _roundingAmount;
        order.netAmount = order.totalAmount + order.roundingAmount;
        order.averageCost = _averageCost;

        subtotal += order.subtotal;
        tax1Amount += order.tax1Amount;
        tax2Amount += order.tax2Amount;
        totalAmount += order.totalAmount;
        averageCost += order.averageCost;
        discountAmount += order.discountAmount;
        discountItemAmount += order.discountItemAmount;
        quantity += _quantity;

        paymentTotalOrder =
          paymentSubtotalItem +
          tax1Amount +
          tax2Amount -
          (discountAmount || 0);
      }
    }

    roundingAmount = +calculateRoundingAmount(totalAmount);
    netAmount = totalAmount + roundingAmount;
    paymentTotalOrder = paymentTotalOrder + roundingAmount;
    paymentQtyOrder = paymentQtyOrder + paymentQtyItem;
    paymentDiscountItemOrder = paymentDiscountItemOrder + paymentDiscountItem;

    return {
      subtotal,
      tax1Amount,
      tax2Amount,
      totalAmount,
      roundingAmount,
      netAmount,
      averageCost,
      discountAmount,
      discountItemAmount,
      quantity,
      paymentTotalOrder,
      paymentQtyOrder,
      paymentSubtotalItem,
      paymentDiscountItemOrder,
      paymentSubtotalOrder
    };
  }
}
