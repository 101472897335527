import {Injectable} from '@angular/core';
import {BehaviorSubject, combineAll} from 'rxjs';
import {OrderReq, PaymentControllerService, ReceiptDto, UserControllerService} from "./gen";
import * as dayjs from 'dayjs';
import {alignCenter} from './utils';
import {Order} from './gen/model/order';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  unpaidOrdersSub$: BehaviorSubject<OrderReq[]> = new BehaviorSubject<OrderReq[]>([]);
  unpaidOrdersObs$ = this.unpaidOrdersSub$.asObservable();
  paymentComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private paymentService: PaymentControllerService, private userService: UserControllerService) {}

  getPaymentReceipt(paymentId: number, lineLength: number, cb: (res) => void) {
    const drawText = (text) => text + '\n';
    const underLine = '-'.repeat(lineLength);

    this.paymentService.getPaymentReceiptGet(paymentId).subscribe((receipt) => {
      let textToPrint = '';

      textToPrint += drawText(alignCenter(this.buildHeader(receipt, 'receipt', drawText)));
      textToPrint += drawText(underLine);

      let subtotal = 0;
      receipt.receiptItems.forEach((item, currentIdx) => {
        textToPrint += drawText(item.name);
        textToPrint += drawText(
          this.formatReceiptRow(
            '> ' + item.quantity + 'x   RM' + item.price?.toFixed(2) + '  = RM',
            item.total?.toFixed(2),
            lineLength
          )
        );

        if (item?.discountAmount > 0) {
          textToPrint += drawText(
            this.formatReceiptRow(
              `Disc`,
              `(${item?.discountPercentage}%) -${item?.discountAmount?.toFixed(2)}`,
              lineLength
            )
          );
        }
        if (item.instructions) {
          textToPrint += drawText(`"${item.instructions.trim()}"`);
        }
        if (item.variant) {
          textToPrint += drawText(`${item.variant}`);
        }

        if (currentIdx < receipt.receiptItems.length - 1) {
          textToPrint += drawText(``);
        }

        subtotal += item.total;
      });

      textToPrint += drawText(underLine);
      textToPrint += drawText(this.formatReceiptRow('Subtotal RM: ', subtotal?.toFixed(2), lineLength));

      if (receipt.tax1Amount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('SST(6%) RM: ', receipt.tax1Amount?.toFixed(2), lineLength));
      }
      if (receipt.tax2Amount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('Serv(10%) RM: ', receipt.tax2Amount?.toFixed(2), lineLength));
      }
      if (receipt.tipsAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('Tips RM: ', receipt.tipsAmount?.toFixed(2), lineLength));
      }
      if (receipt.discountAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(
          this.formatReceiptRow('Disc RM: ', '-' + receipt.discountAmount?.toFixed(2), lineLength)
        );
      }
      if (receipt.discountItemAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(
          this.formatReceiptRow('Item Disc RM: ', '- ' + receipt.discountItemAmount?.toFixed(2), lineLength)
        );
      }
      if (receipt.roundingAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('Rounding RM: ', receipt.roundingAmount?.toFixed(2), lineLength));
      }

      textToPrint += drawText(underLine);

      textToPrint += drawText(this.formatReceiptRow('Total RM: ', receipt.netAmount?.toFixed(2), lineLength));
      textToPrint += drawText(this.formatReceiptRow('Received RM: ', receipt.paidAmount?.toFixed(2), lineLength));
      textToPrint += drawText(this.formatReceiptRow('Change RM: ', receipt.changeAmount?.toFixed(2), lineLength));
      textToPrint += drawText(this.formatReceiptRow('Paid using: ', receipt.paymentMethodName || '-', lineLength));
      textToPrint += receipt.ewalletReferenceNo
        ? drawText(this.formatReceiptRow('Ref: ', receipt.ewalletReferenceNo || '-', lineLength))
        : '';
      textToPrint += drawText('');
      textToPrint += drawText(alignCenter(this.buildFooter(receipt, drawText)));
      textToPrint += drawText('');

      console.log(`texttoprint`);
      console.log(textToPrint);

      cb(textToPrint);
    });
  }

  getOrderReceipt(orderId: number, kitchenId: number, lineLength: number, cb: (text, hasData) => void) {
    const drawText = (text) => text + '\n';
    const underLine = '-'.repeat(lineLength);

    this.paymentService.getOrderReceiptGet(kitchenId, orderId).subscribe((receipt) => {
      let textToPrint = '';

      if (receipt.receiptItems && receipt.receiptItems.length > 0) {
        textToPrint += drawText(``);
        textToPrint += drawText(``);
        textToPrint += drawText(``);
        textToPrint += drawText(`<B>Table: ${receipt.tableName}</B>`);
        textToPrint += drawText('Order: ' + receipt.receiptNo);
        textToPrint += drawText('Date: ' + dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
        textToPrint += drawText(underLine);

        receipt.receiptItems.forEach((item, currentIdx) => {
          textToPrint += drawText(this.formatReceiptRow(`${item.name}`, `x${item.printQuantity}`));

          if (item.instructions) {
            textToPrint += drawText(`"${item.instructions}"`);
          }

          if (item.variant) {
            textToPrint += drawText(item.variant);
          }

          if (currentIdx < receipt.receiptItems.length - 1) {
            textToPrint += drawText(``);
          }
        });

        textToPrint += drawText('');
        textToPrint += drawText('');
        console.log(`order receipt texttoprint`);
        console.log(textToPrint);
      } else {
        console.log('no order to be printed');
      }

      cb(textToPrint, receipt.receiptItems && receipt.receiptItems.length > 0);

      let orderItemsIds = receipt.receiptItems.flatMap((ri) => ri.orderItemId);
      this.paymentService
        .markPrintOrderReceiptPost({
          orderItemIds: [...orderItemsIds],
        })
        .subscribe();
    });
  }

  getOrderReceiptUnprinted(merchantId: number, kitchenId: number, lineLength: number, cb: (text, hasData) => void) {
    const drawText = (text) => text + '\n';
    const underLine = '-'.repeat(lineLength);

    let subscription = this.paymentService.getOrderReceiptUnprintedGet(kitchenId, merchantId).subscribe(
      (receipts) => {

        for (let receipt of receipts) {
          let textToPrint = '';

          if (receipt.receiptItems && receipt.receiptItems.length > 0) {
            textToPrint += drawText(``);
            textToPrint += drawText(``);
            textToPrint += drawText(``);
            textToPrint += drawText(`<B>Table: ${receipt.tableName}</B>`);
            textToPrint += drawText('Order: ' + receipt.receiptNo);
            textToPrint += drawText('Date: ' + dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
            textToPrint += drawText(underLine);

            receipt.receiptItems.forEach((item, currentIdx) => {
              textToPrint += drawText(this.formatReceiptRow(`${item.name}`, `x${item.printQuantity}`));

              if (item.instructions) {
                textToPrint += drawText(`"${item.instructions}"`);
              }

              if (item.variant) {
                textToPrint += drawText(item.variant);
              }

              if (currentIdx < receipt.receiptItems.length - 1) {
                textToPrint += drawText(``);
              }
            });

            textToPrint += drawText('');
            textToPrint += drawText('');
            console.log(`order receipt texttoprint`);
            console.log(textToPrint);
          } else {
            console.log('no order to be printed');
          }

          cb(textToPrint, receipt.receiptItems && receipt.receiptItems.length > 0);

          let orderItemsIds = receipt.receiptItems.flatMap((ri) => ri.orderItemId);
          let subscription1 = this.paymentService
            .markPrintOrderReceiptPost({
              orderItemIds: [...orderItemsIds],
            })
            .subscribe(
              () => {},
              (error) => {},
              () => {
                subscription1.unsubscribe();
              }
            );
        }
      },
      (error) => {},
      () => {
        subscription.unsubscribe();
      }
    );
  }

  getPaymentBill(tableId: number, lineLength: number, cb: (res) => void) {
    const drawText = (text) => text + '\n';
    const underLine = '-'.repeat(lineLength);

    this.paymentService.getPaymentBillGet(tableId).subscribe((receipt) => {
      let textToPrint = '';

      textToPrint += drawText(alignCenter(this.buildHeader(receipt, 'bill', drawText)));
      textToPrint += drawText(underLine);

      let subtotal = 0;
      receipt.receiptItems.forEach((item) => {
        textToPrint += drawText(item.name);
        textToPrint += drawText(
          this.formatReceiptRow(
            '> ' + item.quantity + 'x   RM' + item.price?.toFixed(2) + '  = RM',
            item.total?.toFixed(2),
            lineLength
          )
        );

        if (item?.discountAmount > 0) {
          textToPrint += drawText(
            this.formatReceiptRow(
              `Disc`,
              `(${item?.discountPercentage}%) -${item?.discountAmount?.toFixed(2)}`,
              lineLength
            )
          );
        }

        if (item.variant) {
          textToPrint += drawText(item.variant);
        }

        subtotal += item.total;
      });

      textToPrint += drawText(underLine);
      textToPrint += drawText(this.formatReceiptRow('Subtotal RM: ', subtotal?.toFixed(2), lineLength));

      if (receipt.discountAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(
          this.formatReceiptRow('Disc RM: ', '-' + receipt.discountAmount?.toFixed(2), lineLength)
        );
      }
      if (receipt.discountItemAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(
          this.formatReceiptRow('Item Disc RM: ', '-' + receipt.discountItemAmount?.toFixed(2), lineLength)
        );
      }
      if (receipt.tax1Amount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('SST(6%) RM: ', receipt.tax1Amount?.toFixed(2), lineLength));
      }
      if (receipt.tax2Amount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('Serv(10%) RM: ', receipt.tax2Amount?.toFixed(2), lineLength));
      }
      textToPrint += drawText(underLine);

      if (receipt.roundingAmount?.toFixed(2) !== '0.00') {
        textToPrint += drawText(this.formatReceiptRow('Rounding RM: ', receipt.roundingAmount?.toFixed(2), lineLength));
      }

      textToPrint += drawText(this.formatReceiptRow('Total RM: ', receipt.netAmount?.toFixed(2), lineLength));
      textToPrint += drawText('');
      textToPrint += drawText(alignCenter(this.buildFooter(receipt, drawText)));
      textToPrint += drawText('');

      console.log(`texttoprint`);
      console.log(textToPrint);

      cb(textToPrint);
    });
  }

  getClosingReceipt(shiftId: number, lineLength: number, cb: (res) => void) {
    const drawText = (text) => text + '\n';
    const underLine = '-'.repeat(lineLength);

    this.userService
      .getUserShiftClosingKVPost({
        shiftId: shiftId,
        includeOrderByHour: false,
        includeOrderByCategory: false,
        includeSalesItem: false,
      })
      .subscribe((data) => {
        let textToPrint = '';
        textToPrint += drawText(alignCenter('<B>Closing Receipt</B>'));
        textToPrint += drawText('');

        for (const d of data) {
          if (Array.isArray(d.value)) {
            for (const inner of d.value) {
              textToPrint += drawText(this.formatReceiptRow(inner.key, inner.value ?? '', lineLength));
            }
          } else {
            textToPrint += drawText(this.formatReceiptRow(d.key, d.value ?? '', lineLength));
          }
          textToPrint += drawText(underLine);
        }
        textToPrint += drawText(alignCenter('*** End of Receipt ***'));
        textToPrint += drawText(alignCenter('*** Powered by DUCK POS ***'));
        textToPrint += drawText('');
        textToPrint += drawText('');
        textToPrint += drawText('');

        console.log(`closing receipt \n`);
        console.log(textToPrint);
        cb(textToPrint);
      });
  }

  formatReceiptRow(label, amount, lineLength: number = 30) {
    let formattedAmount = '';
    if (amount) {
      if (isNaN(amount)) {
        formattedAmount = amount;
      } else {
        formattedAmount = parseFloat(amount)
          ?.toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }
    } else {
      formattedAmount = '0.00';
    }

    let labels = this.splitStringByLength(label, lineLength - 8);

    let labelPadding = 0;
    if (labels.length > 1) {
      labelPadding = lineLength - labels[0].length - formattedAmount.length;
    } else {
      labelPadding = lineLength - label.length - formattedAmount.length;
    }
    const padding = ' '.repeat(Math.max(labelPadding, 0));

    if (labels.length > 1) {
      return `${labels[0]}${padding}${formattedAmount}\n${labels[1]}`;
    } else {
      return `${label}${padding}${formattedAmount}`;
    }
  }

  splitStringByLength(string, maxLength) {
    if (!string) {
      return '';
    }
    const words = string.split(' ');
    let currentLine = '';
    let result = [];

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const lineLength = currentLine.length + word.length;

      if (lineLength <= maxLength) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        result.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine !== '') {
      result.push(currentLine);
    }

    return result;
  }

  private buildHeader(receipt: ReceiptDto, receiptType: 'receipt' | 'bill', drawText: (text) => string) {
    let headerContent: string = '';
    if (receipt.header && receiptType === 'receipt') {
      headerContent = receipt.header
        .replace('{{companyName}}', receipt.companyName)
        .replace('{{companyPhone}}', receipt.companyPhone)
        .replace('{{companyAddress}}', receipt.companyAddress)
        .replace('{{companyEmail}}', receipt.companyEmail)
        .replace('{{receiptNo}}', receipt.receiptNo)
        .replace('{{queueNo}}', receipt.queueNo)
        .replace('{{tableName}}', receipt.tableName)
        .replace('{{orderDate}}', dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
    } else {
      if (receiptType === 'receipt') {
        headerContent += drawText(`<M><B>${receipt.queueNo}</B></M>`);
      }
      headerContent += drawText(receipt.companyName);
      headerContent += drawText(receipt.companyPhone ? receipt.companyPhone : '');
      headerContent += drawText(receipt.companyAddress ? receipt.companyAddress : '');

      if (receiptType === 'receipt') {
        headerContent += drawText('Receipt: #' + receipt.receiptNo);
        headerContent += drawText('Table: ' + receipt.tableName);
        headerContent += drawText(dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
      } else {
        headerContent += drawText('Table: ' + receipt.tableName);
        headerContent += drawText(dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
        headerContent += drawText('BILL');
      }
    }

    return headerContent;
  }

  private buildFooter(receipt: ReceiptDto, drawText: (text) => string) {
    let footerContent: string = null;
    if (receipt.footer) {
      footerContent = receipt.footer
        .replace('{{companyName}}', receipt.companyName)
        .replace('{{companyPhone}}', receipt.companyPhone)
        .replace('{{companyAddress}}', receipt.companyAddress)
        .replace('{{companyEmail}}', receipt.companyEmail)
        .replace('{{receiptNo}}', receipt.receiptNo)
        .replace('{{queueNo}}', receipt.queueNo)
        .replace('{{tableName}}', receipt.tableName)
        .replace('{{orderDate}}', dayjs(receipt.paymentDate).format('DD/MM/YYYY HH:mm'));
    }

    return footerContent
      ? drawText(footerContent)
      : drawText('*** Thank You ***') + drawText('*** Powered by DUCK POS ***');
  }
}
