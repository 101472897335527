<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header pos-mobile-sidebar-toggled" id="pos">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="d-flex  align-items-center flex-column card card-body flex-grow-0">
          <span class="fs-3 fw-bold mb-3 d-block">{{title}}</span>
          <form class="d-flex flex-column gap-2  fw-semibold align-items-center justify-content-center w-300px">

            <label for="closingCash">Closing Cash Only (RM):</label>
            <input id="closingCash" name="closingCash" type="number"
                   class="rounded p-2"
                   autofocus
                   [(ngModel)]="closingCash">

            <!--            {{shiftSummary | json}}-->


            <div *ngIf="shiftSummary as s" class="mt-3 w-100">
              <div class="d-flex justify-content-between w-100">
                <span>Opening Cash</span>
                <span>RM {{s.openingCash | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span> Cash Sales</span>
                <span>RM {{s.cashAmount | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span> Cash In</span>
                <span>RM {{s.cashInAmount | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span> Cash Out</span>
                <span>RM {{s.cashOutAmount | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100 text-success">
                <span> Expected Closing</span>
                <span>RM {{(s.cashAmount + s.openingCash + s.cashInAmount + s.cashOutAmount) | number: '1.2'}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between w-100">
                <span> eWallet</span>
                <span>RM {{s.ewalletAmount | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span> Delivery</span>
                <span>RM {{s.deliveryAmount | number: '1.2'}}</span>
              </div>
              <div class="d-flex justify-content-between w-100">
                <span> Visa</span>
                <span>RM {{s.visaAmount | number: '1.2'}}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between w-100">
                <span>Total Sales</span>
                <span>RM {{s.totalSales | number: '1.2'}}</span>
              </div>
            </div>


            <div class="d-flex mt-4">
              <button class="btn btn-lg btn-default fw-semibold mb-0 d-block py-3 me-3" (click)="location.back()">
                Cancel
              </button>
              <button class="btn btn-lg bg-primary text-white fw-semibold mb-0 d-block py-3" type="submit"
                      [disabled]="closingCash  === null || closingCash === undefined" (click)="submit()">Submit
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>
