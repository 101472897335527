import {Component} from '@angular/core';
import {Merchant, UserControllerService} from '../../../@services/gen';
import {AuthService} from '../../../@services/auth.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {NotificationService} from '../../../@services/notification.service';
import {LocalstorageService} from '../../../@services/localstorage.service';
import {UserModel} from "../../../@models/user.model";
import {kickCashDrawerz} from "../../../@services/utils";
import {UserShiftService} from "../../../@services/user-shift.service";

@Component({
  selector: 'app-shift-opening',
  templateUrl: './shift-opening.component.html',
  styleUrls: ['./shift-opening.component.scss'],
})
export class ShiftOpeningComponent {
  title = 'Shift Opening Form';
  private merchant: Merchant;
  openingCash: number;
  user: UserModel;
  role: any;

  constructor(
    private userService: UserControllerService,
    private customAuthService: AuthService,
    private ns: NotificationService,
    private ls: LocalstorageService,
    public location: Location,
    private router: Router,
    private userShiftService: UserShiftService
  ) {}

  ngOnInit(): void {
    this.merchant = this.ls.getMerchant();
    this.user = this.customAuthService.getUser();
    this.role = this.user.role;

    console.log(this.role);
    kickCashDrawerz();
  }

  submit() {
    let dialogRef = this.ns.showNotification({
      message: 'Please wait...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.userService
      .startStopUserShiftPost({
        actionType: 'start',
        userShift: {
          openingCash: this.openingCash,
          merchant: {id: this.ls.getMerchant().id},
        },
      })
      .subscribe((res) => {
        this.userShiftService.saveUserShift(res);
        dialogRef.close();
        this.router.navigate(['/']);
      });
  }

  cancel() {
    this.ls.clear();
    this.router.navigate(['/login']);
  }
}
