/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedOrderQuery { 
    endDate?: string;
    kitchenOrderStatusEnum?: Array<GetPagedOrderQuery.KitchenOrderStatusEnumEnum>;
    merchantId?: number;
    orderItemStatusEnum?: Array<GetPagedOrderQuery.OrderItemStatusEnumEnum>;
    orderStatusEnum?: Array<GetPagedOrderQuery.OrderStatusEnumEnum>;
    pageRequest?: CustomPageRequest;
    startDate?: string;
    tableId?: number;
}
export namespace GetPagedOrderQuery {
    export type KitchenOrderStatusEnumEnum = 'CANCELLED' | 'CONFIRMED' | 'NEW' | 'PREPARING' | 'READY' | 'SERVED';
    export const KitchenOrderStatusEnumEnum = {
        Cancelled: 'CANCELLED' as KitchenOrderStatusEnumEnum,
        Confirmed: 'CONFIRMED' as KitchenOrderStatusEnumEnum,
        New: 'NEW' as KitchenOrderStatusEnumEnum,
        Preparing: 'PREPARING' as KitchenOrderStatusEnumEnum,
        Ready: 'READY' as KitchenOrderStatusEnumEnum,
        Served: 'SERVED' as KitchenOrderStatusEnumEnum
    };
    export type OrderItemStatusEnumEnum = 'CANCELLED' | 'NEW' | 'PAID' | 'PARTIALLY_PAID' | 'PARTIALLY_VOID' | 'PAYMENT_VOIDED' | 'UNFULFILLED' | 'UNPAID';
    export const OrderItemStatusEnumEnum = {
        Cancelled: 'CANCELLED' as OrderItemStatusEnumEnum,
        New: 'NEW' as OrderItemStatusEnumEnum,
        Paid: 'PAID' as OrderItemStatusEnumEnum,
        PartiallyPaid: 'PARTIALLY_PAID' as OrderItemStatusEnumEnum,
        PartiallyVoid: 'PARTIALLY_VOID' as OrderItemStatusEnumEnum,
        PaymentVoided: 'PAYMENT_VOIDED' as OrderItemStatusEnumEnum,
        Unfulfilled: 'UNFULFILLED' as OrderItemStatusEnumEnum,
        Unpaid: 'UNPAID' as OrderItemStatusEnumEnum
    };
    export type OrderStatusEnumEnum = 'CANCELLED' | 'NEW' | 'PAID' | 'PARTIALLY_PAID' | 'PARTIALLY_VOID' | 'PAYMENT_VOIDED' | 'UNFULFILLED' | 'UNPAID';
    export const OrderStatusEnumEnum = {
        Cancelled: 'CANCELLED' as OrderStatusEnumEnum,
        New: 'NEW' as OrderStatusEnumEnum,
        Paid: 'PAID' as OrderStatusEnumEnum,
        PartiallyPaid: 'PARTIALLY_PAID' as OrderStatusEnumEnum,
        PartiallyVoid: 'PARTIALLY_VOID' as OrderStatusEnumEnum,
        PaymentVoided: 'PAYMENT_VOIDED' as OrderStatusEnumEnum,
        Unfulfilled: 'UNFULFILLED' as OrderStatusEnumEnum,
        Unpaid: 'UNPAID' as OrderStatusEnumEnum
    };
}


