<mat-dialog-content>
  <div class="modal-pos-product">
    <div class="modal-pos-product-img">
      <div class="img"
           *ngIf="orderItem.posItemVsMerchant.posItem.imageUrl"
           [ngStyle]="{'background-image': 'url(' + orderItem.posItemVsMerchant.posItem.imageUrl + ')'}">
      </div>
      <div class="img d-flex align-items-center justify-content-center"
           *ngIf="!orderItem.posItemVsMerchant.posItem.imageUrl">
        <img src="assets/img/no-img.jpg" alt="" class="h-200px">
      </div>
    </div>
    <div class="modal-pos-product-info">
      <div class="text-primary fs-4 fw-semibold">{{ orderItem?.name }}</div>
      <div class="text-body text-opacity-50 mb-2">
        {{ orderItem?.posItemVsMerchant?.posItem?.description }}
      </div>
      <div class="fs-3 fw-bold">
        <div class="row mb-3 align-items-center">
          <div class="col-md-1">
            RM
          </div>
          <div class="col-md-11">
            <input type="number"
                   class="p-2 px-3 rounded w-120px"
                   style="border: 1px solid var(--bs-border-color)"
                   [(ngModel)]="orderItem.price"
                   [class.border-red]="price.invalid && (price.dirty || price.touched)"
                   #price="ngModel"
                   required
                   min="0"
                   pattern="^\d+(\.\d+)?$"
            >
            <span *ngIf="price.invalid && (price.dirty || price.touched)" class="text-danger small ms-3" style="font-size: 10px">
              <span *ngIf="price.errors.required">Price is required.</span>
              <span *ngIf="price.errors.min">Price cannot be negative.</span>
              <span *ngIf="price.errors.pattern">Please enter a valid number.</span>
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <a href="#" class="btn btn-primary" (click)="minusQty(); $event.preventDefault(); $event.stopPropagation()"
          ><i class="fa fa-minus"></i
        ></a>

        <span class="fs-3 fw-bold w-25 text-center">{{orderItem?.quantity}}</span>
        <a href="#" class="btn btn-primary" (click)="addQty(); $event.preventDefault(); $event.stopPropagation()"
          ><i class="fa fa-plus"></i
        ></a>
      </div>

      <hr class="opacity-1"/>

      <label for="instructions" class=" fw-bold">Special Instructions</label>
      <textarea
        id="instructions"
        class="p-2 px-3 rounded w-100"
        placeholder="Add your special instruction here..."
        [(ngModel)]="orderItem.instructions"></textarea>


      <hr class="opacity-1" *ngIf="orderItem.posItemVsMerchant.posItem.varianceModel.length > 0"/>
      <ng-container *ngFor="let variant of orderItem.posItemVsMerchant.posItem.varianceModel">
        <div class="mb-2">
          <div class="fw-bold">{{ variant.title }}</div>
          <div class="option-list">
            <div class="option" *ngFor="let variantOption of variant.options; let i = index">
              <input
                type="{{ variant.type === 'multiple' ? 'checkbox' : 'radio' }}"
                value="true"
                id="{{ variant.title + '[' + i + ']' }}"
                name="{{ variant.title }}"
                class="option-input"
                [checked]="hasVarianceOption(variant, variantOption)"
                (click)="selectAddon(variant, variantOption)" />
              <label class="option-label" for="{{ variant.title + '[' + i + ']' }}">
                <span class="option-text">{{ variantOption.name }}</span>
                <span class="option-price">+{{ variantOption.price | number : "1.2" }}</span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row justify-content-center w-100">
    <div class="col-4">
      <button class="btn btn-default  border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button" >
      <button [disabled]="orderItem.price == null || orderItem.price < 0" (click)="submit()"
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center">
        <i class="fa fa-check-circle me-2"></i>
        <span>Add to Order</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
