import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../@services/notification.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {MembershipEditorDialogComponent} from '../membership-editor-dialog/membership-editor-dialog.component';
import {LocalstorageService} from '../../../@services/localstorage.service';
import {take} from 'rxjs';
import {Membership, MembershipControllerService} from '../../../@services/gen';

@Component({
  selector: 'app-membership-search-dialog',
  templateUrl: './membership-search-dialog.component.html',
  styleUrls: ['./membership-search-dialog.component.scss'],
})
export class MembershipSearchDialogComponent {
  membership: Membership;
  title = 'Search a Customer';
  loading: boolean;
  keyword: string = '';

  constructor(
    private dialogRef: MatDialogRef<MembershipSearchDialogComponent>,
    private ds: TdDialogService,
    private ns: NotificationService,
    private ls: LocalstorageService,
    private membershipService: MembershipControllerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  search() {
    this.loading = true;
    this.membershipService
      .getPagedMembershipPost({
        merchantId: this.ls.getMerchant().id,
        filter: this.keyword,
      })
      .subscribe(
        (res) => {
          if (res.content && res.content.length > 0) {
            this.membership = res.content[0];
          } else {
            if (this.membership) {
              this.membership = null;
            }

            this.ds
              .openConfirm({
                title: 'Customer Not Found',
                message: 'Add New Customer?',
              })
              .afterClosed()
              .subscribe((confirmed) => {
                if (confirmed) {
                  this.addCustomer();
                }
              });
          }
        },
        (error) => {
          this.ns.showNotification({message: 'Error while searching the customer'});
          this.loading = false;
        }
      );
  }

  addCustomer() {
    this.ds
      .open(MembershipEditorDialogComponent, {
        width: '50%',
        data: {
          customer: {phoneNo: this.keyword},
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((customer) => {
        if (customer) {
          this.membership = customer;
          this.submit();
        }
      });
  }

  submit() {
    this.dialogRef.close(this.membership);
  }
}
