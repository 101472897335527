/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PosItemPricingModel } from './posItemPricingModel';
import { PosItemUnit } from './posItemUnit';
import { PosItemVarianceModel } from './posItemVarianceModel';
import { PosItemVsMerchant } from './posItemVsMerchant';
import { PosItemVsRawMaterial } from './posItemVsRawMaterial';
import { Merchant } from './merchant';
import { PosItemVsAddOn } from './posItemVsAddOn';
import { PosItemCategory } from './posItemCategory';


export interface PosItem { 
    convertPosItemUnit?: PosItemUnit;
    convertPosItemUnitRate?: number;
    copyPriceFromHq?: boolean;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    description?: string;
    id?: number;
    imageUrl?: string;
    itemType?: PosItem.ItemTypeEnum;
    merchant?: Merchant;
    minStockTrigger?: number;
    name?: string;
    posItemCategory?: PosItemCategory;
    posItemUnit?: PosItemUnit;
    posItemVsAddOns?: Array<PosItemVsAddOn>;
    posItemVsMerchant?: PosItemVsMerchant;
    posItemVsRawMaterials?: Array<PosItemVsRawMaterial>;
    prepareDuration?: number;
    pricingModels?: Array<PosItemPricingModel>;
    pricingSetting?: string;
    skuNo?: string;
    updateTime?: string;
    updatedByName?: string;
    variance?: string;
    varianceModel?: Array<PosItemVarianceModel>;
}
export namespace PosItem {
    export type ItemTypeEnum = 'POS' | 'RAW_MATERIAL';
    export const ItemTypeEnum = {
        Pos: 'POS' as ItemTypeEnum,
        RawMaterial: 'RAW_MATERIAL' as ItemTypeEnum
    };
}


