import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PosComponent} from './@pages/pos/pos.component';
import {CheckoutComponent} from './@pages/checkout/checkout.component';
import {LoginComponent} from './@pages/login/login.component';
import {AuthGuard} from './@services/auth.guard';
import {PaymentComponent} from './@pages/payment/payment.component';
import {PaymentResultComponent} from './@pages/payment-result/payment-result.component';
import {PaymentListComponent} from './@pages/payment-list/payment-list.component';
import {ShiftClosingComponent} from './@pages/shift/shift-closing/shift-closing.component';
import {ShiftOpeningComponent} from './@pages/shift/shift-opening/shift-opening.component';
import {UserShiftGuard} from './@services/user-shift.guard';
import {UserProfileComponent} from './@pages/user-profile/user-profile.component';
import {PaymentDetailComponent} from './@pages/payment-detail/payment-detail.component';
import {ExpensesListComponent} from './@pages/expenses/expenses-list/expenses-list.component';
import {StockComponent} from "./@pages/stock/stock.component";
import {StockDetailComponent} from "./@pages/stock/stock-detail/stock-detail.component";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, UserShiftGuard],
    component: CheckoutComponent,
  },
  {
    path: 'stock',
    canActivate: [AuthGuard, UserShiftGuard],
    component: StockComponent,
  },
  {
    path: 'stock-detail',
    canActivate: [AuthGuard, UserShiftGuard],
    component: StockDetailComponent,
  },
  {
    path: 'payment',
    canActivate: [AuthGuard, UserShiftGuard],
    component: PaymentComponent,
  },
  {
    path: 'payment-list',
    canActivate: [AuthGuard, UserShiftGuard],
    component: PaymentListComponent,
  },
  {
    path: 'payment-detail',
    canActivate: [AuthGuard, UserShiftGuard],
    component: PaymentDetailComponent,
  },
  {
    path: 'payment-success',
    canActivate: [AuthGuard, UserShiftGuard],
    component: PaymentResultComponent,
  },
  {
    path: 'expenses-list',
    canActivate: [AuthGuard, UserShiftGuard],
    component: ExpensesListComponent,
  },
  {
    path: 'pos',
    canActivate: [AuthGuard, UserShiftGuard],
    component: PosComponent,
  },
  {
    path: 'shift-closing',
    canActivate: [AuthGuard, UserShiftGuard],
    component: ShiftClosingComponent,
  },
  {
    path: 'shift-opening',
    canActivate: [AuthGuard],
    component: ShiftOpeningComponent,
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard, UserShiftGuard],
    component: UserProfileComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
