<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="content__filter mb-4">
          <span class="fs-3 fw-bold mb-4 d-block">{{title}}</span>

          <form class="d-flex flex-column flex-lg-row align-items-center">
            <div class="d-flex align-items-center justify-content-start w-100">
              <app-date-selector
                class="me-4"
                [value]="datePreset"
                (dateChangeEvt)="changeDate($event)"
              ></app-date-selector>

              <div id="date-presets" class="d-flex align-items-center ms-3" style="width: 100px">
                <label for="txStatus" class="me-2">
                  <i class="fa fa-info-circle"></i>
                </label>
                <select
                  id="txStatus"
                  name="txStatus"
                  class="form-select p-2 rounded"
                  [(ngModel)]="txStatus"
                  (ngModelChange)="changeTxStatus($event)"
                >
                  <option value="ALL">All</option>
                  <option value="PAID">Paid only</option>
                  <option value="VOIDED">Void only</option>
                </select>
              </div>
            </div>
          </form>
        </div>

        <div class="content__body">
          <table class="table table-bordered bg-white">
            <thead>
            <tr>
              <th>No</th>
              <th>Status</th>
              <th>Method</th>
              <th>Date</th>
              <th>Receipt No</th>
              <th>Order No</th>
              <th class="text-right">Net Amt (RM)</th>
              <th class="text-center">Table</th>
              <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let payment of filteredPayments; let i = index">
              <td>{{i + 1}}</td>
              <td
                class="fw-semibold"
                [class.text-danger]="payment?.paymentStatus === 'VOIDED'"
                [class.text-success]="payment?.paymentStatus === 'PAID'"
              >
                {{payment?.paymentStatus | titlecase}}
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <img
                    src="{{payment?.paymentMethod?.logoUrl}}"
                    class="w-20px h-20px object-fit-contain rounded me-2"
                  >
                  <span>{{payment?.paymentMethod?.name}}</span>
                </div>
                <ng-container *ngIf="payment?.ewalletReferenceNo">
                  Ref: {{payment?.ewalletReferenceNo}}
                </ng-container>
              </td>
              <td>{{payment?.creationTime | date: 'dd/MM/yy HH:mm'}}</td>
              <td>
                <a class="p-0" [routerLink]="['/payment-detail']" [queryParams]="{paymentId: payment?.id}">
                  {{payment?.paymentNo}}
                </a>
              </td>
              <td class="text-wrap" style="min-width: 180px;">
                <ng-container *ngFor="let item of payment?.paymentItems">
                  {{item?.order?.orderNo}} ;
                </ng-container>
              </td>
              <td class="text-right">{{payment?.netAmount | number: '1.2'}}</td>
              <td class="text-center">{{payment?.tableMerchant?.name}}</td>
              <td class="text-center">
                <button
                  class="btn text-danger"
                  *ngIf="payment?.paymentStatus == 'PAID'"
                  (click)="voidPayment(payment); $event.stopPropagation()"
                >
                  Void
                </button>
              </td>
            </tr>

            <tr class="fs-5 sticky-bottom z-1 bg-white" *ngIf="filteredPayments.length > 0">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="fw-bold">Total </td>
              <td class="text-right fw-bold">RM {{totalNetAmount | number: '1.2'}}</td>
              <td class="text-right fw-bold"></td>
              <td>
              </td>
              <td colspan="6" class="fw-bold text-end">Total</td>
              <td class="text-right fw-bold">{{totalNetAmount | number: '1.2'}}</td>
              <td colspan="100%"></td>
            </tr>

            <tr *ngIf="filteredPayments.length === 0">
              <td colspan="9" class="text-center">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <i class="fa fa-info-circle me-2"></i>
                  <span>No item to be displayed</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
