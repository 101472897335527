import {Component, OnDestroy, OnInit} from "@angular/core";
import {
  Inventory,
  InventoryControllerService,
  Merchant, PosItemVsMerchant,
} from "../../../@services/gen";
import {UserModel} from "../../../@models/user.model";
import {PaymentService} from "../../../@services/payment.service";
import {AuthService} from "../../../@services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationService} from "../../../@services/notification.service";
import {TdDialogService} from "@covalent/core/dialogs";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalstorageService} from "../../../@services/localstorage.service";
import {ExpensesDialogComponent} from "../../expenses/expenses-dialog/expenses-dialog.component";
import {StockDialogComponent} from "../stock-dialog/stock-dialog.component";
import {Subscription, tap} from "rxjs";
import {BarcodeScannerService} from "../../../@services/barcode-scanner.service";
import {PosItemService} from "../../../@services/pos-item.service";

@Component({
  selector: 'app-stock-detail',
  templateUrl: './stock-detail.component.html',
  styleUrls: ['./stock-detail.component.scss']
})
export class StockDetailComponent implements OnInit, OnDestroy{
  inventoryId: number;
  inventory: Inventory;
  user: UserModel;
  merchant: Merchant = {};
  scannedData: string = '';
  private subscription: Subscription;
  scanItem: PosItemVsMerchant;
  posItems: PosItemVsMerchant[];
  filteredPosItems: PosItemVsMerchant[]

  constructor(
    private inventoryControllerService: InventoryControllerService,
    private customAuthService: AuthService,
    private ns: NotificationService,
    private ds: TdDialogService,
    private route: ActivatedRoute,
    private ls: LocalstorageService,
    private barcodeScannerService: BarcodeScannerService,
    private posItemService: PosItemService,
    private customPosItemService: PosItemService,
  ) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.merchant = this.ls.getMerchant();

    let qp = this.route.snapshot.queryParams;
    this.inventoryId = +qp['id'];
    this.load();
    this.loadPosItems();

    this.subscription = this.barcodeScannerService.getScanData().subscribe(data => {
      this.scannedData = data;
      this.scanBarcode(this.scannedData)
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  load() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading...',
      icon: 'fa fa-sync',
      manualClose: true,
    });

    this.inventoryControllerService.getInventoryPost({id: this.inventoryId}).subscribe((res) => {
      dialogRef.close();
      this.inventory = res;
    });
  }

  voidInventory(inventory){

  }

  addStock(){
    this.ds
      .open(StockDialogComponent, {
        minWidth: '50%',
        data: {
          inventory: this.inventory,
          posItemVsMerchant: this.scanItem
        }
      })
      .afterClosed()
      .subscribe((res) => {
        this.load();
      });
  }

  editItem(inventory){}

  deleteItem(inventory){}

  scanBarcode(barcode) {
    this.posItemService.getPosItemByBarcode(barcode, this.posItems).subscribe(item => {
      this.scanItem = item;
      this.addStock();
    });
  }

  private loadPosItems() {
    this.customPosItemService
      .getCachePagedPosItemVsMerchant(this.merchant.id)
      .pipe(tap((value) => {}))
      .subscribe(
        (res) => {
          if (res && res.content) {

            this.posItems = res.content;
            this.filteredPosItems = res.content;
          }
        },
        (error) => {

        }
      );
  }
}
