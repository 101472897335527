<div id="date-presets" class="d-flex p-2 align-items-center">
  <label for="preset" class="me-2">
    <i class="fa fa-calendar-alt"></i>
  </label>
  <select
    id="preset"
    class="w-100 flex-1 p-2 rounded"
    [(ngModel)]="value"
    (ngModelChange)="changeDate()"
  >
    <option value="session">Session</option>
    <option value="today">Today</option>
    <option value="this-week">This Week</option>
    <option value="this-month">This Month</option>
  </select>
</div>
