/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SortOrder { 
    direction?: SortOrder.DirectionEnum;
    ignoreCase?: boolean;
    property?: string;
}
export namespace SortOrder {
    export type DirectionEnum = 'ASC' | 'DESC';
    export const DirectionEnum = {
        Asc: 'ASC' as DirectionEnum,
        Desc: 'DESC' as DirectionEnum
    };
}


