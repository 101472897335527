/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedPaymentQuery { 
    endDate?: string;
    merchantId?: number;
    pageRequest?: CustomPageRequest;
    paymentStatus?: GetPagedPaymentQuery.PaymentStatusEnum;
    search?: string;
    startDate?: string;
    tableId?: number;
}
export namespace GetPagedPaymentQuery {
    export type PaymentStatusEnum = 'PAID' | 'UNPAID' | 'VOIDED';
    export const PaymentStatusEnum = {
        Paid: 'PAID' as PaymentStatusEnum,
        Unpaid: 'UNPAID' as PaymentStatusEnum,
        Voided: 'VOIDED' as PaymentStatusEnum
    };
}


