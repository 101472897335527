/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomPageRequest } from './customPageRequest';


export interface GetPagedPosItemVsMerchantLightQuery { 
    filterByIsShow?: boolean;
    itemType?: GetPagedPosItemVsMerchantLightQuery.ItemTypeEnum;
    merchantId?: number;
    pageRequest?: CustomPageRequest;
}
export namespace GetPagedPosItemVsMerchantLightQuery {
    export type ItemTypeEnum = 'POS' | 'RAW_MATERIAL';
    export const ItemTypeEnum = {
        Pos: 'POS' as ItemTypeEnum,
        RawMaterial: 'RAW_MATERIAL' as ItemTypeEnum
    };
}


