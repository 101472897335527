<mat-dialog-content>
  <div class="w-100">

    <div class="fs-4 text-black mb-4 fw-bold">{{ title }}</div>

    <div class="d-flex flex-column">
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Phone No</mat-label>
        <input matInput [(ngModel)]="keyword"
               type="tel"
               required
               placeholder="">
        <mat-hint>eg: 60123456789</mat-hint>
      </mat-form-field>
    </div>

    <div class="d-flex flex-column w-100 py-2 px-2" *ngIf="membership">
      <span class="h5 fw-bold text-left">{{ membership?.name }}</span>
      <span class="h6 fw-bold text-left">{{ membership?.phoneNo }}</span>
      <button class="btn btn-success mt-2" (click)="submit()">Select</button>
    </div>
  </div>


</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex justify-content-center w-100 p-3">
    <div class="col-4 me-3">
      <button class="btn btn-default border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button">
      <button
        class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center"
        (click)="search()"
        [disabled]="!keyword">
        <i class="fa fa-search me-2"></i>
        <span>Search</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>
