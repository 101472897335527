<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header" id="pos">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="d-flex  align-items-center flex-column card card-body flex-grow-0">

          <span class="fs-3 fw-bold mb-3 d-block">{{title}}</span>

          <div class="w-300px d-flex flex-column fw-semibold">
            <div class="d-flex justify-content-between">
              <span>User ID</span>
              <span >{{user?.userId}}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Username</span>
              <span >{{user?.username}}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Shift Start</span>
              <span>{{userShift?.start | date: 'dd/MM/yyyy HH:mm'}}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span>Opening Cash</span>
              <span>RM {{userShift?.openingCash | number: '1.2'}}</span>
            </div>
          </div>

          <div class="mt-4">
            <button class="btn btn-lg bg-primary text-white fw-semibold mb-0 d-block py-3" (click)="closeShift()" *ngIf="role =='cashier'">
              End Shift
            </button>
            <button class="btn btn-lg bg-primary text-white fw-semibold mb-0 d-block py-3" (click)="logout()" *ngIf="role !='cashier'">
             Log Out
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
