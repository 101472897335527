import {Component, OnInit} from "@angular/core";
import {DposUserRole, Merchant, UserControllerService, UserShiftRes} from "../../@services/gen";
import {AuthService} from '../../@services/auth.service';
import {Location} from '@angular/common';
import {take} from 'rxjs';
import {TdDialogService} from '@covalent/core/dialogs';
import {Router} from '@angular/router';
import {UserModel} from '../../@models/user.model';
import {LocalstorageService} from '../../@services/localstorage.service';
import {NotificationService} from '../../@services/notification.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit{
  title = 'My Profile';
  merchant: Merchant;
  userShift: UserShiftRes;
  user: UserModel;
  role: any;

  constructor(
    private userService: UserControllerService,
    private customAuthService: AuthService,
    private ls: LocalstorageService,
    public location: Location,
    private router: Router,
    private ds: TdDialogService,
    private ns: NotificationService
  ) {}

  ngOnInit(): void {
    this.user = this.customAuthService.getUser();
    this.role = this.user.role;
    this.merchant = this.ls.getMerchant();
    this.loadShift();
  }

  loadShift() {
    let dialogRef = this.ns.showNotification({
      message: 'Loading...',
      icon: 'fa fa-sync',
      manualClose: true,
    });
    this.userService
      .getUserShiftPost({
        merchantId: this.merchant.id,
      })
      .subscribe(
        (userShift) => {
          dialogRef.close();
          this.userShift = userShift;
        },
        (error) => {
          dialogRef.close();
        }
      );
  }

  closeShift() {
    this.ds
      .openConfirm({
        title: 'Confirmation',
        message: 'End your shift now?',
        cancelButton: 'No',
        acceptButton: 'Yes',
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.router.navigate(['/shift-closing']);
        }
      });
  }

  logout(){
    this.ls.clear();
    this.router.navigate(['/login']);
  }
}
