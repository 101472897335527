import {Injectable} from '@angular/core';
import {NotificationModalComponent} from '../@components/notification-modal/notification-modal.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {take} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private ds: TdDialogService) {}

  showNotification({
    message,
    icon = 'fa fa-info',
    manualClose = false,
    timeout = 1000,
  }: {
    message: string;
    icon?: string;
    manualClose?: boolean;
    timeout?: number;
  }) {
    let dialogRef = this.ds.open(NotificationModalComponent, {
      data: {
        icon: icon,
        message: message,
      },
    });

    if (!manualClose) {
      dialogRef
        .afterOpened()
        .pipe(take(1))
        .subscribe(() => {
          setTimeout(() => {
            dialogRef.close();
          }, timeout);
        });
    }

    return dialogRef;
  }
}
