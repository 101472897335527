/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Merchant } from './merchant';
import { OrderItem } from './orderItem';
import { TableMerchant } from './tableMerchant';


export interface Order { 
    actualPreparedDuration?: number;
    actualServedDuration?: number;
    averageCost?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    discountAmount?: number;
    discountItemAmount?: number;
    discountRemarks?: string;
    id?: number;
    kitchenOrderStatus?: Order.KitchenOrderStatusEnum;
    kitchenRemarks?: string;
    merchant?: Merchant;
    netAmount?: number;
    orderItems?: Array<OrderItem>;
    orderNo?: string;
    orderStatus?: Order.OrderStatusEnum;
    orderType?: Order.OrderTypeEnum;
    paidAmount?: number;
    receiptNo?: string;
    roundingAmount?: number;
    subtotal?: number;
    tableCustomer?: TableMerchant;
    tax1Amount?: number;
    tax2Amount?: number;
    totalAmount?: number;
    updateTime?: string;
    updatedByName?: string;
}
export namespace Order {
    export type KitchenOrderStatusEnum = 'CANCELLED' | 'CONFIRMED' | 'NEW' | 'PREPARING' | 'READY' | 'SERVED';
    export const KitchenOrderStatusEnum = {
        Cancelled: 'CANCELLED' as KitchenOrderStatusEnum,
        Confirmed: 'CONFIRMED' as KitchenOrderStatusEnum,
        New: 'NEW' as KitchenOrderStatusEnum,
        Preparing: 'PREPARING' as KitchenOrderStatusEnum,
        Ready: 'READY' as KitchenOrderStatusEnum,
        Served: 'SERVED' as KitchenOrderStatusEnum
    };
    export type OrderStatusEnum = 'CANCELLED' | 'NEW' | 'PAID' | 'PAYMENT_VOIDED' | 'UNFULFILLED' | 'UNPAID';
    export const OrderStatusEnum = {
        Cancelled: 'CANCELLED' as OrderStatusEnum,
        New: 'NEW' as OrderStatusEnum,
        Paid: 'PAID' as OrderStatusEnum,
        PaymentVoided: 'PAYMENT_VOIDED' as OrderStatusEnum,
        Unfulfilled: 'UNFULFILLED' as OrderStatusEnum,
        Unpaid: 'UNPAID' as OrderStatusEnum
    };
    export type OrderTypeEnum = 'DINE_IN' | 'TAKE_AWAY';
    export const OrderTypeEnum = {
        DineIn: 'DINE_IN' as OrderTypeEnum,
        TakeAway: 'TAKE_AWAY' as OrderTypeEnum
    };
}


