/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentOrderItemReq } from './paymentOrderItemReq';
import { Merchant } from './merchant';
import { PaymentItemReq } from './paymentItemReq';
import { TableMerchant } from './tableMerchant';
import { Membership } from './membership';
import { PaymentMethod } from './paymentMethod';


export interface PaymentReq { 
    changeAmount?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    discountAmount?: number;
    discountItemAmount?: number;
    discountPercentage?: number;
    ewalletReferenceNo?: string;
    id?: number;
    membership?: Membership;
    merchant?: Merchant;
    netAmount?: number;
    paidAmount?: number;
    paymentItems?: Array<PaymentItemReq>;
    paymentMethod?: PaymentMethod;
    paymentNo?: string;
    paymentOrderItems?: Array<PaymentOrderItemReq>;
    paymentStatus?: PaymentReq.PaymentStatusEnum;
    queueNo?: string;
    roundingAmount?: number;
    tableId?: number;
    tableMerchant?: TableMerchant;
    tax1Amount?: number;
    tax2Amount?: number;
    tipsAmount?: number;
    totalAmount?: number;
    updateTime?: string;
    updatedByName?: string;
    voidDate?: string;
    voidUser?: string;
}
export namespace PaymentReq {
    export type PaymentStatusEnum = 'PAID' | 'UNPAID' | 'VOIDED';
    export const PaymentStatusEnum = {
        Paid: 'PAID' as PaymentStatusEnum,
        Unpaid: 'UNPAID' as PaymentStatusEnum,
        Voided: 'VOIDED' as PaymentStatusEnum
    };
}


