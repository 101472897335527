import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PosItemVarianceModel, PosItemVarianceOptionModel} from '../../../@services/gen';
import {OrderItem} from "../../../@services/gen/model/orderItem";

@Component({
  selector: 'app-pos-item-dialog',
  templateUrl: './pos-item-dialog.component.html',
  styleUrls: ['./pos-item-dialog.component.scss'],
})
export class PosItemDialogComponent implements OnInit {
  orderItem: OrderItem = {
    quantity: 0,
    instructions: '',
  };

  selectedVariants: PosItemVarianceModel[] = [];

  constructor(private dialogRef: MatDialogRef<PosItemDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {}

  ngOnInit(): void {
    this.orderItem = this.data.orderItem;
    if (this.orderItem.varianceModel) {
      for (let v of this.orderItem.varianceModel) {
        for (let opt of v.options) {
          this.selectAddon(v, opt);
        }
      }
    }
  }

  submit() {
    this.orderItem.averageCost = this.orderItem.quantity * (this.orderItem.averageCost ?? 0);
    this.dialogRef.close(this.orderItem);
  }

  addQty() {
    this.orderItem.quantity += 1;
  }

  minusQty() {
    if (this.orderItem.quantity == 0) {
      return;
    }
    this.orderItem.quantity -= 1;
  }

  selectAddon(variant: PosItemVarianceModel, varianceOption: PosItemVarianceOptionModel) {
    const index = this.selectedVariants.findIndex((v) => v.title === variant.title);
    if (index > -1) {
      const optionIndex = this.selectedVariants[index].options.findIndex((o) => o.name === varianceOption.name);
      if (this.selectedVariants[index].type === 'option') {
        this.selectedVariants[index].options = [];
      }
      if (optionIndex === -1) {
        this.selectedVariants[index].options.push(varianceOption);
      } else {
        this.selectedVariants[index].options.splice(optionIndex, 1);
      }
    } else {
      this.selectedVariants.push({...variant, options: [varianceOption]});
    }

    this.orderItem.varianceModel = this.selectedVariants;
    this.orderItem.variance = JSON.stringify(this.selectedVariants);
  }

  hasVarianceOption(variant: PosItemVarianceModel, variantOption: PosItemVarianceOptionModel) {
    for (let v of this.selectedVariants) {
      if (v.title === variant.title) {
        return v.options.findIndex((x) => x.name === variantOption.name) > -1;
      }
    }
    return false;
  }
}
