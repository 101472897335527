<h1 matDialogTitle>Transfer Table</h1>

<mat-dialog-content>
  <div class="row gx-3">
    <div class="mt-2" style="width: calc(100% / 5);" *ngFor="let item of tables"
         (click)="selectTable(item.id)">
      <div class="pos-checkout-table" [ngClass]="{'bg-primary text-white': selectedTable?.id === item.id}">
        <div class="pos-checkout-table-container" data-toggle="select-table">
          <div class="pos-checkout-table-header text-center rounded p-2 rounded">
            <div class="fw-bold fs-4">{{ item.name }}</div>
            <div class="fs-13px text-opacity-50">{{ item.totalOrder }} order(s)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="row justify-content-center w-100">
    <div class="col-4">
      <button class="btn btn-default  border-0 fw-semibold mb-0 d-block py-3 w-100" mat-dialog-close="">
        <i class="fa fa-times-circle me-2"></i>
        <span>Cancel</span>
      </button>
    </div>
    <div class="col-8" role="button" >
      <button [disabled]="!selectedTable" (click)="submit()"
              class="btn btn-default bg-primary text-white w-100 h-100 d-flex align-items-center justify-content-center">
        <i class="fa fa-check-circle me-2"></i>
        <span>Confirm</span>
      </button>
    </div>
  </div>
</mat-dialog-actions>

