/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TableMerchantDto { 
    hasUnconfirmed?: boolean;
    id?: number;
    name?: string;
    orderType?: TableMerchantDto.OrderTypeEnum;
    seq?: number;
    totalOrder?: number;
    totalSale?: number;
}
export namespace TableMerchantDto {
    export type OrderTypeEnum = 'DINE_IN' | 'TAKE_AWAY';
    export const OrderTypeEnum = {
        DineIn: 'DINE_IN' as OrderTypeEnum,
        TakeAway: 'TAKE_AWAY' as OrderTypeEnum
    };
}


