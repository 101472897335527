import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-payment-discount-dialog',
  templateUrl: './payment-discount-dialog.component.html',
  styleUrls: ['./payment-discount-dialog.component.scss'],
})
export class PaymentDiscountDialogComponent {
  cashDiscount: number = 0;
  discountPercentage: number = 0;
  nettAmount: number = 0;
  percentagePresets = ['5', '10', '15', '20', '50', '100', 'custom'];
  showPercentageDiscountInput: boolean;
  selectedPercentageDiscountIndex: number;

  constructor(
    private dialogRef: MatDialogRef<PaymentDiscountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.nettAmount = data.totalAmount;

    console.log(data);
  }

  submit() {
    this.dialogRef.close({
      cashDiscount: this.cashDiscount,
      discountPercentage: this.discountPercentage,
    });
  }

  calculate(type: 'cash' | 'percentage') {
    switch (type) {
      case 'cash':
        this.calculateDiscountPercentage();
        break;
      case 'percentage':
        this.calculateCashDiscount();
        break;
    }
  }

  calculateDiscountPercentage() {
    if (this.nettAmount <= 0 || this.cashDiscount <= 0 || this.cashDiscount > this.nettAmount) {
      this.discountPercentage = 0;
    }
    this.discountPercentage = +((this.cashDiscount / this.nettAmount) * 100).toFixed(2);
  }

  calculateCashDiscount() {
    if (this.nettAmount <= 0 || this.discountPercentage < 0 || this.discountPercentage > 100) {
      this.cashDiscount = 0;
    }

    this.cashDiscount = +((this.discountPercentage / 100) * this.nettAmount).toFixed(2);
  }

  selectPercentageDiscount(item: string, itemIndex: number) {
    this.selectedPercentageDiscountIndex = itemIndex;
    this.showPercentageDiscountInput = false;
    if (item === 'custom') {
      this.showPercentageDiscountInput = true;
    } else {
      this.discountPercentage = +item;
      this.calculate('percentage');
    }
  }
}
