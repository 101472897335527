<body>
<div class="container justify-content-center align-items-center d-flex flex-column h-100">

  <div class="main-container">
    <a href="/">
      <img src="../../../assets/img/duck-pos.png" style="width: 280px;" />
    </a>
    <div class="login-container">
      <h2 class="login-title">LOGIN ACCOUNT</h2>
      <form (ngSubmit)="login()" class="gap-2 d-inline flex-xl-column">
        <div *ngIf="errMsg" class="m-4 text-center">
          <span class="error">{{errMsg}}</span>
        </div>
        <div>
          <input
            class="input-field"
            type="text"
            placeholder="Username"
            name="username"
            [(ngModel)]="username"
            required>
        </div>
        <div class="password-input">
          <input
            class="input-field"
            type="{{ showPassword ? 'text' : 'password' }}"
            placeholder="Password"
            name="password"
            [(ngModel)]="password"
            required>
          <button type="button" class="toggle-password" (click)="togglePasswordVisibility()">
            <mat-icon matSuffix *ngIf="password">{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon>
          </button>
          <button class="login-button mt-10px mb-10px" type="submit" [disabled]="loading">{{loading ? 'Signing in...' :'SIGN IN'}}</button>
        </div>
      </form>
    </div>

  </div>

  <footer class="footer">
    &copy; {{ currentYear }} Devduck Solutions. All Rights Reserved
  </footer>
</div>
</body>
