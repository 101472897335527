import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Merchant} from './gen';
import {resizeImageUrl} from './utils';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private readonly _key = 'dposPosMerchant';

  constructor(private router: Router) {}

  saveMerchant(merchant: Merchant) {
    if (merchant.merchantLogo) {
      merchant['merchantLogoSmall'] = resizeImageUrl(merchant.merchantLogo, '200');
    }
    localStorage.setItem(this._key, JSON.stringify(merchant));
  }

  getMerchant(): Merchant {
    let userJson = localStorage.getItem(this._key);
    return JSON.parse(userJson) as Merchant;
  }

  clear() {
    localStorage.clear();
  }
}
