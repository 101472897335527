<div id="app" class="app app-content-full-height app-without-sidebar app-without-header">
  <div id="content" class="app-content p-0">
    <div class="pos pos-vertical pos-with-header">
      <div class="pos-container p-5">

        <app-header></app-header>

        <div class="content__filter mb-4">
          <span class="fs-3 fw-bold mb-4 d-block">{{title}}</span>

          <form class="d-flex flex-column flex-lg-row align-items-center">
            <div class="d-flex align-items-center justify-content-between w-100">
              <app-date-selector
                class="me-4"
                [value]="datePreset"
                (dateChangeEvt)="changeDate($event)"
              ></app-date-selector>

              <button class="btn btn-lg btn-outline-primary fw-semibold mb-0 d-block py-3 ms-4"
                      (click)="addExpenses(); $event.stopPropagation()">
                <i class="fa fa-plus"></i>
                New Record
              </button>
            </div>
          </form>
        </div>

        <div class="content__body">
          <table class="table table-bordered bg-white">
            <thead>
            <tr>
              <th>No</th>
              <th>Date</th>
              <th>Description</th>
              <th class="text-end">Amount</th>
              <th class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let expenses of filteredExpenses; let i = index">
              <td class="w-80px">{{i + 1}}</td>
              <td class="w-200px">{{expenses?.creationTime | date: 'dd/MM/yy HH:mm'}}</td>
              <td class="w-500px text-wrap">{{expenses?.description}}</td>
              <td class="text-end">{{expenses?.amount | number: '1.2'}}</td>
              <td class="text-center">
                <button class="btn text-danger"
                        (click)="voidExpenses(expenses); $event.stopPropagation()">Void
                </button>
              </td>
            </tr>

            <tr class="fs-5 sticky-bottom z-1 bg-white" *ngIf="filteredExpenses.length > 0">
              <td></td>
              <td></td>
              <td class="fw-bold text-right">Total</td>
              <td class="text-end fw-bold">{{totalAmount | number: '1.2'}}</td>
              <td></td>
            </tr>

            <tr *ngIf="filteredExpenses.length === 0">
              <td colspan="5" class="text-center">
                <div class="d-flex flex-row align-items-center justify-content-center">
                  <i class="fa fa-info-circle me-2"></i>
                  <span>No item to be displayed</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
