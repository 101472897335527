import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {map, Observable, of, shareReplay} from "rxjs";
import {PagePosItemVsMerchant, PosItemVsMerchant, PosItemVsMerchantControllerService} from "./gen";

@Injectable({
  providedIn: 'root',
})
export class PosItemService {
  cachePosItemVsMerchant$: Observable<PagePosItemVsMerchant>;

  constructor(private router: Router, private posItemVsMerchantControllerService: PosItemVsMerchantControllerService) {}

  getCachePagedPosItemVsMerchant(merchantId: number): Observable<PagePosItemVsMerchant> {
    if (!this.cachePosItemVsMerchant$) {
      this.cachePosItemVsMerchant$ = this.posItemVsMerchantControllerService
        .getPagedPosItemVsMerchantLightPost({
          merchantId: merchantId,
          itemType: 'POS',
          pageRequest: {},
        })
        .pipe(
          map((pivm) => {
            pivm.content.sort((a, b) => {
              if (a.posItem.posItemCategory.seq !== null) {
                return a.posItem.posItemCategory.seq - b.posItem.posItemCategory.seq;
              } else {
                return a.posItem.posItemCategory.name.localeCompare(b.posItem.posItemCategory.name);
              }
            });
            return pivm;
          }),
          shareReplay(1)
        );
    }

    return this.cachePosItemVsMerchant$;
  }

  getPosItemByBarcode(barcode: string, posItemVsMerchants: PosItemVsMerchant[]): Observable<PosItemVsMerchant | null> {
    const posItemVsMerchant = posItemVsMerchants.find(item => item.barcode === barcode) || null;
    return of(posItemVsMerchant);
  }

}
