import {Component, OnInit} from "@angular/core";
import * as dayjs from "dayjs";
import {UserControllerService} from "./@services/gen";
import {LocalstorageService} from "./@services/localstorage.service";
import {UserShiftService} from "./@services/user-shift.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'dpos-pos-ui';

  constructor(private userService: UserControllerService, private ls: LocalstorageService, private userShiftService: UserShiftService) {
  }

  ngOnInit() {
    this.loadShiftSummary();
  }

  private loadShiftSummary() {
    this.userService
      .getUserShiftSummaryPost({
        merchantId: this.ls.getMerchant().id,
      })
      .subscribe(
        (userShift) => {
          if(userShift){
            this.userShiftService.saveUserShift(userShift);
          }
        },
        (error) => {
        }
      );
  }
}
