/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PosItemUnit { 
    convertionRate?: number;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    id?: number;
    multiplyValue?: number;
    name?: string;
    sequence?: number;
    symbol?: string;
    toUnit?: PosItemUnit;
    updateTime?: string;
    updatedByName?: string;
}

