/**
 * DPos
 * API for DPos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PosItemVsMerchant } from './posItemVsMerchant';
import { StaffMealConfig } from './staffMealConfig';
import { MerchantVsUser } from './merchantVsUser';
import { MerchantVsPrinter } from './merchantVsPrinter';
import { MerchantVsTax } from './merchantVsTax';


export interface Merchant { 
    address?: string;
    city?: string;
    companyCode?: string;
    companyEmail?: string;
    companyName?: string;
    companyPhone?: string;
    companyRegNo?: string;
    createdByName?: string;
    creationTime?: string;
    deleted?: boolean;
    hqId?: number;
    hqName?: string;
    id?: number;
    merchantLogo?: string;
    merchantStatus?: Merchant.MerchantStatusEnum;
    merchantType?: Merchant.MerchantTypeEnum;
    merchantVsPrinters?: Array<MerchantVsPrinter>;
    merchantVsTaxes?: Array<MerchantVsTax>;
    merchantVsUsers?: Array<MerchantVsUser>;
    natureOfBusiness?: Merchant.NatureOfBusinessEnum;
    posItemVsMerchants?: Array<PosItemVsMerchant>;
    postcode?: string;
    staffMealConfig?: StaffMealConfig;
    state?: string;
    storeName?: string;
    taxNo?: string;
    updateTime?: string;
    updatedByName?: string;
}
export namespace Merchant {
    export type MerchantStatusEnum = 'ACTIVE' | 'INACTIVE';
    export const MerchantStatusEnum = {
        Active: 'ACTIVE' as MerchantStatusEnum,
        Inactive: 'INACTIVE' as MerchantStatusEnum
    };
    export type MerchantTypeEnum = 'HQ_MERCHANT' | 'MERCHANT' | 'SUB_MERCHANT';
    export const MerchantTypeEnum = {
        HqMerchant: 'HQ_MERCHANT' as MerchantTypeEnum,
        Merchant: 'MERCHANT' as MerchantTypeEnum,
        SubMerchant: 'SUB_MERCHANT' as MerchantTypeEnum
    };
    export type NatureOfBusinessEnum = 'FNB' | 'RETAIL';
    export const NatureOfBusinessEnum = {
        Fnb: 'FNB' as NatureOfBusinessEnum,
        Retail: 'RETAIL' as NatureOfBusinessEnum
    };
}


